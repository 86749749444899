import { SvgIcon } from '@mui/material';

const ImportIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.6667 18.6667C28.1394 18.6667 29.3333 17.4728 29.3333 16C29.3333 14.5273 28.1394 13.3334 26.6667 13.3334C25.1939 13.3334 24 14.5273 24 16C24 17.4728 25.1939 18.6667 26.6667 18.6667Z"
        stroke="url(#paint0_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6667 7.99996C28.1394 7.99996 29.3333 6.80605 29.3333 5.33329C29.3333 3.86053 28.1394 2.66663 26.6667 2.66663C25.1939 2.66663 24 3.86053 24 5.33329C24 6.80605 25.1939 7.99996 26.6667 7.99996Z"
        stroke="url(#paint1_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6667 29.3333C28.1394 29.3333 29.3333 28.1394 29.3333 26.6667C29.3333 25.1939 28.1394 24 26.6667 24C25.1939 24 24 25.1939 24 26.6667C24 28.1394 25.1939 29.3333 26.6667 29.3333Z"
        stroke="url(#paint2_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33317 18.6667C6.80593 18.6667 7.99984 17.4728 7.99984 16C7.99984 14.5273 6.80593 13.3334 5.33317 13.3334C3.86041 13.3334 2.6665 14.5273 2.6665 16C2.6665 17.4728 3.86041 18.6667 5.33317 18.6667Z"
        stroke="url(#paint3_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 16H24"
        stroke="url(#paint4_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9998 5.33337H18.6665C15.9998 5.33337 14.6665 6.66671 14.6665 9.33337V22.6667C14.6665 25.3334 15.9998 26.6667 18.6665 26.6667H23.9998"
        stroke="url(#paint5_linear_4184_356)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4184_356"
          x1="24"
          y1="16"
          x2="29.3333"
          y2="16"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4184_356"
          x1="24"
          y1="5.33329"
          x2="29.3333"
          y2="5.33329"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4184_356"
          x1="24"
          y1="26.6667"
          x2="29.3333"
          y2="26.6667"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4184_356"
          x1="2.6665"
          y1="16"
          x2="7.99984"
          y2="16"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4184_356"
          x1="8"
          y1="16.5"
          x2="24"
          y2="16.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4184_356"
          x1="14.6665"
          y1="16"
          x2="23.9998"
          y2="16"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ImportIcon;
