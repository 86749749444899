import { FC } from 'react';
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuthState } from '../store/reducers/authReducer';
import { RootState } from '../store/store';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const RequireAuth: FC = () => {
  const auth = useTypedSelector(getAuthState, shallowEqual);

  return auth.access_token ? <Outlet /> : <Navigate to="/" replace />;
};

export default RequireAuth;
