import * as React from 'react';
import { Box, Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Style from './Breadcrumbs.styles';
import ReactGA from 'react-ga4';

export default function CustomizedBreadcrumbs() {
  let location = useLocation();

  let navigate = useNavigate();

  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbsType[]>([]);

  type BreadcrumbsType = {
    path: string;
    name: string;
    url?: string;
  };

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    if (location.pathname !== '/') {
      if (location.pathname.includes('dashboard')) {
        setBreadcrumb([breadcrumbsPages.Dashboard]);
      }
      if (location.pathname.includes('repository')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          breadcrumbsPages.SelectProject,
          breadcrumbsPages.Repository
        ]);
      }
      if (location.pathname.includes('select-project')) {
        setBreadcrumb([breadcrumbsPages.Dashboard, breadcrumbsPages.SelectProject]);
      }
      if (location.pathname.includes('build-new-project')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          breadcrumbsPages.SelectProject,
          breadcrumbsPages.CreateNewProject
        ]);
      }
      if (location.pathname.includes('project-configure')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          breadcrumbsPages.SelectProject,
          breadcrumbsPages.Repository,
          breadcrumbsPages.ProjectConfig
        ]);
      }
      if (location.pathname.includes('configure-project')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          breadcrumbsPages.Repository,
          breadcrumbsPages.CreateProject
        ]);
      }
      if (location.pathname.includes('details')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          { ...breadcrumbsPages.ProjectDetail, url: location.pathname }
        ]);
      }
      if (location.pathname.includes('configuration')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          {
            ...breadcrumbsPages.ProjectDetail,
            url: location.pathname.replace(
              breadcrumbsPages.ConfigureationProject.path,
              breadcrumbsPages.ProjectDetail.path
            )
          },
          { ...breadcrumbsPages.ConfigureationProject, url: location.pathname }
        ]);
      }
      if (location.pathname.includes('managebranch')) {
        setBreadcrumb([
          breadcrumbsPages.Dashboard,
          {
            ...breadcrumbsPages.ProjectDetail,
            url: location.pathname.replace(
              breadcrumbsPages.ManageBranchConfig.path,
              breadcrumbsPages.ProjectDetail.path
            )
          },
          { ...breadcrumbsPages.ManageBranchConfig, url: location.pathname }
        ]);
      }
      if (location.pathname.includes('status')) {
        setBreadcrumb([]);
      }
    } else {
      setBreadcrumb([]);
    }
  }, [location]);

  return (
    <Box sx={Style.BreadcrumbWrapper}>
      <Breadcrumbs aria-label="breadcrumb" sx={Style.Breadcrumbs}>
        {breadcrumb.map((element: any, index: any) => (
          <Link
            key={index + '1'}
            underline="hover"
            onClick={() => navigate(element.url ? element.url : element.path)}
            aria-current="page"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              color: breadcrumb?.length - 1 === index ? 'text.primary' : 'black',
              textDecoration: 'none !important',
              cursor: 'pointer'
            }}>
            {element.name}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

export const breadcrumbsPages = {
  Dashboard: {
    path: '/dashboard',
    name: 'Home'
  },
  Repository: {
    path: '/repository',
    name: 'Repository'
  },
  CreateProject: {
    path: '/configure-project',
    name: 'Create Project'
  },
  SelectProject: {
    path: '/select-project',
    name: 'Create Project'
  },
  CreateNewProject: {
    path: '/build-new-project',
    name: 'Add New Project'
  },
  ProjectDetail: {
    path: '/details',
    name: 'Project Details'
  },
  ConfigureationProject: {
    path: '/configuration',
    name: 'Configuration'
  },
  ManageBranchConfig: {
    path: '/managebranch',
    name: 'Manage Branch Config'
  },
  ProjectConfig: {
    path: '/project-configure',
    name: 'Project Configuration'
  }
};
