import SvgIcon from '@mui/material/SvgIcon';

export default function AddCircleBlueIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 32 32">
      <path
        d="M16.0003 29.3332C23.3337 29.3332 29.3337 23.3332 29.3337 15.9998C29.3337 8.6665 23.3337 2.6665 16.0003 2.6665C8.66699 2.6665 2.66699 8.6665 2.66699 15.9998C2.66699 23.3332 8.66699 29.3332 16.0003 29.3332Z"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 16H21.3337"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.3332V10.6665"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
