import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { BuildSettingForm } from '..';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { PageRoutes } from '../../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import DockerfileConfigs from './DockerfileConfigs';
import BuildndeployConfigs from './BuildndeployConfigs';
import { BuildFlagEnum } from '../../../models';

export const BuildSettings: FC<BuildSettingForm> = (props) => {
  return (
    <Grid container spacing={3} sx={{ width: 1 }}>
      {props.BuildFlag !== BuildFlagEnum.COMMANDS && (
        <Grid item container sx={{ pl: 3, pt: 3 }} spacing={3} direction="row">
          <Grid item xs={12} md={12} sx={{ width: 1 }}>
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="info">
                If you dont have Dockerfile in your project please choose inline and write the
                custom docker file <Link to={PageRoutes.DockerFileInstruction}>more info</Link>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      )}
      <Grid item container sx={{ pl: 3, pt: 3 }} spacing={3} direction="row">
        <Grid item xs={12} md={6} sx={{ width: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Build Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.BuildFlag === BuildFlagEnum.COMMANDS ? 1 : 0}
              label="Build Type"
              onChange={(event) => {
                if (event?.target.value) {
                  props.onChange('BuildFlag', BuildFlagEnum.COMMANDS);
                } else if (!props.dockerChecked) {
                  props.onChange('BuildFlag', BuildFlagEnum.DOCKERFILE);
                } else {
                  props.onChange('BuildFlag', BuildFlagEnum.INLINE);
                }
              }}>
              <MenuItem value={0}>Dockerfile</MenuItem>
              <MenuItem value={1}>Build and Development Settings</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {props.BuildFlag === BuildFlagEnum.COMMANDS ? (
          <BuildndeployConfigs {...props} />
        ) : (
          <DockerfileConfigs {...props} />
        )}
      </Grid>
      {/* <Grid item xs={12} md={6} sx={{ width: 1 }}>
        <TextField
          variant="outlined"
          label="Image"
          fullWidth
          id="Image"
          placeholder="image"
          onChange={(event) => {
            props.onChange('Image', event?.target.value);
          }}
          value={props.Image || ''}
          aria-describedby="Image"
          inputProps={{
            'aria-label': 'Image'
          }}
        />
      </Grid> */}
    </Grid>
  );
};

export default BuildSettings;
