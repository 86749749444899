const Style = {
  RepositoryDetailsHead: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2
  },
  ListItemLeftPart: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  RepositoryImage: {
    height: { xxs: 30, md: 40 },
    width: { xxs: 30, md: 40 },
    borderRadius: '50%',
    overflow: 'hidden',
    mr: { xxs: 1, md: 2 },
    img: {
      height: 1,
      width: 1,
      objectFit: 'cover'
    }
  },
  RepositoryTitle: {
    maxWidth: { xxs: 'calc(100% - 38px)', md: 'calc(100% - 56px)' },
    display: 'flex',
    alignItems: 'center',
    color: 'common.blackText',
    fontWeight: '500'
  },
  OverviewCard: {
    mt: 5,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    p: 3,
    pl: 2,
    border: '1px solid #DADADA',
    alignItems: {
      xxs: 'center',
      sm: 'flex-start'
    },
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    }
  },
  OverViewStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1,
    flexDirection: {
      xxs: 'column',
      md: 'row'
    },
    alignItems: {
      xxs: 'center',
      sm: 'flex-start'
    }
  },
  OverviewBox: {
    display: 'flex',
    width: 1,

    alignItems: {
      xxs: 'center',
      sm: 'flex-start'
    },
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    }
  },
  OverviewImageBox: {
    overflow: 'hidden',
    heigh: '200px',
    width: 1,
    display: 'flex'
  },
  OverviewContentBox: {
    my: 2
  },
  ContentLabel: {
    color: 'common.grey',
    mb: 0.5
  },
  DetailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    gap: 2,
    alignItems: 'center'
  },
  BranchSelect: {
    color: 'common.blackText',
    border: '1px solid #DADADA',
    borderRadius: 2,

    px: 1,
    '.MuiSelect-select': {
      backgroundColor: 'transparent',
      display: 'flex',
      py: 1.1
    },
    '&.MuiInputBase-root': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    }
  },
  StatusButton: {
    borderRadius: 50,
    backgroundColor: 'common.green.100',
    color: 'common.green.800',
    '&:hover': {
      color: 'common.white'
    }
  },
  SettingButton: {
    color: '#6B6B6B',
    p: 0,
    minWidth: '0',
    border: '1px solid #DADADA',
    transition: '0.3s ease-in',
    backgroundColor: 'white',

    '&:hover': {
      color: '#8F5DE5',
      backgroundColor: 'white'
    }
  },
  HistoryBox: {
    display: 'flex',
    width: 1,
    p: 1,
    flexWrap: 'wrap',
    border: '1px solid #DADADA',
    borderRadius: 1,
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  HistoryBoxStart: {
    display: 'flex',
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    width: {
      xxs: 1,
      sm: 0.65
    },
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  HistoryBoxEnd: {
    display: 'flex',
    alignItems: 'center'
  },
  TabButtonWrapper: {
    '.MuiButtonBase-root': {
      textTransform: 'capitalize'
    }
  },
  MenuDropDown: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
    mt: 1.5,
    ul: {
      pb: 0
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  },
  menuSelect: {
    '.MuiSelect-select': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center'
    }
  }
};

export default Style;
