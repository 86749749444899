// material ui

// zustand
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create } from 'zustand';

type ConfirmDialogStore = {
  title: string;
  message: string;
  onSubmit?: () => void;
  close: () => void;
  handleClose: () => void;
};

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
  title: '',
  message: '',
  onSubmit: undefined,
  handleClose: () => {},
  close: () => set({ onSubmit: undefined })
}));

export const confirmDialog = (
  title: string,
  message: string,
  onSubmit: () => void,
  handleClose: () => void
) => {
  useConfirmDialogStore.setState({
    title,
    message,
    onSubmit,
    handleClose
  });
};

const ConfirmDialog = () => {
  const { title, message, onSubmit, close, handleClose } = useConfirmDialogStore();
  return (
    <>
      <Dialog
        open={Boolean(onSubmit)}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ color: 'common.blackText' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} sx={{ color: 'common.grey' }}>
            No
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) {
                onSubmit();
              }
              close();
              handleClose();
            }}
            autoFocus
            sx={{ color: 'error.main' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
