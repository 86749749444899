import theme from '../../../core/config/theme';

const Style = {
  menuSelectWrapper: {
    minWidth: 120,
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: 2,
    '.MuiSelect-select': {
      p: 1.5
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },

  MenuDropDown: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  },
  menuSelect: {
    '.MuiSelect-select': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center'
    }
  },
  HeaderWrapper: {
    width: 1,
    py: 1,
    zIndex: 5,
    position: 'sticky',
    display: 'flex',
    top: 0,
    bgcolor: 'background.light'
  },
  LogoBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2
  },
  HeaderMainOuter: {
    borderRadius: 55,
    py: 1
  },
  HeaderMain: {
    display: 'flex',

    alignItems: 'center',

    justifyContent: 'space-between'
  },
  MenuWrapper: {
    display: { xxs: 'none', md: 'flex' },
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  Profile: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 64px)'
  },
  UserImage: {
    p: 0,
    minWidth: 'auto',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    overflow: 'hidden',
    ml: 2,
    img: {
      width: 1,
      height: 1,
      objectFit: 'cover'
    }
  },
  MenuUserImage: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    overflow: 'hidden',
    mr: 1,
    img: {
      width: 1,
      height: 1,
      objectFit: 'cover'
    }
  },
  MenuUserImageWrapper: {
    PointerEvent: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  ProfileImageWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  ProfileImage: {
    width: '32px',
    height: '32px',
    mr: 1,
    borderRadius: '50%',
    overflow: 'hidden',
    img: {
      width: 1,
      height: 1,
      objectFit: 'cover'
    }
  },
  HeaderMenu: {
    [theme.breakpoints.down('sm')]: {
      left: '0',
      width: '100%',
      position: 'fixed',
      minHeight: 'calc(100vh - 88px)',
      backgroundColor: '#fff',
      bottom: '0',
      transform: 'translateX(-100%)',
      transition: 'ease all 0.3s',
      '&.ShowMenu': {
        transform: 'translateX(0)'
      }
    }
  },
  MenuList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  MenuItem: {
    ml: { sm: 3, md: 4 },
    [theme.breakpoints.down('sm')]: {
      width: 1,
      p: 2,
      fontSize: '24px'
    }
  },
  HeaderLink: {
    textDecoration: 'none',
    color: 'common.grey',
    '&:hover': {
      color: 'primary.main'
    },
    [theme.breakpoints.down('sm')]: {
      fontWeight: '500'
    }
  },
  LogoutLink: {
    textDecoration: 'none',
    color: 'error.main'
  },
  HumbuggerMenu: {
    display: { xxs: 'flex', sm: 'none' }
  },
  DrawerBlock: {
    p: 2,
    height: 1,
    backgroundColor: '#FCFDFF'
  }
};

export default Style;
