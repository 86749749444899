const Style = {
  OverviewCard: {
    mt: 5,
    display: 'flex',
    alignItems: {
      xxs: 'center',
      sm: 'flex-start'
    },
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    }
  },
  OverviewImageBox: {
    borderRadius: 2,
    overflow: 'hidden',
    width: 1,
    display: 'flex',
    img: {
      width: 1
    }
  },
  OverviewContentBox: {
    my: 2
  },
  ContentLabel: {
    color: 'common.grey',
    mb: 0.5
  },
  BranchSelect: {
    color: 'common.blackText',
    border: '1px solid #DADADA',
    borderRadius: 2,
    mr: 1,
    py: 0.5,
    px: 1,
    '.MuiSelect-select': {
      backgroundColor: 'transparent',
      display: 'flex'
    },
    '&.MuiInputBase-root': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    }
  },
  StatusButton: {
    borderRadius: 50,
    backgroundColor: 'common.green.100',
    color: 'common.green.800',
    '&:hover': {
      color: 'common.white'
    }
  },
  SettingButton: {
    color: '#6B6B6B',
    py: 1,
    px: 1,
    minWidth: '0',
    border: '1px solid #DADADA',
    transition: '0.3s ease-in',
    '&:hover': {
      color: '#8F5DE5',
      backgroundColor: 'transparent'
    }
  },
  HistoryBox: {
    display: 'flex',
    width: 1,
    px: { xxs: 0, sm: 1 },
    py: 1,
    flexWrap: 'wrap',
    borderRadius: 1,
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px'
  },
  HistoryBoxStart: {
    display: 'flex',
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    width: {
      xxs: 1,
      md: 1,
      lg: 0.73
    },
    gap: { xxs: 2, md: '12%' },
    flexWrap: 'wrap',

    alignItems: 'center'
  },
  HistoryBoxEnd: {
    display: 'flex',
    alignItems: 'center'
  },
  ModalStyle: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: 2,
    transform: 'translate(-50%, -50%)',
    width: 0.6,
    maxHeight: 500,

    border: 'none',
    bgcolor: 'background.paper',

    p: 4,
    '&:focus': {
      border: 'none'
    }
  },
  AccordionWrapper: {
    boxShadow: 'none',
    border: '1px solid',

    mb: 2,
    borderColor: 'action.focus'
  },
  AccordionDetails: {
    borderTop: '1px solid',
    borderColor: 'action.focus',
    position: 'relative'
  },
  AccordionHead: {
    width: 1,
    display: 'flex',
    my: 0,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  UserImage: {
    p: 0,
    minWidth: 'auto',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
    ml: 2,
    img: {
      width: 1,
      height: 1,
      objectFit: 'cover'
    }
  }
};

export default Style;
