const Style = {
  Heading: {
    fontSize: '20px',
    color: 'common.blackText',
    ml: { xxs: 1, sm: 1.5 }
  },
  LogsCard: {
    p: { xxs: 2, sm: 3.5, lg: 4.5 },

    mb: 3,
    '&:last-child': {
      mb: 0
    },
    '&.disable': {
      bgcolor: 'grey.200',
      pointerEvents: 'none'
    }
  },
  AccordionDetails: {
    borderTop: '1px solid #DADADA',
    p: 3,
    position: 'relative'
  },
  LogsCardHead: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 1,
    alignItems: 'center',

    justifyContent: 'space-between'
  },
  AccordianBoxes: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
    overflowY: 'auto',
    height: { xxs: 'calc(100vh - 400px)', sm: 'calc(100vh - 270px)' }
  },

  LogsCardTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  AddOnButton: {
    borderRadius: 0,
    py: 2,
    borderLeft: '1px solid',
    borderColor: 'action.focus'
  },
  RefreshIcon: {
    cursor: 'pointer',
    display: 'flex',
    borderColor: 'common.grey'
  },
  AccordionWrapper: {
    boxShadow: 'none',
    borderRadius: '16px !important',
    '&.MuiAccordion-root': {
      borderRadius: 1
    },
    '&:before': {
      display: 'none'
    }
  },

  AccordionHead: {
    width: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  IconWithText: {
    pr: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  NoDataFoundWrapper: {
    width: 1,

    borderRadius: 5,
    textAlign: 'center',
    mt: 1,
    cursor: 'pointer'
  },
  BranchNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    mr: 2
  },
  EnviromentVariables: {
    border: '1px dashed #DADADA',
    // p: 4,
    borderRadius: 4,
    color: '#1E1E1E'
  },
  Subtitle2: {
    fontSize: '18px',
    lineHeight: '27px',
    mt: 1
  },
  AddButton: {
    fontSize: '48px',
    borderRadius: '50%',
    mt: 5,
    mb: 4,
    width: '36px',
    height: '63px'
  },
  DetailsHeader: {
    display: 'flex',
    width: 1,
    maxWidth: 1200,
    mx: 'auto',

    gap: 2,

    justifyContent: 'space-between',
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },

    alignItems: 'center'
  },
  SettingButton: {
    color: '#6B6B6B',
    p: 0,
    minWidth: '0',
    border: '1px solid #DADADA',
    transition: '0.3s ease-in',
    backgroundColor: 'white',

    '&:hover': {
      color: '#8F5DE5',
      backgroundColor: 'white'
    }
  },
  ReDetectButton: {
    p: 0,

    svg: {
      '&:hover': {
        path: {
          stroke: '#8F5DE5'
        }
      }
    },
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  IconSize: { height: '32px', width: '32px' },
  ReDetectButtonHead: {
    p: 0,
    height: '24px',
    width: '24px',
    mr: 1,

    minWidth: 0,
    svg: {
      path: {
        stroke: '#8F5DE5'
      }
    }
  },
  MenuDropDown: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};

export default Style;
