import styled from '@emotion/styled';

const SectionWrapper = styled.div`
  max-height: 1024px;
  height: 100vh;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
  }
  .yellowPlanet {
    position: absolute;
    bottom: 2%;
    left: 70%;
    transition: ease all 1.5s;
  }
`;
const ProcessMain = styled.div`
  width: 100%;
  margin: auto;
  .sectionContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    .content {
      width: 50%;
      padding-left: 45px;
      @media only screen and (max-width: 991px) {
        order: 1;
        padding: 0 0 30px 0;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;

        padding: 0 0 20px 0;
      }
    }
    .image {
      width: 50%;
      @media only screen and (max-width: 991px) {
        order: 2;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        order: 2;
      }
      @media only screen and (max-height: 899px) {
        img {
          width: 90%;
        }
      }
      @media (max-width: 767px) and (max-height: 899px) {
        img {
          width: 100%;
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 24px;
      @media only screen and (max-width: 820px) {
        font-size: 28px;
        line-height: 38px;
      }
      span {
        background: linear-gradient(90deg, #ea2bff 0%, #c50cda 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    p {
      font-size: 18px;
      line-height: 27px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .patterPlanet {
      position: absolute;
      z-index: -1;
      left: -3%;
      top: -5%;
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    .robot {
      width: 200px;
      position: absolute;
      top: -35%;
      left: 40%;
      z-index: -1;
      opacity: 0.1;
      transition: ease all 1.5s;
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }
`;

const SmBluePlanet = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: -1;
`;
const MarsPlanet = styled.div`
  position: absolute;
  top: 25%;
  left: -3%;
  height: 100%;
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  svg {
    height: 100%;
    width: 100%;
  }
  transform: scale(1.2);
`;
const SpaceGuy = styled.div`
  position: absolute;
  top: 20%;
  right: 10%;
  z-index: -1;
  opacity: 0.4;
  transform: rotate(30deg);
`;

export { SectionWrapper, ProcessMain, SmBluePlanet, MarsPlanet, SpaceGuy };
