import theme from '../../../core/config/theme';

const Style = {
  FooterMain: {
    mt: 'auto',
    py: 3
  },
  FooterContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: { xxs: 'center', md: 'space-between' }
  },
  LinkWrapper: {
    mt: { xxs: 1, md: 0 },
    textAlign: 'center',
    width: { xxs: 1, md: 'auto' }
  },
  CopyrightText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  FooterLink: {
    textDecoration: { xxs: 'underline', md: 'none' },
    color: 'common.grey',
    cursor: 'pointer',
    mx: { xxs: 0.5, sm: 1.5, md: 2 },
    '&:hover': {
      color: 'primary.main'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  }
};

export default Style;
