export default function FeatureIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7688 17.9326H12.633L12.5158 18.0013L7.01591 21.2283L8.07623 18.6209L8.35615 17.9326H7.61306H6.09799C4.65274 17.9326 3.5 16.7824 3.5 15.3529V5.0797C3.5 3.65018 4.65274 2.5 6.09799 2.5H17.902C19.3473 2.5 20.5 3.65018 20.5 5.0797V15.3529C20.5 16.7599 19.3246 17.9326 17.902 17.9326H12.7688Z"
        fill="#FCFDFF"
        stroke="#664CCD"
      />
      <path
        d="M16.7156 11.1702V9.75352C16.7156 9.59162 16.5941 9.47019 16.4322 9.47019H15.8453C15.7644 9.10589 15.6024 8.76184 15.4203 8.45826L15.8251 8.05349C15.9465 7.93206 15.9465 7.74992 15.8251 7.64873L14.8334 6.6368C14.712 6.51537 14.5298 6.51537 14.4286 6.6368L14.0238 7.04157C13.7 6.83919 13.356 6.69752 12.9917 6.59633V6.00941C12.9917 5.8475 12.8703 5.72607 12.7084 5.72607H11.2917C11.1298 5.72607 11.0083 5.8475 11.0083 6.00941V6.59633C10.644 6.67728 10.3 6.83919 9.9964 7.04157L9.59163 6.6368C9.4702 6.51537 9.28805 6.51537 9.18686 6.6368L8.19518 7.62849C8.07375 7.74992 8.07375 7.93206 8.19518 8.03326L8.59995 8.43803C8.4178 8.76184 8.27613 9.10589 8.17494 9.44995H7.58803C7.42612 9.44995 7.30469 9.57138 7.30469 9.73329V11.15C7.30469 11.3119 7.42612 11.4333 7.58803 11.4333H8.1547C8.23566 11.7976 8.39756 12.1417 8.57971 12.4452L8.17494 12.85C8.05351 12.9714 8.05351 13.1536 8.17494 13.2548L9.16662 14.2465C9.28805 14.3679 9.4702 14.3679 9.57139 14.2465L9.97616 13.8417C10.3 14.0441 10.644 14.1655 10.9881 14.2667V14.8536C10.9881 15.0155 11.1095 15.1369 11.2714 15.1369H12.6881C12.85 15.1369 12.9715 15.0155 12.9715 14.8536V14.2667C13.3357 14.1857 13.6798 14.0238 13.9834 13.8417L14.3881 14.2465C14.5096 14.3679 14.6917 14.3679 14.7929 14.2465L15.7846 13.2548C15.906 13.1333 15.906 12.9512 15.7846 12.85L15.3798 12.4452C15.562 12.1214 15.7036 11.7774 15.8048 11.4333H16.412C16.5739 11.4536 16.7156 11.3321 16.7156 11.1702ZM12.1417 12.6476C10.8059 12.7286 9.7333 11.6559 9.81425 10.3202C9.87497 9.22732 10.7655 8.33683 11.8583 8.27612C13.1941 8.19516 14.2667 9.2678 14.1858 10.6035C14.1048 11.6964 13.2143 12.5869 12.1417 12.6476Z"
        fill="#664CCD"
      />
    </svg>
  );
}
