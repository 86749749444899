import * as Style from './SubscribeForm.style';
import { EmailFormFields } from 'react-mailchimp-subscribe';
import { useForm, SubmitHandler } from 'react-hook-form';

const SubscribeForm = ({
  status,
  message,
  onValidated
}: {
  status: string | null;
  message: string | Error | null;
  onValidated: (data: EmailFormFields) => void;
}) => {
  type Inputs = {
    email: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({});

  const onSubmit: SubmitHandler<Inputs> = (data: { email: any }) => {
    onValidated({
      EMAIL: data.email
    });
  };

  return (
    <>
      <Style.StatusBox>
        {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
        {status === 'error' && (
          <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message as string }} />
        )}
        {errors?.email && <div style={{ color: 'red' }}>{errors?.email?.message}</div>}
        {status === 'success' && <div style={{ color: 'green' }}>Subscribed successfully!</div>}
      </Style.StatusBox>
      <Style.FormBox onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder="Enter email address"
          className={errors?.email ? 'error' : ''}
          autoComplete="off"
          {...register('email', {
            required: 'Required',
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            }
          })}
        />
        <button type="submit">Subscribe for Free</button>
      </Style.FormBox>
      {/* <SocialIcons /> */}
    </>
  );
};

export default SubscribeForm;
