const Style = {
  Heading: {
    color: 'common.blackText',
    textAlign: 'center',
    mb: { sm: 1, md: 2 }
  },
  LoginSliderHeader: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '46px',
    color: '#FFFFFF',
    textAlign: 'start'
  },
  LoginSliderSubTitle: {
    fontSize: '18px',
    fontWeight: 400,
    color: '#FCFDFF',
    my: 4,
    textAlign: 'start'
  },
  SignUpBox: {
    border: '1px solid #D0D5DD',
    borderRadius: '32px',
    pt: 2,
    pb: 2,
    px: 4.5,
    mb: 2
  },
  ButtonWrapper: {
    maxWidth: '380px',
    margin: 'auto',
    button: {
      width: 1,
      border: '1px solid',
      borderRadius: '8px',
      mt: 2,
      p: 2,
      fontWeight: 500,
      img: {
        mr: 2
      },
      '&.Mui-disabled': {
        img: {
          opacity: '0.5'
        }
      }
    }
  },
  GithubButton: {
    background: 'rgba(35, 30, 27, 0.04)',
    color: 'common.blackText',
    borderColor: 'common.blackText'
  },
  GitlabButton: {},
  BitbucketButton: {},
  ComingSoonLabel: {
    paddingLeft: '4px',
    fontSize: 9
  },
  LogoWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  LogoBox: {
    width: { xxs: 'calc(100% / 2)', sm: 'calc(100% / 4)' }
  }
};

export default Style;
