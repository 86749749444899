const Style = {
  HighlightHeaderText: {
    background: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text'
  },
  CardTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px'
  },
  BestValueTag: {
    background: 'rgba(43, 170, 255, 0.1)',
    backdropFilter: 'blur(12px)',
    borderRadius: '44px',
    padding: '12px 20px',
    margin: '-60px 0px 12px 0',
    width: 'fit-content',
    alignSelf: 'center'
  },
  StartButton: {
    minWidth: '100%',
    borderRadius: '8px',
    px: 4,
    fontSize: '16px',
    py: 2
  }
};

export default Style;
