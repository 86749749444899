import React, { Fragment } from 'react';
import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/nightOwl';

const styles: any = {
  editor: {
    width: '100%',
    display: 'inline-flex'
  },
  lineNo: {
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    lineHeight: '1em'
  },
  editorBox: {
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    ...theme.plain
  },
  preLine: {
    textAlign: 'right',
    margin: '0.2em 0',
    padding: '0.5em'
  },
  liveView: {
    textAlign: 'left',
    display: 'block',
    margin: '1.5em 0',
    padding: '0.5em',
    width: '100%',
    height: '100px'
  }
};

const CodeEditor = (prop: any) => {
  const exampleCode = `# Hello world

  > Block quote.

  Some _emphasis_, **importance**, and \`code\`.`;

  // const textInput = useInput(prop.value);

  const highlight = () => (
    <Highlight {...defaultProps} theme={theme} code={prop.value} language="jsx">
      {({ tokens, getLineProps, getTokenProps }: any) => (
        <Fragment>
          {tokens.map((line: any, i: any) => (
            <div key={i + 1} {...getLineProps({ line, key: i })}>
              {line.map((token: any, key: any) => (
                <span key={i + 1} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  );

  const LineNumbers = () => {
    return (
      <Highlight {...defaultProps} theme={theme} code={prop.value} language="jsx">
        {({ tokens, getLineProps }: any) => (
          <div style={styles.preLine}>
            {tokens.map((line: any, i: any) => (
              <div key={i + 1} {...getLineProps({ line, key: i })}>
                <span style={styles.lineNo}>{i + 1}</span>
              </div>
            ))}
          </div>
        )}
      </Highlight>
    );
  };

  return (
    <div style={styles.root}>
      <div style={styles.editor}>
        <LineNumbers />
        <Editor
          value={prop.value || ' '}
          onValueChange={(text) => prop.onValueChange(text)}
          highlight={highlight}
          padding={10}
          style={styles.editorBox}
        />
      </div>
    </div>
  );
};

export default CodeEditor;
