import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import crossImage from '../../assets/images/crossImage.svg';
import headerCrossCircle from '../../assets/images/headerCrossCircle.svg';
import headerDangerSign from '../../assets/images/headerDangerSign.svg';
import headerTickCircle from '../../assets/images/headerTickCircle.svg';
import tickCircleImage from '../../assets/images/tickCircleImage.svg';
import { AuthService } from '../../services/API/AuthService';
import { BuildService } from '../../services/API/BuildService';
import { DeployService } from '../../services/API/DeployService';
import { DetectService } from '../../services/API/DetectService';
import { ProjectService } from '../../services/API/ProjectService';
import Style from './Status.style';

export const StatusPage: FC = () => {
  const enum serviceStatus {
    Ok,
    Outage
  }

  const enum GlobalStatusEnum {
    AllServiceOprational,
    SomeServiceOpearional,
    NoServiceOprational
  }

  const serviceArray = [
    {
      serviceName: 'Auth Service',
      status: serviceStatus.Outage
    },
    {
      serviceName: 'Project Service',
      status: serviceStatus.Outage
    },
    {
      serviceName: 'Build Service',
      status: serviceStatus.Outage
    },
    {
      serviceName: 'Deploy Service',
      status: serviceStatus.Outage
    },
    {
      serviceName: 'Detection Service',
      status: serviceStatus.Outage
    }
  ];

  const [allService, setAllService] =
    useState<{ serviceName: string; status: serviceStatus }[]>(serviceArray);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const authClient = new AuthService();
  const projectClient = new ProjectService();
  const buildClient = new BuildService();
  const deployClient = new DeployService();
  const detectClient = new DetectService();

  const [globalStatus, setGlobalStatus] = useState<GlobalStatusEnum>(2);

  async function handleAuthStatus() {
    const result = await authClient.GetHealthCheck();
    if (result) {
      const item = [...allService];
      item[0].status = serviceStatus.Ok;
      setAllService(item);
    } else {
      const item = [...allService];
      item[0].status = serviceStatus.Outage;
      setAllService(item);
    }
  }

  async function handleProjectStatus() {
    const result = await projectClient.GetHealthCheck();
    if (result) {
      const item = [...allService];
      item[1].status = serviceStatus.Ok;
      setAllService(item);
    } else {
      const item = [...allService];
      item[1].status = serviceStatus.Outage;
      setAllService(item);
    }
  }
  async function handleDetectStatus() {
    const result = await detectClient.GetHealthCheck();
    if (result) {
      const item = [...allService];
      item[4].status = serviceStatus.Ok;
      setAllService(item);
    } else {
      const item = [...allService];
      item[4].status = serviceStatus.Outage;
      setAllService(item);
    }
  }
  async function handleDeployStatus() {
    const result = await deployClient.GetHealthCheck();
    if (result) {
      const item = [...allService];
      item[3].status = serviceStatus.Ok;
      setAllService(item);
    } else {
      const item = [...allService];
      item[3].status = serviceStatus.Outage;
      setAllService(item);
    }
  }
  async function handleBuildStatus() {
    const result = await buildClient.GetHealthCheck();
    if (result) {
      const item = [...allService];
      item[2].status = serviceStatus.Ok;
      setAllService(item);
    } else {
      const item = [...allService];
      item[2].status = serviceStatus.Outage;
      setAllService(item);
    }
  }

  function handleGetAllServiceHealthCheck() {
    setIsSkeleton(true);
    Promise.all([
      handleAuthStatus(),
      handleProjectStatus(),
      handleDetectStatus(),
      handleDeployStatus(),
      handleBuildStatus()
    ]).finally(() => {
      let totalLength = allService.length;
      let okServices = allService.filter((s) => s.status === serviceStatus.Ok)?.length || 0;
      let errServices = allService.filter((s) => s.status === serviceStatus.Outage)?.length || 0;

      if (totalLength === okServices) {
        setGlobalStatus(GlobalStatusEnum.AllServiceOprational);
      } else if (totalLength === errServices) {
        setGlobalStatus(GlobalStatusEnum.NoServiceOprational);
      } else {
        setGlobalStatus(GlobalStatusEnum.SomeServiceOpearional);
      }
      setIsSkeleton(false);
    });
  }

  useEffect(() => {
    handleGetAllServiceHealthCheck();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.StatusHeader}>
          {isSkeleton ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 5,
                width: 1
              }}>
              <Skeleton width={'80px'} height={'80px'} variant="circular" />
              <Skeleton sx={{ mt: 2, width: 0.4 }} variant="rounded" height={22} />
            </Box>
          ) : (
            <Box>
              <img
                src={
                  globalStatus === GlobalStatusEnum.AllServiceOprational
                    ? headerTickCircle
                    : globalStatus === GlobalStatusEnum.NoServiceOprational
                    ? headerCrossCircle
                    : headerDangerSign
                }
                alt={'status'}
              />
              <Typography
                sx={{ fontSize: '20px', mt: -4, lineHeight: '30px', color: 'black' }}
                variant="h2">
                {' '}
                {globalStatus === GlobalStatusEnum.AllServiceOprational
                  ? 'All Services are Operational'
                  : globalStatus === GlobalStatusEnum.NoServiceOprational
                  ? ' Sorry! We’re fixing this issue...'
                  : 'Some Services are Not-operational'}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ width: 0.9, mx: 'auto', mt: 5 }}>
          <Grid container spacing={4}>
            {allService.map((Element, index) => (
              <Grid item xs={12} sm={6} key={index + 1} width={1}>
                {isSkeleton ? (
                  <Box sx={Style.ServiceList}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: 1
                      }}>
                      <Typography
                        sx={{ color: 'black', fontWeight: '500', width: 1 }}
                        variant="body2">
                        <Skeleton variant="rounded" sx={{ width: 0.7 }} height={22} />
                      </Typography>
                      <Typography sx={{ mt: 0.5, width: 1 }} variant="body1">
                        <Skeleton variant="rounded" sx={{ width: 0.4 }} height={22} />
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItem: 'center' }}>
                      <Skeleton width={'32px'} height={'32px'} variant="circular" />
                    </Box>
                  </Box>
                ) : (
                  <Box sx={Style.ServiceList}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography sx={{ color: 'black', fontWeight: '500' }} variant="body2">
                        {Element.serviceName}{' '}
                      </Typography>
                      <Typography sx={{ mt: 0.5 }} variant="body1">
                        {Element.status === serviceStatus.Ok
                          ? ''
                          : Element.status === serviceStatus.Outage
                          ? 'Outage'
                          : ''}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItem: 'center' }}>
                      <img
                        src={
                          Element.status === serviceStatus.Ok
                            ? tickCircleImage
                            : Element.status === serviceStatus.Outage
                            ? crossImage
                            : ''
                        }
                        alt="status"
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
