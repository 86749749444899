import { FC, useEffect, useState } from 'react';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '../../components/shared/Loader';
import { LoginRequest } from '../../models';
import { AuthService } from '../../services/API/AuthService';
import { notifySuccess } from '../../services/toster';
import { authDetailsReducer, getAuthState, setAuthDetails } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { PageRoutes } from '../../utils/constants';
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const AuthComplete: FC = () => {
  const [searchParams] = useSearchParams();
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const authService = new AuthService();
  async function setAuth(data: authDetailsReducer) {
    await dispatch(setAuthDetails(data));
  }

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    if (code && state === auth.state && state) {
      (async () => {
        await handleLogin(code, state);
      })();
    } else {
      if (state == 'repository') {
        navigate(
          {
            pathname: PageRoutes.Repository,
            search: 'isOrg=true'
          },
          { replace: true }
        );
      } else {
        navigate(PageRoutes.Login, { replace: true });
      }
    }
  }, []);

  const getUserDetails = async (res: any) => {
    const response = await authService.GetUserDetails();
    if (response?.ID) {
      await setAuth({
        ...res,
        user_details: response,
        provider: response.Provider
      } as authDetailsReducer);
    }
  };

  const handleLogin = async (code: string, state: string) => {
    const requestBody: LoginRequest = {
      code: code,
      state: state
    };
    setIsLoader(true);
    const response = await authService.Login(requestBody);
    setIsLoader(false);
    if (response.access_token) {
      if (auth.isVscodeLogin) {
        setAuth({ ...auth, isVscodeLogin: false, state: '' });
        const vscodeOpen = window.open(
          `${process.env.REACT_APP_VSCODE_URI as string}?state=${state}&token=${
            response.access_token
          }&refreshToken=${response.refresh_token}`,
          '_self',
          ''
        );
        if (vscodeOpen) {
          window.open(location.href, '_self', '');
        }
        return;
      }
      localStorage.setItem('auth', JSON.stringify(response));
      await getUserDetails(response);

      notifySuccess('Login Successfully');
      return navigate(PageRoutes.Dashboard, { replace: true });
    } else {
      navigate(PageRoutes.Login, { replace: true });
    }
  };

  return <Loader isShow={isLoader} />;
};
