import { Box, Container } from '@mui/system';
import { FC } from 'react';
import Style from './SelectProjectType.style';
import selectProject from '../../assets/images/selectProject.svg';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '../../assets/icons/AddIcon';
import ImportIcon from '../../assets/icons/ImportIcon';
import AddIconLarge from '../../assets/icons/AddIcon32*32';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../utils/constants';
import CrossCancle from '../../assets/icons/CrossCancle/CrossCancle';
import { Cancel, Close } from '@mui/icons-material';
const SelectProjectType: FC = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          textAlign: 'center',
          py: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 0.7,
          m: 'auto'
        }}>
        <Box></Box>
        <Typography variant="h1" sx={{ color: '#1E1E1E', fontWeight: 500 }}>
          Start a New Project
        </Typography>
        <IconButton onClick={() => navigate(PageRoutes.Dashboard)}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={Style.SelectProjectTypeWraper}>
        <Box sx={{ textAlign: 'center', mt: 1 }}>
          <img src={selectProject} />
        </Box>
        <Box sx={Style.SelectProjectType}>
          <Grid container sx={{ mt: 1 }} spacing={3}>
            <Grid item xs={12} sm={6} sx={{ width: 1 }}>
              <Box onClick={() => navigate(PageRoutes.BuildNewProject)} sx={Style.BlankProject}>
                <AddIconLarge />
                <Typography variant="body2" sx={{ color: '#1E1E1E', fontWeight: 600 }}>
                  New Project
                </Typography>
                <Typography variant="caption">Build a project from template</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ width: 1 }}>
              <Box
                onClick={() => navigate(PageRoutes.Repository)}
                sx={{
                  background: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)',
                  padding: '1px',
                  borderRadius: '8px'
                }}>
                <Box sx={Style.ImportRepo}>
                  <ImportIcon />
                  <Typography variant="body2" sx={{ color: '#1E1E1E', fontWeight: 600 }}>
                    Import Repository
                  </Typography>
                  <Typography variant="caption">Build a project from scratch</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
export default SelectProjectType;
