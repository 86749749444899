import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FC, useState } from 'react';
import { PortsForm } from '..';
import DoNotDisturbOnIcon from '../../../assets/icons/DoNotDisturbOn';
import { PortRange } from '../../../utils/constants';

export const PortSelect: FC<PortsForm> = (props) => {
  const [errorPort, setErrorPort] = useState(false);

  return (
    <>
      <Grid item xs={12} md={4} sx={{ width: 1 }}>
        <TextField
          variant="outlined"
          label="Port"
          error={!props.Port || errorPort ? true : false || (props.Port === '0' && true)}
          helperText={
            (!props.Port && 'This field is required') ||
            (errorPort && 'Port is invalid') ||
            (props.Port === '0' && 'Port is invalid')
          }
          type="number"
          fullWidth
          autoComplete="off"
          placeholder="Port"
          id="Port"
          value={props.Port || ''}
          onChange={(event) => {
            props.onChange('Port', event?.target.value);
            if (
              +event.target.value < PortRange.MinPortRange ||
              +event.target.value > PortRange.MaxPortRange
            ) {
              setErrorPort(true);
            } else {
              setErrorPort(false);
            }
          }}
          aria-describedby="outlined-weight-helper-text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControl
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    }
                  }}>
                  <Select
                    value={props.Protocol || ''}
                    onChange={(event) => {
                      props.onChange('Protocol', event?.target.value);
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Port' }}>
                    <MenuItem value="">Both</MenuItem>
                    <MenuItem value="udp">UDP</MenuItem>
                    <MenuItem value="tcp">TCP</MenuItem>
                  </Select>
                </FormControl>
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={8} sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            label="HealthCheck Endpoint"
            fullWidth
            autoComplete="off"
            id="Value"
            value={props.HealthCheck || ''}
            onChange={(event) => {
              props.onChange('HealthCheck', event?.target.value);
            }}
            placeholder="Helthcheck Endpoint"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'RootDirectory'
            }}
          />
          {/* Temprory we are removing this button*/}
          {props.IsDetect && (
            <Button
              disabled={props.TotalLength === 1}
              sx={{
                color: 'common.grey',
                ml: { xxs: 1, sm: 2, minWidth: 'auto' },
                px: 0,
                '&:hover': { backgroundColor: 'transparent' }
              }}
              onClick={() => {
                props.onRemove();
              }}>
              <DoNotDisturbOnIcon sx={{ mr: { sm: 1 } }} />
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default PortSelect;
