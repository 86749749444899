import { Box, List, ListItem, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { FC, useEffect } from 'react';
import Style from './PrivacyPolicy.style';
import * as Scroll from 'react-scroll';
const PrivacyPolicy: FC = () => {
  var scroll = Scroll.animateScroll;

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ padding: { xxs: 4, md: 3 } }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: { xxs: '36px', md: '64px' },
          color: 'black',
          textAlign: 'center',
          fontWeight: 500,
          my: { xxs: 4, md: 6 }
        }}>
        Privacy Policy
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xxs: '2rem', md: '4rem' },
          mt: { xxs: '2rem', md: '4rem' }
        }}>
        <Typography sx={Style.PrivacyParaStyle}>
          Build and Deploy (“us”, “we”, or “our”){' '}
          <a href="https://buildndeploy.co">https://buildndeploy.co</a> website (hereinafter
          referred to as the “Service”). This page informs you of our policies regarding the
          collection, use, and disclosure of personal data when you use our Service and the choices
          you have associated with that data. The Privacy Policy for BnD has been created with the
          help of Terms Feed. We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy
          Policy have the same meanings as in our Terms and Conditions, accessible from{' '}
          <a href="https://buildndeploy.co">https://buildndeploy.co</a>
        </Typography>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Service
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Service is the <a href="https://buildndeploy.co">https://buildndeploy.co</a> website
            operated by BnD
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Personal Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Personal Data means data about a living individual who can be identified from those data
            (or from those and other information either in our possession or likely to come into our
            possession).
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Usage Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Usage Data is data collected automatically either generated by the use of the Service or
            from the Service infrastructure itself (for example, the duration of a page visit).
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Cookies
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Cookies are small files stored on your device (computer or mobile device).
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Information Collection and Use
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            We collect several different types of information for various purposes to provide and
            improve our Service to you.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography
            variant="h1"
            sx={{
              ...Style.PrivacyHeading,
              fontSize: { sm: '36px', md: '48px' },
              color: 'black',
              fontWeight: 500,
              my: { sm: '1rem', md: '2rem' }
            }}>
            Types of Data Collected
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Personal Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            While using our Service, we may ask you to provide us with certain personally
            identifiable information that can be used to contact or identify you (“Personal Data”).
            Personally, identifiable information may include, but is not limited to:
          </Typography>
          <List
            sx={{
              pl: 5
            }}>
            <ListItem sx={Style.ListItemStyle}>Email address </ListItem>
            <ListItem sx={Style.ListItemStyle}> First name and last name </ListItem>
            <ListItem sx={Style.ListItemStyle}> Phone number</ListItem>
          </List>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography
            variant="h1"
            sx={{
              ...Style.PrivacyHeading,
              fontSize: { sm: '36px', md: '48px' },
              color: 'black',
              fontWeight: 500,
              my: { sm: '1rem', md: '2rem' }
            }}>
            Cookies and Usage Data
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Usage Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            UWe may also collect information on how the Service is accessed and used (“Usage Data”).
            This Usage Data may include information such as your computer’s Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages of our Service that
            you visit, the time and date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Tracking & Cookies Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            We use cookies and similar tracking technologies to track the activity on our Service
            and we hold certain information.
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Cookies are files with a small amount of data which may include an anonymous unique
            identifier. Cookies are sent to your browser from a website and stored on your device.
            Other tracking technologies are also used such as beacons, tags, and scripts to collect
            and track information and to improve and analyze our Service.
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is
            being sent. However, if you do not accept cookies, you may not be able to use some
            portions of our Service.
          </Typography>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500, my: 1 }}>
              Examples of Cookies we use:
            </Typography>
            <List
              sx={{
                pl: 5
              }}>
              <ListItem sx={Style.ListItemStyle}>
                Session Cookies. We use Session Cookies to operate our Service.
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>
                Preference Cookies. We use Preference Cookies to remember your preferences and
                various settings.{' '}
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>
                Security Cookies. We use Security Cookies for security purposes.
              </ListItem>
            </List>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500, mt: 1 }}>
              Use of Data
            </Typography>
            <Typography sx={{ ...Style.PrivacyParaStyle, my: 2 }}>
              BnD uses the collected data for various purposes:
            </Typography>
            <List
              sx={{
                pl: 5
              }}>
              <ListItem sx={Style.ListItemStyle}>To provide and maintain the Service</ListItem>
              <ListItem sx={Style.ListItemStyle}>
                To notify you about changes to our Service
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>
                To allow you to participate in interactive features of our Service when you choose
                to do so
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>To provide customer care and support</ListItem>
              <ListItem sx={Style.ListItemStyle}>
                To detect, prevent and address technical issues
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>
                To provide analysis or valuable information so that we can improve the Service
              </ListItem>
              <ListItem sx={Style.ListItemStyle}>To monitor the usage of the Service</ListItem>
            </List>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500, my: 1 }}>
              Transfer Of Data
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              Your information, including Personal Data, may be transferred to – and maintained on –
              computers located outside of your state, province, country or other governmental
              jurisdiction where the data protection laws may differ than those from your
              jurisdiction.
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              If you are located outside the United States and choose to provide information to us,
              please note that we transfer the data, including Personal Data, to the United States
              and process it there.
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              Your consent to this Privacy Policy followed by your submission of such information
              represents your agreement to that transfer.
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              BnD will take all steps reasonably necessary to ensure that your data is treated
              securely and in accordance with this Privacy Policy and no transfer of your Personal
              Data will take place to an organization or a country unless there are adequate
              controls in place including the security of your data and other personal information.
            </Typography>
          </Box>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography
            variant="h1"
            sx={{
              ...Style.PrivacyHeading,
              fontSize: { sm: '36px', md: '48px' },
              color: 'black',
              fontWeight: 500,
              my: { sm: '1rem', md: '2rem' }
            }}>
            Disclosure Of Data
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Legal Requirements
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            BnD may disclose your Personal Data in the good faith belief that such action is
            necessary to:
          </Typography>
          <List
            sx={{
              pl: 5
            }}>
            <ListItem sx={Style.ListItemStyle}>To comply with a legal obligation</ListItem>
            <ListItem sx={Style.ListItemStyle}>
              To protect and defend the rights or property of BnD
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              To prevent or investigate possible wrongdoing in connection with the Service
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              To protect the personal safety of users of the Service or the public
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>To protect against legal liability</ListItem>
          </List>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography
            variant="h1"
            sx={{
              ...Style.PrivacyHeading,
              fontSize: { sm: '36px', md: '48px' },
              color: 'black',
              fontWeight: 500,
              my: { sm: '1rem', md: '2rem' }
            }}>
            Security of Data
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            The security of your data is important to us but remember that no method of transmission
            over the Internet or method of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we cannot guarantee its
            absolute security.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Service Providers
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            We may employ third-party companies and individuals to facilitate our Service (“Service
            Providers”), to provide the Service on our behalf, to perform Service-related services,
            or to assist us in analyzing how our Service is used.
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            These third parties have access to your Personal Data only to perform these tasks on our
            behalf and are obligated not to disclose or use it for any other purpose.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Children’s Privacy
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Our Service does not address anyone under the age of 18 (“Children”).
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            We do not knowingly collect personally identifiable information from anyone under the
            age of 18. If you are a parent or guardian and you are aware that your Child has
            provided us with Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            Changes to This Privacy Policy
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page. We will let you know via email and/or a
            prominent notice on our Service, prior to the change becoming effective and update the
            “effective date” at the top of this Privacy Policy
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox} style={{ paddingBottom: '2rem' }}>
          <Typography
            variant="h1"
            sx={{
              ...Style.PrivacyHeading,
              fontSize: { sm: '36px', md: '48px' },
              color: 'black',
              fontWeight: 500,
              my: { sm: '1rem', md: '2rem' }
            }}>
            Contact Us
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            If you have any questions about this Privacy Policy, please contact us:
            <a href="mailto:hello@buildndeploy.co"> hello@buildndeploy.co</a>
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            Privacy Policy of <a href="https://buildndeploy.co">https://buildndeploy.co</a>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
export default PrivacyPolicy;
