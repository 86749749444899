import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Style from './NoDataFound.styles';
export const NoDataFound: FC<{ message?: string }> = (props) => {
  return (
    <Box sx={Style.NoDataFoundWrapper}>
      <Typography variant="h2">{props.message ? props.message : 'No Data Found'}</Typography>
    </Box>
  );
};

export default NoDataFound;
