import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Button, SwipeableDrawer } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container } from '@mui/system';
import { FC, KeyboardEvent, MouseEvent, ReactNode, SyntheticEvent, useState } from 'react';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useLocation, useNavigate } from 'react-router';
import { PageRoutes } from '../../utils/constants';
import Style from './Documentation.style';
import { Link } from 'react-router-dom';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: '22px',

  borderLeft: '1px solid rgba(0, 0, 0, .125)'
}));

const DocumentationPage: FC<{ children: ReactNode | ReactNode[] }> = (props) => {
  const [expanded, setExpanded] = useState<string | false>('');
  const [state, setState] = useState(false);
  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };
  return (
    <Container maxWidth="xl" sx={{ xxs: 3, md: 2 }}>
      {
        <ReadMoreIcon
          sx={{ display: { xxs: 'block', md: 'none' } }}
          onClick={toggleDrawer('left', true)}
        />
      }
      <SwipeableDrawer
        anchor={'left'}
        open={state}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}>
        <Box
          sx={{
            maxHeight: '50vh',
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}>
          <Box
            onClick={(event: any) => {
              toggleDrawer('left', false)(event);
              navigate(PageRoutes.DocumentationPage);
            }}
            sx={{
              px: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mt: 2,
              justifyContent: 'space-between',
              cursor: 'pointer'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  height: '2px',
                  width: '12px',

                  backgroundColor: '#DADADA'
                }}></Box>
              <Typography
                sx={{ fontWeight: location.pathname.includes('documentation') ? 600 : 'none' }}>
                Introduction
              </Typography>
            </Box>
            <Box onClick={toggleDrawer('left', false)} sx={{ textAlign: 'end' }}>
              <CloseIcon />
            </Box>
          </Box>
          <Accordion
            expanded={true}
            onChange={() => {
              handleChange('panel1');
            }}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography
                sx={{
                  fontWeight: location.pathname.split('/')[1] == 'getstared' ? 600 : 'none'
                }}>
                Get Started
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
              <Typography
                onClick={(event: any) => {
                  toggleDrawer('left', false)(event);
                  navigate(PageRoutes.GetStartedStepOne);
                }}
                variant="body1"
                sx={{
                  ...Style.StepWraper,
                  fontWeight: location.pathname == PageRoutes.GetStartedStepOne ? 600 : 'none'
                }}>
                Step 1 - Authentication
              </Typography>
              <Typography
                onClick={(event: any) => {
                  toggleDrawer('left', false)(event);
                  navigate(PageRoutes.GetStartedStepTwo);
                }}
                variant="body1"
                sx={{
                  ...Style.StepWraper,
                  fontWeight: location.pathname == PageRoutes.GetStartedStepTwo ? 600 : 'none'
                }}>
                Step 2 - Select repository
              </Typography>
              <Typography
                onClick={(event: any) => {
                  toggleDrawer('left', false)(event);
                  navigate(PageRoutes.GetStartedStepThree);
                }}
                variant="body1"
                sx={{
                  ...Style.StepWraper,
                  fontWeight: location.pathname == PageRoutes.GetStartedStepThree ? 600 : 'none'
                }}>
                Step 3 - Configure
              </Typography>
              <Typography
                onClick={(event: any) => {
                  toggleDrawer('left', false)(event);
                  navigate(PageRoutes.GetStartedStepFour);
                }}
                variant="body1"
                sx={{
                  ...Style.StepWraper,
                  fontWeight: location.pathname == PageRoutes.GetStartedStepFour ? 600 : 'none'
                }}>
                Step 4 - Deployment
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </SwipeableDrawer>

      <Box
        sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, position: 'relative' }}>
        <Box
          sx={{
            width: '270px',
            position: 'fixed',
            display: { xxs: 'none', md: 'block' },
            pb: 2,
            top: 155,
            zIndex: 1,
            border: '1px solid #DADADA'
          }}>
          <Box
            sx={{
              maxHeight: '50vh',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}>
            <Box
              onClick={() => navigate(PageRoutes.DocumentationPage)}
              sx={{
                px: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 2,

                cursor: 'pointer'
              }}>
              <Box style={{ height: '2px', width: '12px', backgroundColor: '#DADADA' }}></Box>
              <Typography
                sx={{ fontWeight: location.pathname.includes('documentation') ? 600 : 'none' }}>
                Introduction
              </Typography>
            </Box>
            <Accordion expanded={true} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography
                  sx={{
                    fontWeight: location.pathname.split('/')[1] == 'getstared' ? 600 : 'none'
                  }}>
                  Get Started
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Typography
                  onClick={() => navigate(PageRoutes.GetStartedStepOne)}
                  variant="body1"
                  sx={{
                    ...Style.StepWraper,
                    fontWeight: location.pathname == PageRoutes.GetStartedStepOne ? 600 : 'none'
                  }}>
                  Step 1 - Authentication
                </Typography>
                <Typography
                  onClick={() => navigate(PageRoutes.GetStartedStepTwo)}
                  variant="body1"
                  sx={{
                    ...Style.StepWraper,
                    fontWeight: location.pathname == PageRoutes.GetStartedStepTwo ? 600 : 'none'
                  }}>
                  Step 2 - Select repository
                </Typography>
                <Typography
                  onClick={() => navigate(PageRoutes.GetStartedStepThree)}
                  variant="body1"
                  sx={{
                    ...Style.StepWraper,
                    fontWeight: location.pathname == PageRoutes.GetStartedStepThree ? 600 : 'none'
                  }}>
                  Step 3 - Configure
                </Typography>
                <Typography
                  onClick={() => navigate(PageRoutes.GetStartedStepFour)}
                  variant="body1"
                  sx={{
                    ...Style.StepWraper,
                    fontWeight: location.pathname == PageRoutes.GetStartedStepFour ? 600 : 'none'
                  }}>
                  Step 4 - Deployment
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Link
              to={PageRoutes.DockerFileInstruction}
              style={{ textDecoration: 'none', color: '#6B6B6B' }}>
              <Box
                sx={{
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mt: 2,
                  cursor: 'pointer'
                }}>
                <Box style={{ height: '2px', width: '12px', backgroundColor: '#DADADA' }}></Box>
                <Typography
                  sx={{
                    fontWeight: location.pathname.includes('docker-instruction') ? 600 : 'none'
                  }}>
                  Dockerfile Instruction
                </Typography>
              </Box>
            </Link>
            <Link
              to={PageRoutes.GithubAppInstallation}
              style={{ textDecoration: 'none', color: '#6B6B6B' }}>
              <Box
                sx={{
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mt: 2,
                  cursor: 'pointer'
                }}>
                <Box style={{ height: '2px', width: '12px', backgroundColor: '#DADADA' }}></Box>
                <Typography
                  sx={{
                    fontWeight: location.pathname.includes('github-app-installation') ? 600 : 'none'
                  }}>
                  Github App Installation Guide
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            ml: 2,
            width: { xxs: 1, md: 1 },
            marginLeft: { xxs: 0, md: '290px' }
          }}>
          {props.children}
        </Box>
        <Box></Box>
      </Box>
    </Container>
  );
};
export default DocumentationPage;
