import React from 'react';

import CardsImage from '../../../assets/images/cards.png';
import * as Style from './Dashboard.style';
import { motion, useInView } from 'framer-motion';
import Saturn from '../../../assets/images/saturn.svg';

const Dashboard2 = () => {
  const variants = {
    start: {
      x: -200,
      y: 300,
      transition: { duration: 1, delay: 0.1 }
    },
    stop: { x: 300, y: -200, transition: { duration: 2 } }
  };

  const variantsMobile = {
    start: { scale: 1.5, transition: { duration: 2, delay: 0 } },
    stop: { scale: 1, transition: { duration: 1 } }
  };

  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <Style.SectionWrapper className="bottomDashboard">
      <div className="container" ref={ref}>
        <Style.DashboardMain>
          <div className="bottomSlide desktop">
            <motion.div className="text">
              BnD is a one-stop platform for streamlining your development and deployment process in
              single pane of glass
            </motion.div>
            <motion.div className="image">
              <img src={CardsImage} alt="cards" />
            </motion.div>
          </div>
          <div className="bottomSlide mobile">
            <div className="text">
              BnD is a one-stop platform for streamlining your development and deployment process in
              single pane of glass
            </div>
            <motion.div
              className="image"
              variants={variantsMobile}
              animate={isInView ? 'start' : 'stop'}>
              <img src={CardsImage} alt="cards" />
            </motion.div>
          </div>
        </Style.DashboardMain>
        <motion.div
          className="patterPlanet"
          variants={variants}
          animate={isInView ? 'start' : 'stop'}>
          <img src={Saturn} alt="Saturn" />
        </motion.div>
      </div>
    </Style.SectionWrapper>
  );
};

export default Dashboard2;
