import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { EnvVarsForms } from '..';
import DoNotDisturbOnIcon from '../../../assets/icons/DoNotDisturbOn';

export const EnvironmentVariables: FC<EnvVarsForms> = (props) => {
  return (
    <>
      {/* to disable the whole box className="disable" */}
      <Grid item xs={12} md={4} sx={{ width: 1 }}>
        <TextField
          variant="outlined"
          label="key"
          fullWidth
          autoComplete="off"
          placeholder="Variable Name"
          id="VariableName"
          onChange={(event) => {
            props.onChange('Name', event?.target.value);
          }}
          error={props.Name ? false : true}
          value={props.Name || ''}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'VariableName'
          }}
          helperText={props.Name ? '' : 'This field is required'}
        />
      </Grid>
      <Grid item xs={12} md={8} sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <TextField
            variant="outlined"
            label="Value"
            fullWidth
            id="Value"
            autoComplete="off"
            placeholder="Enter Value for Environment Variable"
            value={props.Value || ''}
            onChange={(event) => {
              props.onChange('Value', event?.target.value);
            }}
            error={props.Value ? false : true}
            helperText={props.Value ? '' : 'This field is required'}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'RootDirectory'
            }}
          />
          <Button
            sx={{
              color: 'common.grey',
              mt: '10px',
              minWidth: 'auto',
              '&:hover': { backgroundColor: 'transparent' }
            }}
            onClick={() => {
              props.onRemove();
            }}>
            <DoNotDisturbOnIcon sx={{ mr: { sm: 1 }, ml: 1 }} />{' '}
            {/* <Box sx={{ display: { xxs: 'none', sm: 'block' } }}></Box> */}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default EnvironmentVariables;
