import { FC } from 'react';
import { TypedUseSelectorHook, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getAuthState, setAuthDetails } from '../../store/reducers/authReducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { notifyError } from '../../services/toster';
import { PageRoutes } from '../../utils/constants';
import Style from './VscodeLogin.styles';
import { Box, Button, Typography } from '@mui/material';

import VscodeAuthImg from '../../assets/images/vscodeauth.jpg';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const VscodeLogin: FC = () => {
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initiateVscodeLogin = () => {
    const env = process.env;
    const state = searchParams.get('state') || '';
    if (/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(state)) {
      dispatch(setAuthDetails({ ...auth, isVscodeLogin: true, state: state }));
      location.href = `${env.REACT_APP_GITHUB_APP_GITHUB_URL}?client_id=${env.REACT_APP_GITHUB_APP_CLIENT_ID}&state=${state}`;
    } else {
      notifyError('Invalid vscode login attempt');
    }
  };

  const handleCancelLogin = () => {
    navigate(PageRoutes.Login);
  };

  return (
    <Box sx={Style.VscodeLoginWrapper}>
      <Box sx={Style.VscodeLoginCard}>
        <img width="80%" src={VscodeAuthImg} alt="auth flow" />
        <Typography sx={Style.VscodeLoginCardText}>
          Jumpstart BnD needs access to your Buildndeploy account.
        </Typography>
        <Box sx={Style.VscodeLoginCardBtnContainer}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              minWidth: '100px',
              border: '1px solid #8f5de5 !important',
              borderColor: 'action.focus',
              color: '#fff',
              fontSize: '16px'
            }}
            onClick={() => {
              initiateVscodeLogin();
            }}>
            Yes, authenticate!
          </Button>
          <Button
            onClick={() => {
              handleCancelLogin();
            }}
            sx={{
              minWidth: '100px',
              fontSize: '16px',
              background: '#ffffff',
              boxShadow: '0px 10px 40px rgb(255 255 255 / 12%)'
            }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VscodeLogin;
