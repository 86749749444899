const Style = {
  NoDataFoundWrapper: {
    width: 1,
    px: { xxs: 3, sm: 5, lg: 7 },
    py: { xxs: 2, sm: 3, lg: 4 },
    bgcolor: 'grey.200',
    borderRadius: 5,
    textAlign: 'center',
    mt: 1
  }
};

export default Style;
