import { FormControl, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import SettingsIcon from '../../assets/icons/Settings';
import Loader from '../../components/shared/Loader';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import {
  BuildServiceBranchConfigRequest,
  DeployServiceBranchConfigRequest,
  DetectResponse,
  EnvVars,
  PortConfig
} from '../../models';
import { BuildService } from '../../services/API/BuildService';
import { DeployService } from '../../services/API/DeployService';
import { DetectService } from '../../services/API/DetectService';
import { ProjectService } from '../../services/API/ProjectService';
import { notifyError } from '../../services/toster';
import {
  getConfigureProjectState,
  setRepositoryDetect
} from '../../store/reducers/configureProjectReducer';
import { RootState } from '../../store/store';
import { PageRoutes } from '../../utils/constants';
import Style from './ConfigureProject.style';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const ConfigureProject: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('configure-project');
  const repositoryDetails = useTypedSelector(getConfigureProjectState, shallowEqual);
  const projectAPIService = new ProjectService();
  const detectAPIService = new DetectService();

  const buildAPIService = new BuildService();
  const deployAPIService = new DeployService();

  const [values, setValues] = useState({
    projectName: '',
    errorProjectName: '',
    source: ''
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleConfigureClick = async () => {
    // Check for project name
    if (values.errorProjectName) {
      setIsShow(false);
      notifyError(values.errorProjectName);
      return;
    }
    gaEventTracker('creating_new_project');

    setIsShow(true);
    const response = await projectAPIService.CreateProject({
      Source: values.source || '',
      Name: values.projectName
    });

    if (response?.ID) {
      const result = await getDetection(response.Name);
      let buildRequest = handleSetBuildRequest(result);
      let deployRequest = handleSetDeployRequest(result);

      try {
        await buildAPIService.CreateBranchConfig(buildRequest, searchParams.get('alias'));
        await deployAPIService.CreateBranchConfig(deployRequest, searchParams.get('alias'));
        setTimeout(() => {
          setIsShow(false);
          navigate({
            pathname: PageRoutes.DetectingLogs.replace(':id', response.Name),
            search: createSearchParams({ branch: buildRequest.Branch, isNew: 'true' }).toString()
          });
        }, 500);
      } catch (error) {
        notifyError('Error while creating configuration');
        navigate(PageRoutes.Repository);
      }
    } else {
      setIsShow(false);
    }
  };

  function handleSetDeployRequest(data: DetectResponse) {
    let deployRequest = {} as DeployServiceBranchConfigRequest;
    deployRequest.Branch = repositoryDetails.repository.default_branch;
    deployRequest.Project = values.projectName;

    if (data?.env) {
      let envs: EnvVars[] = [];
      Object.keys(data.env).forEach((key) => {
        envs.push({
          Key: key,
          Value: data.env[key]
        });
      });
      deployRequest.Env = envs;
    }
    if (data?.ports) {
      let ports: PortConfig[] = [];
      Object.keys(data.ports).forEach((key) => {
        ports.push({
          Port: parseInt(key),
          Protocol: data.ports[key],
          HealthCheck: '/'
        });
      });
      deployRequest.Ports = ports;
    }
    return deployRequest;
  }

  function handleSetBuildRequest(data: DetectResponse) {
    let buildRequest = {} as BuildServiceBranchConfigRequest;
    buildRequest.Branch = repositoryDetails.repository.default_branch;
    buildRequest.Project = values.projectName;
    buildRequest.Context = data.build?.context;
    buildRequest.Dockerfile = data.build?.instructions_file;
    buildRequest.Language = data.language?.toLocaleLowerCase();
    buildRequest.IsAutoDeploy = true;

    return buildRequest;
  }

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop == 'projectName') {
      if (event.target.value) {
        setValues((prevState) => ({ ...prevState, errorProjectName: '' }));
        let projectNameregex = new RegExp(event.target.attributes['pattern'].value);
        if (projectNameregex.test(event.target.value)) {
          setValues((prevState) => ({ ...prevState, errorProjectName: '' }));
        } else {
          setValues((prevState) => ({
            ...prevState,
            errorProjectName: 'Project name in invalid'
          }));
        }
      } else {
        setValues((prevState) => ({ ...prevState, errorProjectName: 'Project name is required' }));
      }
    }
  };

  async function getDetection(projectName: string) {
    let response = await detectAPIService.DetectByBranch(
      projectName,
      repositoryDetails.repository.default_branch,
      searchParams.get('alias')
    );
    dispatch(setRepositoryDetect(response));
    return response;
  }

  useEffect(() => {
    let rawUrl = repositoryDetails.repository.html_url?.replace(/(^\w+:|^)\/\//, '');
    if (!(repositoryDetails.repository && repositoryDetails.repository.name && rawUrl)) {
      navigate(PageRoutes.Repository);
    } else {
      setValues({
        projectName: repositoryDetails.repository.name,
        errorProjectName: '',
        source: rawUrl
      });
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box sx={Style.LogsCard}>
        {!isShow && (
          <Box sx={Style.LogsCardHead}>
            <Box sx={Style.LogsCardTitle}>
              <SettingsIcon color="secondary" />
              <Typography variant="h2" sx={Style.MainHeading}>
                Configure Project
              </Typography>
            </Box>
          </Box>
        )}

        <Loader isShow={isShow} />
        {!isShow && (
          <FormControl fullWidth>
            <Box sx={Style.SetupCard}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ width: 1 }}>
                  <TextField
                    label="Project Name"
                    variant="outlined"
                    error={values.errorProjectName ? true : false}
                    helperText={values.errorProjectName}
                    fullWidth
                    placeholder="Project Name"
                    id="ProjectName"
                    value={values.projectName}
                    onChange={handleChange('projectName')}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'ProjectName',
                      pattern: '^[a-zA-Z0-9_-]+$'
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ width: 1 }}>
                  <TextField
                    label="Source"
                    variant="outlined"
                    fullWidth
                    disabled
                    id="Source"
                    placeholder="https://github.com/pacenthink/bnd-ui.git"
                    value={values.source}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'RootDirectory'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ width: 1, textAlign: 'center' }}>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    size="large"
                    sx={{ mr: { xxs: 1, sm: 2 } }}>
                    Back
                  </Button>
                  <Button
                    onClick={handleConfigureClick}
                    variant="contained"
                    color="secondary"
                    size="large">
                    Configure
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        )}
      </Box>
    </Container>
  );
};
