import React from 'react';
import DashboardImage from '../../../assets/images/Dashboard.png';
import bluePlanet from '../../../assets/images/planet-blue.svg';
import * as Style from './Dashboard.style';
import { motion, useInView } from 'framer-motion';
import { useMediaQuery } from '@mui/material';
import pinkPlanet from '../../../assets/images/planet-pink.svg';

const Dashboard = ({ isActive }: { isActive?: boolean }) => {
  const isBigTooScreen = useMediaQuery('(min-height: 1200px)');

  const variants = {
    start: {
      opacity: 0.45,
      y: isBigTooScreen ? 0 : 0,
      transition: { duration: 1, delay: 1 }
    },
    stop: {
      opacity: 1,
      y: isBigTooScreen ? 0 : 0,
      transition: { duration: 2 }
    }
  };
  const variants2 = {
    start: {
      opacity: 0.45,
      y: isBigTooScreen ? 0 : 0,
      transition: { duration: 1, delay: 1 }
    },
    stop: {
      opacity: 1,
      y: isBigTooScreen ? 0 : 0,
      transition: { duration: 2 }
    }
  };
  const variantsMobile = {
    start: { scale: 1.3, transition: { duration: 2, delay: 0 } },
    stop: { scale: 1, transition: { duration: 1 } }
  };

  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 'some' });

  return (
    <Style.SectionWrapper>
      <Style.DashboardHandler ref={ref} />
      <div className="container">
        <Style.DashboardMain>
          <div className="topSlide desktop">
            <motion.div className="text" variants={variants2} animate={isActive ? 'start' : 'stop'}>
              Say goodbye to the frustration of manual deployment with BnD, you can focus on what
              you do best
            </motion.div>
            <motion.div className="image" variants={variants} animate={isActive ? 'start' : 'stop'}>
              <img src={DashboardImage} alt="dashboard" />
            </motion.div>
          </div>
          <div className="topSlide mobile">
            <div className="text">
              Say goodbye to the frustration of manual deployment with BnD, you can focus on what
              you do best
            </div>
            <motion.div
              className="image"
              variants={variantsMobile}
              animate={isInView ? 'start' : 'stop'}>
              <img src={DashboardImage} alt="dashboard" />
            </motion.div>
          </div>
        </Style.DashboardMain>
      </div>
      <div className="planetBlue">
        <img src={bluePlanet} alt="planet" />
      </div>
      <div className="planetPink">
        <img src={pinkPlanet} alt="planet" />
      </div>
    </Style.SectionWrapper>
  );
};

export default Dashboard;
