const CircleTwoLayer = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.666748" width="40" height="40" rx="20" fill="#664CCD" fillOpacity="0.1" />
      <path
        d="M12.6667 22.0833L21.0001 26.25L29.3334 22.0833M21.0001 13.75L12.6667 17.9167L21.0001 22.0833L29.3334 17.9167L21.0001 13.75Z"
        stroke="#664CCD"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.16675"
        y="1.5"
        width="37"
        height="37"
        rx="18.5"
        stroke="#664CCD"
        strokeOpacity="0.1"
        strokeWidth="3"
      />
    </svg>
  );
};

export default CircleTwoLayer;
