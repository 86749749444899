import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

import ConfigProjectName from '../../../assets/images/ConfigProjectName.png';

import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll';
import DetectionPage from '../../../assets/images/DetectionPage.png';
import SelectLangPage from '../../../assets/images/SelectLangPage.png';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';
const GetStartedStepThree: FC = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between', position: 'relative' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Configure
        </Typography>

        <Box>
          <Typography> </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'ConfigureProject'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Configure project
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Create the new project with any name you like.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={ConfigProjectName} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Configure Project Page
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'Configuration'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Configuration
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Once you click on the configure project, BnD automatically detects the code the
            repository language, environment variables, and ports used in the code. Of course, you
            can override them in later steps.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={DetectionPage} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Project Configuration Page
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'SelectLanguage'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Configure environment variables and port
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            You can select the language and add/remove env variables and ports if not detected
            automatically in the previous step and click on save.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={SelectLangPage} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Project Configuration Page
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography className="upnext" sx={{ fontSize: '24px', fontWeight: 600, color: 'black' }}>
            Up next
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {' '}
            Continue getting started with Bnd:
          </Typography>
        </Box>
        <Box
          onClick={() => navigate(PageRoutes.GetStartedStepFour)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Deployment
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>We will deploy the first build</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={Style.OnthisPage}>
          <Typography sx={{ mb: 1.5 }}>On this page</Typography>
          <Link activeClass="active" to="ConfigureProject" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Configure Project
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="Configuration" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Configuration
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="SelectLanguage" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Select Language
              </Typography>
            </Box>
          </Link>

          <Link activeClass="active" to="upnext" spy={true} smooth={true} offset={-200}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, cursor: 'pointer' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Up next
              </Typography>
            </Box>
          </Link>
          <Box sx={{ mt: 2, cursor: 'pointer' }}>
            <Typography onClick={scrollToTop}>Back to top</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GetStartedStepThree;
