const Style = {
  BuildNewProjectWrapper: {
    px: { xxs: 3, sx: 7 },
    mt: 5
  },
  ProjectList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  ProjectListImage: {
    width: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
export default Style;
