import { motion, useInView } from 'framer-motion';
import React from 'react';
import ProcessImage from '../../../assets/images/process.svg';
import yellowPlanet from '../../../assets/images/yellow-planet.svg';
import * as Style from './Process.style';

const Process = () => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 'some' });

  const variants = {
    start: { scale: 1, transition: { duration: 1, delay: 0 } },
    stop: { scale: 0.9, transition: { duration: 1 } }
  };

  return (
    <Style.SectionWrapper>
      <Style.ProcessMain>
        <div className="container">
          <div className="sectionContent" ref={ref}>
            <motion.div className="image" variants={variants} animate={isInView ? 'start' : 'stop'}>
              <img src={ProcessImage} alt="process" />
            </motion.div>
            <motion.div
              className="content"
              variants={variants}
              animate={isInView ? 'start' : 'stop'}>
              <div className="title">
                Revolutionize Your Development Process with <span> BnD</span>{' '}
              </div>
            </motion.div>
          </div>
        </div>
      </Style.ProcessMain>
      <div className="yellowPlanet">
        <img src={yellowPlanet} alt="planet" />
      </div>
    </Style.SectionWrapper>
  );
};

export default Process;
