import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll';
import AddNewBranchDoc from '../../../assets/images/AddNewBranchDoc.png';
import DashBoardDoc from '../../../assets/images/DashBoardDoc.png';
import RepositoryPageDoc from '../../../assets/images/RepositoryPageDoc.png';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';
const GetStartedStepTwo: FC = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Select Repository
        </Typography>

        <Box>
          <Typography>
            You need to select an existing project repository from linked Git provider
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'dashboard'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Dashboard
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Once you onboard in the platform you can click on Add Project to create new project
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={DashBoardDoc} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Dashboard Page
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            className={'github'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Select the GitHub account
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            You can select the GitHub account / Organisation or you can add new Github account using
            “Add Github Account” option
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={AddNewBranchDoc} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Select Repository Page
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            className={'repository'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Select Repository
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Select the appropriate repository you want to deploy.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={RepositoryPageDoc} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Select Repository Page
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography className="upnext" sx={{ fontSize: '24px', fontWeight: 600, color: 'black' }}>
            Up next
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {' '}
            Continue getting started with Bnd:
          </Typography>
        </Box>
        <Box
          onClick={() => navigate(PageRoutes.GetStartedStepThree)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Configure
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>
            configure your project and give any unique name
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={Style.OnthisPage}>
          <Typography sx={{ mb: 1.5 }}>On this page</Typography>
          <Link activeClass="active" to="dashboard" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Dashboard
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="github" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Select Github Account
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="repository" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Select Repository
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="upnext" spy={true} smooth={true} offset={-200}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, cursor: 'pointer' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Up next
              </Typography>
            </Box>
          </Link>
          <Box sx={{ mt: 2, cursor: 'pointer' }}>
            <Typography onClick={scrollToTop}>Back to top</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GetStartedStepTwo;
