export default function AddIconLarge(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 16H24"
        stroke="url(#paint0_linear_4184_350)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 24V8"
        stroke="url(#paint1_linear_4184_350)"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4184_350"
          x1="8"
          y1="16.5"
          x2="24"
          y2="16.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4184_350"
          x1="16"
          y1="16"
          x2="17"
          y2="16"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
