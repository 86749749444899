import JavaScript from '../assets/icons/JavaScript';
import PythonIcon from '../assets/icons/Python';
import TypeScriptLogo from '../assets/icons/TypeScript';
import Golang from '../assets/icons/Golang';
import Cplusplus from '../assets/icons/Cplusplus';
import Csharp from '../assets/icons/cSharp';
import Rust from '../assets/icons/Rust';
import JavaLogo from '../assets/icons/Java';
import InitialLogo from '../assets/icons/InitialLogo';
import ReactJsIcon from '../assets/icons/ReactJsIcon';
import AngularIcon from '../assets/icons/AngularIcon';
import NextJsIcon from '../assets/icons/NextJsIcon';
import NodeJsIcon from '../assets/icons/NodeJsIcon';

export const language = [
  {
    languageName: 'JavaScript',
    logo: <JavaScript />
  },

  {
    languageName: 'Python',
    logo: <PythonIcon />
  },
  {
    languageName: 'TypeScript',
    logo: <TypeScriptLogo />
  },
  {
    languageName: 'Java',
    logo: <JavaLogo />
  },
  {
    languageName: 'C++',
    logo: <Cplusplus />
  },
  {
    languageName: 'C#',
    logo: <Csharp />
  },
  {
    languageName: 'Rust',
    logo: <Rust />
  },
  {
    languageName: 'Go',
    logo: <Golang />
  },
  {
    languageName: 'Other',
    logo: <InitialLogo />
  }
];
export const frameWork = [
  {
    languageName: 'Go',
    logo: <Golang />,
    value: 'GoSkeleton'
  },
  {
    languageName: 'React(TS)',
    logo: <ReactJsIcon />,
    value: 'ReactSkeleton'
  },
  {
    languageName: 'Angular(TS)',
    logo: <AngularIcon />,
    value: 'AngularSkeleton'
  },
  {
    languageName: 'Nextjs(TS)',
    logo: <NextJsIcon />,
    value: 'NextSkeleton'
  },
  {
    languageName: 'Nodejs(JS)',
    logo: <NodeJsIcon />,
    value: 'NodeSkeleton'
  }
];
