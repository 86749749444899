import MergeTypeIcon from '@mui/icons-material/MergeType';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Pagination,
  Skeleton,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { FC, SyntheticEvent, useRef, useState } from 'react';
import { LazyLog } from 'react-lazylog';
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import LoadingGif from '../../../../assets/images/loading-gif.gif';
import Profile from '../../../../assets/images/profile.png';
import NoDataFound from '../../../../components/shared/NoDataFound';
import { BuildResponse, PaginationHelper } from '../../../../models';
import { getAuthState } from '../../../../store/reducers/authReducer';
import { RootState } from '../../../../store/store';
import { GetRefiendEndpoint } from '../../../../utils/commonfunction';
import { APIRoutes } from '../../../../utils/constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Style from './History.style';
import { notifySuccess } from '../../../../services/toster';
import useAnalyticsEventTracker from '../../../../hooks/useAnalyticsEventTracker';
import { EllipsisContent } from '../../../../components/shared/EllipsisContent';
import { useSearchParams } from 'react-router-dom';
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const History: FC<{
  items: BuildResponse[];
  onPageChange(pagination: PaginationHelper): void;
  pagination: PaginationHelper;
}> = (props) => {
  const gaEventTracker = useAnalyticsEventTracker('build_history');
  const { items } = props;

  const [isSkeleton, setIsSkeleton] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [counter, setCounter] = useState(0);
  const [currentBuildId, setCurrentBuildId] = useState<string>('');
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const [logs, setLogs] = useState<string>('');
  const refLogs = useRef('');
  const env = process.env;
  const ref = useRef<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  function getCurrentAuth() {
    var auth = JSON.parse(localStorage.getItem('auth') || '');
    return auth;
  }

  const handleChangeAccordion =
    (panel: string, Element: any) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        fetchBuildLogs(Element.Project, Element.Branch, Element?.ID);
      }

      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (e: any, p: any) => {
    let item = props.pagination;
    item.currentPage = p;
    props.onPageChange(props.pagination);
  };

  //#endregion

  //#region GetStreamBuildLogs

  const fetchBuildLogs = async (projectName: string, branchName: string, buildId: string) => {
    if (logs && buildId == currentBuildId) {
      return;
    }
    setCurrentBuildId(buildId);
    gaEventTracker('application_logs_clicked');
    setIsSkeleton(true);

    var respo = await fetch(
      `${env.REACT_APP_BUILD_API_BASE_URL}` +
        GetRefiendEndpoint(APIRoutes.GetBuildStreamLogs, [
          searchParams.get('alias') != null
            ? searchParams.get('alias') || ''
            : auth.user_details.Alias,
          projectName,
          branchName,
          buildId
        ]) +
        '?follow=true',
      {
        method: 'GET',
        headers: {
          Authorization: `bearer ${getCurrentAuth().access_token}`
        }
      }
    ).finally(() => {
      setIsSkeleton(false);
    });
    const reader = respo.body?.getReader();
    if (typeof reader != 'undefined') {
      for (;;) {
        const { value, done } = await reader.read();

        if (done) {
          break;
        }

        var t1 = new TextDecoder().decode(value);
        const item = refLogs.current + t1;
        refLogs.current = item;
        setLogs(item);
      }
    }
  };
  //#endregion

  //#region  Counter
  function timeFormat(count: number): any {
    count = moment(props.items[0].CreatedAt).diff(moment().toLocaleString(), 'seconds') * -1;
    startCount();
    if (count < 60) {
      return `${count} s`;
    } else if (count > 60) {
      return `${Math.floor(count / 60)} m ${count % 60} s`;
    }
  }
  const startCount = () => {
    if (ref.current !== null) return;

    ref.current = setInterval(() => {
      setCounter((pre) => pre + 1);
    }, 1000);
  };
  //#endregion

  function secondsToHms(d: number) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' h, ' : ' h, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' m, ' : ' m, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';
    return hDisplay + mDisplay + sDisplay;
  }

  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        <Box>
          <Box>
            {items.map((Element: any, index: any) => (
              <Box key={index + 1} sx={{ mb: 2 }}>
                <Accordion
                  sx={Style.AccordionWrapper}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChangeAccordion(`panel${index + 1}`, Element)}>
                  <AccordionSummary
                    sx={{
                      '.MuiAccordionSummary-content': { m: 0 }
                    }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Box sx={{ width: 1 }}>
                      <Box sx={{ p: 0 }}>
                        <Box sx={Style.HistoryBox}>
                          <Box sx={Style.HistoryBoxStart}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: { xxs: 'column', sm: 'row' },
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1,
                                width: { xxs: '100%', md: '38%' }
                              }}>
                              <EllipsisContent
                                sx={{
                                  color: '#1E1E1E',
                                  fontWeight: '500',
                                  width: 'max-content',
                                  maxWidth: {
                                    xxs: 280,
                                    sm: 500,
                                    md: 335
                                  }
                                }}
                                variant="body1"
                                text={Element.JobID}
                              />
                              <Box
                                sx={{
                                  display: { xxs: 'flex', sm: 'none' },
                                  justifyContent: 'flex-start',
                                  width: { xxs: '100px', md: '12%' }
                                }}>
                                {Element.Status != 'failed' ? (
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      backgroundColor: 'rgba(0, 166, 117, 0.1)',
                                      borderRadius: 6,
                                      width: 1,
                                      py: 1,
                                      textAlign: 'center',
                                      px: 2,
                                      color: '#00A675',
                                      fontSize: 14
                                    }}>
                                    {Element.Status}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      backgroundColor: 'rgba(232, 49, 49, 0.1)',
                                      borderRadius: 6,
                                      width: 1,
                                      py: 1,
                                      px: 2,
                                      color: '#E83131',
                                      textAlign: 'center',
                                      fontSize: 14
                                    }}
                                    variant="body1">
                                    {Element.Status}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: { xxs: 'none', sm: 'flex' },
                                justifyContent: 'flex-start',
                                width: { xxs: '100px', md: '12%' }
                              }}>
                              {Element.Status != 'failed' ? (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    backgroundColor: 'rgba(0, 166, 117, 0.1)',
                                    borderRadius: 6,
                                    width: 1,
                                    py: 1,
                                    textAlign: 'center',
                                    px: 2,
                                    color: '#00A675',
                                    fontSize: 14
                                  }}>
                                  {Element.Status}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    backgroundColor: 'rgba(232, 49, 49, 0.1)',
                                    borderRadius: 6,
                                    width: 1,
                                    py: 1,
                                    px: 2,
                                    color: '#E83131',
                                    textAlign: 'center',
                                    fontSize: 14
                                  }}
                                  variant="body1">
                                  {Element.Status}
                                </Typography>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start'
                              }}>
                              {Element?.Description && (
                                <EllipsisContent
                                  sx={{
                                    fontSize: 14,
                                    width: 'max-content',
                                    maxWidth: {
                                      xxs: 280,
                                      sm: 500,
                                      md: 200
                                    }
                                  }}
                                  text={Element.Description}
                                />
                              )}
                            </Box>
                          </Box>

                          <Box sx={Style.HistoryBoxEnd} gap={2}>
                            <Box>
                              {Element.Status == 'running' ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      mr: 1,
                                      lineHeight: '21px'
                                    }}>
                                    {timeFormat(counter)}
                                  </Typography>
                                  <img width={'30px'} src={LoadingGif} />
                                </Box>
                              ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography sx={{ fontSize: '14px', mr: 1, lineHeight: '21px' }}>
                                    {moment(Element.CreatedAt).fromNow()}
                                  </Typography>
                                  {Element?.UpdatedAt && Element?.CreatedAt && (
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        mr: 1,
                                        lineHeight: '21px'
                                      }}>
                                      {secondsToHms(
                                        moment(Element?.UpdatedAt).diff(
                                          moment(Element?.CreatedAt),
                                          'seconds'
                                        )
                                      )}
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Box>

                            <Box>
                              <Tooltip
                                arrow
                                title={Element?.Author ? Element?.Author : ''}
                                placement={'top'}>
                                <Button id="menu-button" sx={Style.UserImage}>
                                  {Element?.AuthorAvatarURL ? (
                                    <img src={Element?.AuthorAvatarURL} alt="profile" />
                                  ) : (
                                    <img src={Profile} alt="profile" />
                                  )}
                                </Button>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={Style.AccordionDetails}>
                    <Box sx={{ px: 1 }}>
                      {logs && !isSkeleton ? (
                        <LazyLog follow={true} text={logs || ''} height={400}></LazyLog>
                      ) : isSkeleton ? (
                        <Skeleton animation="wave" sx={{ my: 0, minHeight: 200 }} />
                      ) : (
                        'No build log found'
                      )}
                    </Box>
                    {logs && !isSkeleton && (
                      <Box
                        onClick={() => {
                          navigator.clipboard.writeText(logs);
                          notifySuccess('Copied');
                        }}
                        sx={{
                          position: 'absolute',
                          cursor: 'pointer',
                          top: 30,
                          right: 40,
                          backgroundColor: '#000000',
                          borderRadius: '8px',
                          border: '1px solid grey',
                          py: 1,
                          px: 1.5
                        }}>
                        <ContentCopyIcon />
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
          {props.items?.length === 0 && <NoDataFound />}
        </Box>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', pt: 2 }}>
          <Pagination
            count={props.pagination.totalPages}
            size="large"
            page={props.pagination.currentPage}
            shape="rounded"
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Box>
  );
};
