import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { DomainForms } from '..';
import DoNotDisturbOnIcon from '../../../assets/icons/DoNotDisturbOn';
import InfoCircleIcon from '../../../assets/icons/InfoCircleIcon';
import VerifiedIcon from '@mui/icons-material/Verified';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { DeployService } from '../../../services/API/DeployService';
import crossImage from '../../../assets/images/crossImage.svg';

import tickCircleImage from '../../../assets/images/tickCircleImage.svg';
import { CopyAll, CopyAllTwoTone } from '@mui/icons-material';
import CopyIcon from '../../../assets/icons/CopyIcon';
import { notifySuccess } from '../../../services/toster';
import { DnsConfig, DnsTableConfig } from '../../../models';
import Scroll from 'react-scroll';

export const CostomDomain: FC<DomainForms> = (props) => {
  const [errorDomain, setErrorDomain] = useState(false);
  const [dnsConfig, setDnsConfig] = useState<DnsConfig>({} as DnsConfig);
  const [dnsTableConfig, setDnsTableConfig] = useState<DnsTableConfig[]>([]);

  const deployAPIService = new DeployService();

  function handleFindDomainValue() {
    let domain = props.Domain?.split('.');
    if (domain?.length > 2) {
      setDnsTableConfig([
        {
          Type: 'CNAME',
          Name: domain[0],
          Value: dnsConfig.CNAME
        },
        {
          Type: 'CNAME',
          Name: 'www.' + domain[0],
          Value: props.Domain
        }
      ]);
    } else if (domain?.length == 2) {
      setDnsTableConfig([
        {
          Type: 'A',
          Name: '@',
          Value: dnsConfig.IP
        },
        {
          Type: 'CNAME',
          Name: 'www',
          Value: dnsConfig.CNAME
        }
      ]);
    } else {
      setDnsTableConfig([]);
    }
  }

  async function GetDNSConfiugration() {
    var result = await deployAPIService.GetDNSConfig();
    setDnsConfig(result);
  }

  useEffect(() => {
    GetDNSConfiugration();
  }, []);

  useEffect(() => {
    handleFindDomainValue();
  }, [dnsConfig, props.Domain]);

  var scroller = Scroll.scroller;
  var Element = Scroll.Element;

  const handleScroll = () => {
    scroller.scrollTo('myScrollToElement', {
      containerId: 'domain-table-holder',
      duration: '300',
      offset: -350,
      smooth: true,
      delay: 100
    });
  };

  return (
    <>
      {/* to disable the whole box className="disable" */}
      <Grid item xs={12} md={6} sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <TextField
            variant="outlined"
            label="Domain"
            fullWidth
            autoComplete="off"
            placeholder="Domain Name"
            id="Domain Name"
            error={!props.Domain || errorDomain ? true : false}
            helperText={
              (!props.Domain && 'This field is required') || (errorDomain && 'Domain is invalid')
            }
            onChange={(event) => {
              setTimeout(() => {
                handleScroll();
              }, 1);
              props.onChange('Domain', event?.target.value);
              if (
                event.target.value.match(
                  /^(?!www\.)(?!ww\.)((?!-)(?!.*--)[a-zA-Z0-9-]{1,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}$/g
                )
              ) {
                setErrorDomain(false);
              } else {
                setErrorDomain(true);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!errorDomain && (
                    <IconButton edge="end" color="primary">
                      {props.IsVerified ? (
                        <Tooltip title="Custom Domain Verified">
                          <img src={tickCircleImage} alt="" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Custom Domain Not Verified">
                          <img src={crossImage} alt="" />
                        </Tooltip>
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            value={props.Domain || ''}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'Domain Name'
            }}
          />

          <Button
            sx={{
              color: 'common.grey',
              mt: '10px',
              minWidth: 'auto',
              '&:hover': { backgroundColor: 'transparent' }
            }}
            onClick={() => {
              props.onRemove();
            }}>
            <DoNotDisturbOnIcon sx={{ ml: 1 }} />{' '}
          </Button>
        </Box>
      </Grid>
      {!errorDomain && props.Domain && (
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ fontWeight: 500, color: '#1E1E1E' }}>
            Set the following record on your DNS provider to continue{' '}
          </Typography>
          <TableContainer
            sx={{ mt: 2, boxShadow: 'none', border: '1px solid #E1E2E6' }}
            component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: '#FCFDFF' }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {dnsTableConfig.map((element, index) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={index + 'dns'}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" sx={{ color: '#1E1E1E' }}>
                          {element.Type}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1" sx={{ color: '#1E1E1E' }}>
                          {element.Name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        align="left">
                        <Typography id={'text' + index} variant="body1" sx={{ color: '#1E1E1E' }}>
                          {element.Value}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            let text: any = document.getElementById('text' + index);
                            navigator.clipboard.writeText(text?.innerHTML);
                            notifySuccess('Value Copied Successfully');
                          }}>
                          <CopyIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>

          <Element name="myScrollToElement"></Element>
          <Typography id="domain-table-holder1" variant="body1" sx={{ fontStyle: 'italic', my: 3 }}>
            Instruction: After adding custom domain you have to save the configuration and once you
            make the above changes in your DNS you have to refresh the status of custom domain by
            clicking above icon that will re-validate the domain status and re-build the application
            with custom domain with SSL.
          </Typography>
          <Typography variant="body1" sx={{ fontStyle: 'italic', my: 3 }}>
            Note: It may take up to 6-8 hours for the SSL certificate to be generated, depending on
            the domain provider.
          </Typography>
        </Grid>
      )}
    </>
  );
};
