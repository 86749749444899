import SvgIcon from '@mui/material/SvgIcon';

export default function ExportIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.3333 14.6666L28.2666 3.73328"
        stroke="#664CCD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3333 9.06663V2.66663H22.9333"
        stroke="#664CCD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 2.66663H12C5.33335 2.66663 2.66669 5.33329 2.66669 12V20C2.66669 26.6666 5.33335 29.3333 12 29.3333H20C26.6667 29.3333 29.3334 26.6666 29.3334 20V17.3333"
        stroke="#664CCD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
