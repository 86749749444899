import styled from '@emotion/styled';

const SectionWrapper = styled.div`
  max-height: 1024px;
  height: 100vh;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
  }
`;
const ShopMain = styled.div`
  width: 100%;
  margin: auto;
  .sectionContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    .content {
      width: 50%;
      padding-right: 45px;
      @media only screen and (max-width: 991px) {
        padding: 0 0 30px 0;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 0 0 20px 0;
      }
    }
    .image {
      width: 50%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      @media only screen and (max-height: 899px) {
        img {
          width: 90%;
        }
      }
      @media (max-width: 767px) and (max-height: 899px) {
        img {
          width: 100%;
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 24px;
      @media only screen and (max-width: 820px) {
        font-size: 28px;
        line-height: 38px;
      }
      span {
        background: linear-gradient(90deg, #ff802b 0%, #dd6311 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    p {
      font-size: 18px;
      line-height: 27px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .patterPlanet {
      position: absolute;
      z-index: -1;
      left: -3%;
      top: -5%;
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    .robot {
      position: absolute;
      top: -35%;
      left: 40%;
      z-index: -1;
      opacity: 0.4;
      transition: all 1s;
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    .whitePlanet {
      position: absolute;
      z-index: -1;
      bottom: -10%;
      left: 20%;
      transition: ease all 2s;
      @media only screen and (max-width: 991px) {
        bottom: -20%;
      }
    }
  }
`;

export { SectionWrapper, ShopMain };
