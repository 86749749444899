import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DetectResponse } from '../../models';
import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { APIRoutes } from '../../utils/constants';
import { DetectClient } from '../axios';
import { notifyError } from '../toster';
import HttpService from '../Https-services';
import axios from 'axios';

const env = process.env;
const baseURL = env.REACT_APP_DETECT_API_BASE_URL || '';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export class DetectService {
  auth = useTypedSelector(getAuthState, shallowEqual);

  async GetHealthCheck() {
    var status = false;
    try {
      var result = await axios.get(baseURL + APIRoutes.HealthCheck);

      if (result.status >= 200 && result.status < 300) {
        status = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return status;
  }

  async PreDetectByBranch(
    projectName: string,
    branchName: string,
    source: string,
    alias: string | null
  ) {
    if (!alias) {
      alias = this.auth.user_details.Alias;
    }
    let endpoint: string = `/${alias}/${projectName}/${branchName}/pre-detect?source=${source}`;

    let resp = {} as DetectResponse;
    try {
      const response = await HttpService.get(baseURL, endpoint);

      if (response.status >= 200 && response.status < 300 && response.data) {
        resp = response.data;
      }
    } catch (e: any) {
      // TODO: Deal with gracefully
      notifyError(e.response?.data?.error);
    }
    return resp;
  }

  async DetectByBranch(projectName: string, branchName: string, alias: string | null) {
    if (!alias) {
      alias = this.auth.user_details.Alias;
    }
    let endpoint: string = `/${alias}/${projectName}/${branchName}/detect`;

    let resp = {} as DetectResponse;
    try {
      const response = await HttpService.get(baseURL, endpoint);

      if (response.status >= 200 && response.status < 300 && response.data) {
        resp = response.data;
      }
    } catch (e: any) {
      // TODO: Deal with gracefully
      notifyError(e.response?.data?.error);
    }
    return resp;
  }
}
