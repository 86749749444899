const Style = {
  SelectProjectTypeWraper: {
    width: 1,
    maxWidth: 800,
    m: 'auto'
  },
  SelectProjectType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 1,
    mt: 6,
    mb: 10
  },
  BlankProject: {
    background: '#FCFDFF',
    py: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px dashed #DADADA',
    borderRadius: '8px'
  },
  ImportRepo: {
    background: '#FCFDFF',
    py: 4,
    display: 'flex',
    bgcolor: '#F9F5FF',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
    cursor: 'pointer',

    borderRadius: '7px'
  }
};
export default Style;
