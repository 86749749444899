import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextareaAutosize,
  TextField,
  Typography
} from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import headerCrossCircle from '../../../assets/images/headerCrossCircle.svg';

import headerTickCircle from '../../../assets/images/headerTickCircle.svg';
import tickCircleImage from '../../assets/images/tickCircleImage.svg';
import CrossCancle from '../../../assets/icons/CrossCancle/CrossCancle';
import DownloadIcon from '../../../assets/icons/DownloadIcon/DownloadIcon';
import ExportIcon from '../../../assets/icons/Export';
import FeatureIcon from '../../../assets/icons/FeatureIcon/FeatureIcon';
import FileIcon from '../../../assets/icons/FileIcon/FileIcon';
import GallaryIcon from '../../../assets/icons/GallaryIcon/GallaryIcon';
import IssueIcon from '../../../assets/icons/IssueIcon/IssueIcon';

import { PageRoutes } from '../../../utils/constants';
import { EllipsisContent } from '../EllipsisContent';
import Style from './Footer.styles';

export const Footer: FC = () => {
  const [openDialog, setOpen] = useState(false);
  const [error, setError] = useState(true);
  const [openThanksDialog, setOpenThanksDialog] = useState(false);
  const [activeBug, setActiveBug] = useState({ issue: false, feature: false });
  const location = useLocation();
  const [fileData, setFileData] = useState([] as any);

  const [isUploade, setIsUpload] = useState(false);

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const navigate = useNavigate();
  function niceBytes(x: string) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const handleRemoveFile = (i: any) => {
    const upadatedFileData = fileData.filter((el: any, index: any) => index != i);
    setFileData(upadatedFileData);
  };
  const handleImageUpload = (e: any, files: any) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };
  const handleSubmit = () => {
    handleCloseDialog();
    setOpenThanksDialog(true);
  };
  const handleCloseThanksDialog = () => {
    setOpenThanksDialog(false);
  };
  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };
  const buttonHandleChange = (event: any) => {
    if (event.target.name == 'issue') {
      setActiveBug({ issue: true, feature: false });
    } else if (event.target.name == 'feature') {
      setActiveBug({ issue: false, feature: true });
    }
  };
  const handleOpen = () => {
    (window as any).ATL_JQ_PAGE_PROPS.triggerFunction();
    // setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box
      component="footer"
      sx={{
        ...Style.FooterMain,
        display: 'flex'
      }}>
      <Container maxWidth="xl">
        <Box sx={Style.LinkWrapper}>
          <Link
            sx={Style.FooterLink}
            target="_blank"
            onClick={() => navigate(PageRoutes.PrivacyPolicy)}>
            Privacy Policy
          </Link>
          <Link sx={Style.FooterLink} onClick={() => navigate(PageRoutes.TermAndCondition)}>
            Terms & Conditions
          </Link>
          <Link id="myCustomTrigger" onClick={handleOpen} sx={Style.FooterLink}>
            Feedback
          </Link>

          <Link sx={Style.FooterLink} href={'mailto:hello@buildndeploy.co'}>
            Contact us
          </Link>

          <br></br>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption">{`© 2023 Build 'n Deploy`}</Typography>
          </Box>
        </Box>
        <Dialog
          fullWidth
          open={openDialog}
          sx={{
            maxWidth: '600px',
            margin: 'auto',
            '.MuiDialog-paper': {
              width: '100%',
              m: 0
            }
          }}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle sx={{ p: 2, color: 'black' }} id="alert-dialog-title">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black',
                  fontWeight: '600'
                }}>
                Report a bug or request a feature
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{ color: 'text.primary' }}>
                <CrossCancle />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ px: { xs: 2, md: 2 }, pt: 0 }}>
            <Box sx={{ width: 1, mt: 2, mb: 1.5 }}>
              <Typography variant="body1" sx={{ color: 'black', fontWeight: '500' }}>
                I would like to
              </Typography>
            </Box>
            <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                name="issue"
                endIcon={<IssueIcon />}
                onClick={(e) => buttonHandleChange(e)}
                sx={{
                  color: 'black',
                  width: 0.47,
                  border: activeBug.issue ? '1px solid #664CCD' : '1px solid #DADADA',
                  fontSize: '16px',
                  fontWeight: 400,
                  py: 1.5,
                  borderRadius: '8px',
                  justifyContent: 'space-between',
                  px: 2
                }}>
                Report an Issue
              </Button>
              <Button
                name="feature"
                endIcon={
                  <IconButton disabled>
                    <FeatureIcon />
                  </IconButton>
                }
                onClick={(e) => buttonHandleChange(e)}
                sx={{
                  color: 'black',
                  width: 0.47,
                  borderRadius: '8px',
                  border: activeBug.feature ? '1px solid #664CCD' : '1px solid #DADADA',
                  fontSize: '16px',
                  fontWeight: 400,
                  py: 1.5,
                  justifyContent: 'space-between',
                  px: 2
                }}>
                Request a Feature
              </Button>
            </Box>
            <Box
              onDragOver={(e) => stopReload(e)}
              onDrop={(e) => handleDragEvent(e)}
              sx={{
                width: '100%',
                backgroundColor: '#FBFBFF',
                py: 4,
                display: 'flex',
                border: '1px dashed #CCCCCC',
                borderRadius: '8px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2.5,
                mt: 3
              }}>
              <DownloadIcon />
              <Typography variant="body1" sx={{ fontWeight: 500, color: '#464646' }}>
                Drag & Drop or{' '}
                <label style={{ color: '#664CCD', cursor: 'pointer' }}>
                  <input
                    multiple
                    hidden
                    onChange={(e) => handleBrowseEvent(e)}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'transparent'
                    }}
                    type={'file'}
                  />{' '}
                  Choose file{' '}
                </label>
                to upload
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1.5,
                  width: '100%',
                  alignItem: 'center',
                  m: 'auto'
                }}>
                {fileData.length == 0 || isUploade ? (
                  <Typography
                    textAlign={'center'}
                    variant="body1"
                    sx={{ color: '#787878', fontSize: '14px' }}>
                    doc,img, or video
                  </Typography>
                ) : (
                  fileData?.map((el: { type: string; name: string; size: any }, index: number) => (
                    <Box
                      key={index + 1}
                      sx={{
                        display: 'flex',
                        width: '80%',
                        m: 'auto',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1.5,
                        borderRadius: '8px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #EBEBEB'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {el.type.split('/')[0] == 'image' || el.type.split('/')[0] == 'video' ? (
                          <GallaryIcon />
                        ) : (
                          <FileIcon />
                        )}

                        <EllipsisContent
                          sx={{
                            fontSize: 14,
                            color: '#2D2D2D',
                            width: 'max-content',
                            maxWidth: {
                              xxs: 150,
                              md: 150
                            }
                          }}
                          text={el.name}
                        />

                        <Typography variant="body2" sx={{ color: '#787878' }}>
                          {niceBytes(el.size)}
                        </Typography>
                      </Box>
                      <Box onClick={() => handleRemoveFile(index)}>
                        <CrossCancle />
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, gap: 2, mt: 3 }}>
              <TextField autoComplete={'none'} placeholder="Enter a Title" />
              <TextField
                autoComplete={'none'}
                sx={{ textAlign: 'justify' }}
                multiline
                rows={4}
                placeholder="Enter a Description"
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0, m: 'auto', textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              sx={{ minWidth: 130, px: 4, py: 1.5, borderRadius: '8px' }}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          open={openThanksDialog}
          sx={{
            maxWidth: '600px',
            margin: 'auto',
            '.MuiDialog-paper': {
              width: '100%',
              m: 0
            }
          }}
          onClose={handleCloseThanksDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent sx={{ px: { xs: 2, md: 2 }, pt: 2, pb: 4, textAlign: 'center' }}>
            <img src={error ? headerTickCircle : headerCrossCircle} />
            <Typography variant="subtitle2" sx={{ color: 'black' }}>
              {error ? 'Thank you for reporting the bug.' : 'Something went wrong'}
            </Typography>
            {!error && (
              <Typography variant="body2" sx={{ mt: 1.5, width: 0.6, mx: 'auto' }}>
                Please try again or contact us at{' '}
                <a style={{ color: '#664CCD', textDecoration: 'underline' }}>
                  help@buildndeploy.co
                </a>
              </Typography>
            )}
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0, m: 'auto', textAlign: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseThanksDialog}
              sx={{ minWidth: 130, px: 4, py: 1.5, borderRadius: '8px' }}>
              {error ? 'Close' : 'Try Again'}
            </Button>
          </DialogActions>
          {error && (
            <Typography variant="body1" sx={{ color: '#664CCD', textAlign: 'center', mb: 4 }}>
              Report another bug
            </Typography>
          )}
        </Dialog>
      </Container>
    </Box>
  );
};

export default Footer;
