import { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { PaginationHelper, ProjectListResponse } from '../../models';
import { DefaultPagination, PageRoutes } from '../../utils/constants';

import { Alert, Tab, Tabs, TextField } from '@mui/material';
import moment from 'moment';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/shared/NoDataFound';
import { ProjectService } from '../../services/API/ProjectService';
import { authDetailsReducer, getAuthState, setAuthDetails } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import Style from './Dashboard.style';
import { SkeletonDashboard } from './SkeletonDashboard';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
var debouce = require('lodash.debounce');
import PropTypes from 'prop-types';
import { ContributorDashboard } from '../ContributorDashboard';
import { AuthService } from '../../services/API/AuthService';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const Dashboard: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('dashboard');
  const [repoList, setRepoList] = useState<ProjectListResponse[]>([]);
  const [pagination, setpagination] = useState<PaginationHelper>({
    pageSize: DefaultPagination.PageSize,
    currentPage: DefaultPagination.Page
  } as PaginationHelper);
  const [isLoader, setIsLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectService = new ProjectService();
  const authService = new AuthService();
  const [searchQuery, setSearchQuery] = useState('');

  const handleRepoSearch = async (event: any) => {
    let resetPagination = pagination;
    resetPagination.pageSize = DefaultPagination.PageSize;
    resetPagination.currentPage = DefaultPagination.Page;
    setpagination({ ...resetPagination });
    setHasMore(true);

    setSearchQuery(event.target.value);
    if (event.target.value?.length >= 2) {
      await handleGetProjectList(pagination, event.target.value);
    } else {
      if (event.target.value?.length == 0) {
        await handleGetProjectList(pagination, '');
        setHasMore(true);
      }
    }
  };
  const handleAddNewProject = () => {
    gaEventTracker('add_new_project');
    navigate(PageRoutes.SelectProjectType);
  };

  const getInstallationStatus = async () => {
    const installationStatus = await authService.CheckAppInstallationStatus();
    dispatch(
      setAuthDetails({
        ...auth,
        appInstallationStatus: installationStatus
      } as authDetailsReducer)
    );
  };

  async function handleGetProjectList(paginationParams: PaginationHelper, searchParams?: string) {
    setIsLoader(true);
    const repos = await projectService.ListProjects(searchParams, { ...paginationParams });
    setIsLoader(false);

    let item = pagination;
    item.totalPages = Math.ceil(repos.totalResults / pagination.pageSize);
    item.totalCount = repos.totalResults;

    if (repos.results) {
      let nextRepolist = pagination.currentPage == 1 ? [] : [...repoList];
      setRepoList([...nextRepolist, ...repos.results]);
      if (repos.totalResults <= pagination.currentPage * pagination.pageSize) {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }

  const debouncedResults = useMemo(() => {
    return debouce(handleRepoSearch, 500);
  }, [repoList]);

  useEffect(() => {
    if (auth.access_token && auth.user_details?.Alias) {
      if (localStorage.getItem('isInitiate') !== 'true') {
        projectService.InitiateAccount();
        localStorage.setItem('isInitiate', 'true');
      }
      handleGetProjectList(pagination, '');
      getInstallationStatus();
    }
  }, []);

  const handleNextPage = async () => {
    let item = pagination;
    item.currentPage = item.currentPage + 1;
    setpagination({ ...item });
    handleGetProjectList({ ...pagination }, searchQuery);
  };

  //#region Tab Helper Function

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`repository-tabpanel-${index}`}
        aria-labelledby={`repository-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function TabSequence(index: any) {
    return {
      id: `repository-tab-${index}`,
      'aria-controls': `repository-tabpanel-${index}`
    };
  }

  return (
    <Container maxWidth="xl">
      <Box sx={Style.DashboardWrapper}>
        <Typography variant="h2" sx={Style.Heading}>
          Dashboard
        </Typography>
        {!auth.appInstallationStatus && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <Typography>
              Its look like you have not installed the github app yet, you may not be able to use
              all features. Please{' '}
              <a
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'underLine',
                  color: 'inherit'
                }}
                href={`https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new?state=bnd-prod&redirect_uri=${window.location.href}`}>
                install github apps.
              </a>
            </Typography>
          </Alert>
        )}

        <Box
          sx={{
            width: '100%',
            mt: 4,
            mb: 1,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={Style.TabButtonWrapper}
            textColor="secondary"
            indicatorColor="secondary">
            <Tab label="Your Projects" {...TabSequence(0)} />
            <Tab label="Collaborator Projects" {...TabSequence(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item xs={12} sm={9} sx={{ width: 1 }}>
              <FormControl sx={{ width: 1 }} variant="outlined">
                <TextField
                  label="Search for a project"
                  variant="outlined"
                  id="search"
                  autoComplete="off"
                  placeholder="Search"
                  onChange={debouncedResults}
                  aria-describedby="search"
                  inputProps={{
                    'aria-label': 'search'
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ width: 1, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                sx={Style.AddProject}
                onClick={handleAddNewProject}>
                New Project
              </Button>
            </Grid>
          </Grid>

          <List>
            <InfiniteScroll
              dataLength={repoList.length}
              next={async () => await handleNextPage()}
              hasMore={hasMore}
              loader={isLoader && <SkeletonDashboard />}>
              {repoList.map((Element, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={Style.ProjectList}
                  onClick={() =>
                    navigate(PageRoutes.RepositoryDetails.replace(':id', Element.Name))
                  }>
                  <Box sx={Style.UserBox}>
                    {/* <Box sx={Style.UserThumb}>
                  <img src={Element.profilePicture} alt="profile" />
                </Box> */}
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'common.blackText',
                          fontWeight: '500',
                          wordBreak: 'break-word'
                        }}>
                        {Element.Name}
                      </Typography>
                      <Typography variant="caption" sx={{ wordBreak: 'break-word' }}>
                        {Element.Source}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={Style.CommitBoxWrapper}>
                    <Typography variant="body1" sx={{ color: 'common.blackText' }}>
                      {moment(Element.UpdatedAt).format('MM-DD-yyyy hh:mm a')}
                    </Typography>
                    {/* <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ padding: '0 4px' }}>
                    {'Element.commitTime'}
                  </Box>
                  <HierarchIcon
                    sx={{ fontSize: 16, mx: 0.5 }}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  />
                  <Box component="span">{'Element.branchName'}</Box>
                </Typography> */}
                  </Box>
                </ListItem>
              ))}
            </InfiniteScroll>
            {repoList.length === 0 && !isLoader && <NoDataFound />}
          </List>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ContributorDashboard></ContributorDashboard>
        </TabPanel>
      </Box>
    </Container>
  );
};
