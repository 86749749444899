import styled from '@emotion/styled';

const SectionWrapper = styled.div`
  height: 100vh;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    min-height: 100vh;
  }
  .planetBlue {
    position: absolute;
    right: -14%;
    top: 20%;
    z-index: -1;
    transform-origin: right;
    transition: ease all 1s;
    @media only screen and (max-height: 800px) {
      top: 20%;
      right: -5%;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .planetPink {
    position: absolute;
    left: 40%;
    bottom: 13%;
    z-index: -1;
    transition: ease all 1s;
    @media only screen and (max-height: 1024px) {
      left: 30%;
      bottom: 9%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .patterPlanet {
    position: absolute;
    top: 35%;
    left: 25vw;
    z-index: -1;
    transition: ease all 4s;
    transition-delay: 0.3s;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;
const DashboardMain = styled.div`
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  .topSlide,
  .bottomSlide {
    display: flex;
    flex-wrap: wrap;
  }
  .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #fcfdff;
    width: 30%;
    padding-right: 15px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0 0 15px 0;
    }
  }
  .image {
    width: 70%;
    height: 70vh;
    max-height: 80vh;
    text-align: right;
    @media only screen and (max-width: 991px) {
      height: auto;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    img {
      max-height: 100%;
      width: 58vw;
      max-width: 850px;
      height: auto;
      object-fit: contain;
      @media only screen and (max-width: 768px) {
        width: 100%;
        max-height: 25vh;
        /* margin-top: 50px; */
      }
      @media only screen and (max-height: 899px) {
        height: 90%;
      }
      @media (max-width: 767px) and (max-height: 899px) {
        height: 100%;
      }
    }
  }
  .topSlide {
    transform: translateY(10vh);
    transition: ease all 3s;
    .text {
      padding-top: 5vh;
      @media only screen and (max-width: 768px) {
        padding-top: 15px;
      }
    }
    @media only screen and (max-width: 768px) {
      transform: none;
    }
  }
  .bottomSlide {
    position: absolute;
    top: 15%;
    align-items: center;
    @media only screen and (max-width: 768px) {
      position: static;
      transform: scale(0.7);
      transition: ease all 1s;
      transform: none;
    }
    .text {
      padding-top: 5vh;
      @media only screen and (max-width: 768px) {
        padding-top: 15px;
      }
    }
    .image {
      img {
        max-width: 88%;
        height: 100%;
        object-fit: contain;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
          /* margin-top: 50px; */
        }

        @media only screen and (max-height: 899px) {
          height: 90%;
        }
        @media (max-width: 767px) and (max-height: 899px) {
          height: 100%;
        }
      }
    }
  }

  .desktop {
    display: flex;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: flex;
      height: 100vh;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
`;

const DashboardHandler = styled.div`
  position: absolute;
  top: 20vh;
  left: 0;
  height: 20px;
  width: 100%;
`;

export { SectionWrapper, DashboardMain, DashboardHandler };
