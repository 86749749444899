import styled from '@emotion/styled';

const FormBox = styled.form`
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 586px;
  background: rgba(252, 253, 255, 0.2);
  border: 1px solid rgba(223, 219, 216, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 12px;
  padding: 8px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
  input {
    flex-grow: 1;
    padding: 16px 20px;
    background: transparent;
    color: #ffffff;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    &::-webkit-input-placeholder {
      color: #dadada;
    }
    &::-moz-placeholder {
      color: #dadada;
    }
    &:-ms-input-placeholder {
      color: #dadada;
    }
    &:-moz-placeholder {
      color: #dadada;
    }
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 15px;
      max-width: calc(100% - 90px);
    }
    @media only screen and (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 15px;
      max-width: calc(100% - 100px);
    }
  }
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 20px;
    background: linear-gradient(90deg, #664ccd 0%, #8f5de5 100%);
    border-radius: 8px;
    color: #ffffff;
    min-width: 140px;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 15px;
      min-width: 90px;
    }
  }
`;

export const StatusBox = styled.div`
  height: 25px;
  width: 100%;
  max-width: 586px;
  overflow: auto;
  text-align: start;
  padding: 0 5px;
`;

export { FormBox };
