const Style = {
  VscodeLoginWrapper: {
    width: '100%',
    height: 'calc(100vh - 136px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  VscodeLoginCard: {
    maxWidth: '95%',
    width: '500px',
    margin: '0 auto',
    padding: '42px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    gap: '50px'
  },
  VscodeLoginCardText: {
    fontSize: '20px',
    fontWeight: '500'
  },
  VscodeLoginCardBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    width: '100%',
    gap: '24px'
  }
};

export default Style;
