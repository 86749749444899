import { InputLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FC } from 'react';
import { GeneralForm } from '..';
import { language } from '../../../utils/language';

import Style from './ConfigProject.style';

export const ConfigProject: FC<GeneralForm> = (props) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '200px'
      }
    }
  };
  return (
    <>
      {/* to disable the whole box className="disable" */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} sx={{ width: 1 }}>
          <TextField
            variant="outlined"
            label="Project Name"
            fullWidth
            placeholder="Project Name"
            id="ProjectName"
            onChange={(event) => {
              props.onChange('Name', event?.target.value);
            }}
            error={props.Name ? false : true}
            helperText={props.Name ? '' : 'This field is required'}
            value={props.Name || ''}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'ProjectName'
            }}
            disabled={props.nameDisable}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: 1 }}>
          <TextField
            variant="outlined"
            label="Source"
            disabled
            fullWidth
            id="Source"
            placeholder="https://github.com/pacenthink/bnd-ui.git"
            onChange={(event) => {
              props.onChange('Source', event?.target.value);
            }}
            error={props.Source ? false : true}
            helperText={props.Source ? '' : 'This field is required'}
            value={props.Source || ''}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'RootDirectory'
            }}
          />
        </Grid>
        {/* TODO: Not in these realase */}

        <>
          <Grid item xs={12} md={6} sx={{ width: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="framework-select-label">Language</InputLabel>
              <Select
                labelId="framework-select-label"
                variant="outlined"
                label="Language"
                sx={{
                  '.MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center'
                  }
                }}
                onChange={(event) => {
                  props.onChange('Language', event?.target.value);
                }}
                value={
                  language.filter(
                    (el) => el?.languageName?.toLowerCase() === props?.Language?.toLowerCase()
                  ).length > 0
                    ? language.filter(
                        (el) => el?.languageName?.toLowerCase() === props.Language?.toLowerCase()
                      )[0].languageName
                    : 'Other'
                }
                displayEmpty
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}>
                {language.map((Element, index) => (
                  <MenuItem key={index + 1} sx={Style.ProjectList} value={Element.languageName}>
                    <Box sx={Style.ProjectListImage}>{Element.logo}</Box>
                    <Box sx={{ ml: 1.5 }}>{Element.languageName}</Box>
                  </MenuItem>
                ))}

                {/* <MenuItem sx={Style.ProjectList} value={'vuejs'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  VueJs
                </MenuItem>
                <MenuItem sx={Style.ProjectList} value={'port'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  Rect
                </MenuItem>
                <MenuItem sx={Style.ProjectList} value={'wordpress'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  WordPress
                </MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default ConfigProject;
