import { Skeleton, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import { DeployInfo } from '../../../../models';
import { getAuthState } from '../../../../store/reducers/authReducer';
import { RootState } from '../../../../store/store';
import { GetRefiendEndpoint } from '../../../../utils/commonfunction';
import { APIRoutes } from '../../../../utils/constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { notifySuccess } from '../../../../services/toster';
import useAnalyticsEventTracker from '../../../../hooks/useAnalyticsEventTracker';
import { useSearchParams } from 'react-router-dom';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const ApplicationLogs: FC<{
  projectName: string;
  branch: string;
  deployData: DeployInfo | undefined;
}> = (props) => {
  const gaEventTracker = useAnalyticsEventTracker('application_logs');
  const auth = useTypedSelector(getAuthState, shallowEqual);
  // const [logs, setLogs] = useState<string>('');
  // const [isSkeleton, setIsSkeleton] = useState(false);
  // const refLogs = useRef('');
  const env = process.env;
  const [checked, setChecked] = useState(false);
  const [alias, setAlias] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  // // deprecated
  // const fetchApplicationLogs = async () => {
  //   gaEventTracker('application_logs_clicked');
  //   setIsSkeleton(true);
  //   var respo = await fetch(
  //     `${env.REACT_APP_DEPLOY_API_BASE_URL}` +
  //       GetRefiendEndpoint(APIRoutes.GetDeployServiceLogs, [
  //         auth.user_details.Alias,
  //         props.projectName,
  //         props.branch
  //       ]) +
  //       '?follow=true',
  //     {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `bearer ${auth.access_token}`
  //       }
  //     }
  //   ).finally(() => {
  //     setIsSkeleton(false);
  //   });

  //   const reader = respo.body?.getReader();

  //   if (typeof reader != 'undefined') {
  //     for (;;) {
  //       const { value, done } = await reader.read();

  //       if (done) {
  //         break;
  //       }

  //       var t1 = new TextDecoder().decode(value);
  //       const item = refLogs.current + t1;
  //       refLogs.current = item;
  //       setLogs(item);
  //     }
  //   }
  // };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  // useEffect(() => {
  //   // props.deployData?.Service?.Events.forEach((element: any) => {
  //   //   const item = refLogs.current + element.DisplayMessage;
  //   //   refLogs.current = item + '\n';
  //   //   setLogs(item);
  //   // });
  //   // setLogs(['asdasd', 'asdasdasd', 'sadasd']);
  //   if (props.deployData?.Status != 'Not Running') {
  //     // fetchApplicationLogs();
  //     setLogs(``);
  //   }
  // }, []);

  function getCurrentAuth() {
    var auth = JSON.parse(localStorage.getItem('auth') || '');
    return auth;
  }

  useEffect(() => {
    if (searchParams.get('alias')) {
      setAlias(searchParams.get('alias') || '');
    } else {
      setAlias(auth.user_details.Alias);
    }
  }, []);

  return (
    <Box>
      {alias && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography
              sx={{
                mr: 5,
                fontWeight: 500,
                fontStyle: 'italic',
                color: 'red'
              }}>
              Most recent logs are on the top
            </Typography>
            <Typography>Follow:</Typography>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <ScrollFollow
              startFollowing={checked}
              render={({ follow, onScroll }) => (
                <LazyLog
                  stream
                  enableSearch
                  follow={follow}
                  url={
                    `${env.REACT_APP_DEPLOY_API_BASE_URL}` +
                    GetRefiendEndpoint(APIRoutes.GetDeployServiceLogs, [
                      alias,
                      props.projectName,
                      props.branch
                    ]) +
                    '?follow=true'
                  }
                  height={400}
                  fetchOptions={{
                    method: 'GET',
                    headers: { Authorization: `bearer ${getCurrentAuth().access_token}` }
                  }}></LazyLog>
              )}
            />
            {/* {logs && !isSkeleton && (
        <Box
          onClick={() => {
            navigator.clipboard.writeText(logs);
            notifySuccess('Copied');
          }}
          sx={{
            position: 'absolute',
            cursor: 'pointer',
            top: '20px',
            right: '20px',
            backgroundColor: '#000000',
            borderRadius: '8px',
            border: '1px solid grey',
            py: 1,
            px: 1.5
          }}>
          <ContentCopyIcon />
        </Box>
      )} */}
          </Box>
        </>
      )}
    </Box>
  );
};
