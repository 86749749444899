import * as Style from './LandingHeader.style';
import BndLogo from '../../assets/images/bnd.svg';
import { PageRoutes } from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button, Divider, Drawer, IconButton, ListItemButton, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '../../assets/icons/Close';
import MenuIcon from '../../assets/icons/Menu';

const LandingHeader = ({ isShow, isHeaderSolid }: { isShow: boolean; isHeaderSolid: boolean }) => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate(PageRoutes.Login);
  };

  const [open, setState] = useState(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  return (
    <Style.HeaderSection className={`${isShow ? 'active' : ''} ${isHeaderSolid ? 'solid' : ''}`}>
      <Box className="container">
        <Box className="header-main">
          <Box className="logo">
            <Link to={PageRoutes.Dashboard}>
              <img src={BndLogo} alt="shape" />
            </Link>
            <Box sx={{ display: { xxs: 'none', md: 'flex' }, gap: '22px' }}>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(PageRoutes.DocumentationPage)}>
                Documentation
              </Box>
              <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(PageRoutes.PricingPage)}>
                Pricing
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: { xxs: 'none', md: 'flex' }, gap: 1 }}>
            <Button
              onClick={() => {
                handleLogin();
              }}
              variant="outlined"
              sx={{
                minWidth: '100px',
                border: '1px solid #8f5de5 !important',
                borderColor: 'action.focus',
                color: '#fff',
                fontSize: '16px'
              }}>
              Sign in
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                minWidth: '100px',
                fontSize: '16px',
                background: 'linear-gradient(90deg, #664ccd 0%, #8f5de5 100%)',
                boxShadow: '0px 10px 40px rgb(255 255 255 / 12%)'
              }}
              onClick={() => {
                handleLogin();
              }}>
              Sign up
            </Button>
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              display: {
                xs: 'block',
                md: 'none'
              }
            }}>
            <MenuIcon fontSize="large" />
          </IconButton>

          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={Style.DrawerBlock}>
              <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
                <CloseIcon
                  sx={{
                    color: 'common.white'
                  }}
                />
              </IconButton>

              <Divider sx={{ mb: 2, backgroundColor: 'common.white' }} />

              <Box sx={{ mb: 2 }}>
                <ListItemButton>
                  <ListItemText
                    primary="Documentation"
                    sx={{
                      color: 'common.white'
                    }}
                    onClick={() => navigate(PageRoutes.DocumentationPage)}
                  />
                </ListItemButton>

                <ListItemButton>
                  <ListItemText
                    primary="Pricing"
                    sx={{
                      color: 'common.white'
                    }}
                    onClick={() => navigate(PageRoutes.PricingPage)}
                  />
                </ListItemButton>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Button
                  onClick={() => {
                    handleLogin();
                  }}
                  variant="outlined"
                  sx={{
                    width: 1,
                    border: '1px solid #8f5de5 !important',
                    borderColor: 'action.focus',
                    color: '#fff',
                    fontSize: '16px'
                  }}>
                  Sign in
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: 1,
                    fontSize: '16px',
                    background: 'linear-gradient(90deg, #664ccd 0%, #8f5de5 100%)',
                    boxShadow: '0px 10px 40px rgb(255 255 255 / 12%)'
                  }}
                  onClick={() => {
                    handleLogin();
                  }}>
                  Sign up
                </Button>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </Style.HeaderSection>
  );
};

export default LandingHeader;
