import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';

import './App.css';
import RequireAuth from './components/AuthGuard';
import ConfirmDialog from './components/shared/ConfirmDialog';
import MainContainer from './components/shared/Container';
import { AuthComplete } from './pages/AuthComplete';
import { ConfigureProject } from './pages/ConfigureProject';
import { Dashboard } from './pages/Dashboard';
import { DetectingLogs } from './pages/DetectingLogs';
import { Repository } from './pages/Repository';
import { RepositoryDetails } from './pages/RepositoryDetails';
import { StatusPage } from './pages/Status';
import { PageRoutes } from './utils/constants';
import { ManageBranchConfiguration } from './pages/BranchConfiguration/ManageBranchConfiguration';
import ReactGA from 'react-ga4';
import LandingPage from './pages/LandingPage/Landing';
// import LandingPage from './pages/LandingPage';
import DocumentationPage from './pages/Documentation';
import DocumentMainContent from './pages/Documentation/DocumentMainContent';
import GetStaredStepOne from './pages/Documentation/GetStared/GetStaredStepOne';
import GetStartedStepTwo from './pages/Documentation/GetStared/GetStartedStepTwo';
import GetStartedStepThree from './pages/Documentation/GetStared/GetStartedStepThree';
import GetStartedStepFour from './pages/Documentation/GetStared/GetStartedStepFour';
import PricingPage from './pages/PricingPage';

import PrivacyPolicy from './pages/PrivacyPolicy';

import TermAndCondition from './pages/TermAndCondition';
import SelectProjectType from './pages/SelectProjectType';
import BuildNewProject from './pages/BuildNewProject';
import { ProjectConfigure } from './pages/ProjectConfigure';
import DockerFileInstruction from './pages/Documentation/Dockerfile/DocketfileInstruction';
import GithubAppInstallationInstruction from './pages/Documentation/GithubAppInstallGuide/GithubAppInstallationGuide';
import { ContributorDashboard } from './pages/ContributorDashboard';
import VscodeLogin from './pages/VscodeLogin';

const App: FC = () => {
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID || '';
  ReactGA.initialize(googleAnalyticsId);
  return (
    <Router>
      <MainContainer>
        <ConfirmDialog />

        <Routes>
          <Route path={PageRoutes.Login} element={<Login />} />
          <Route path={PageRoutes.VscodeLogin} element={<VscodeLogin />} />
          <Route path={PageRoutes.LandingPage} element={<LandingPage />} />
          <Route path={PageRoutes.PricingPage} element={<PricingPage />} />
          <Route path={PageRoutes.GithubAuthComplete} element={<AuthComplete />} />
          <Route path={PageRoutes.Status} element={<StatusPage />} />
          <Route path={PageRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={PageRoutes.Any} element={<NotFound />} />
          <Route path={PageRoutes.TermAndCondition} element={<TermAndCondition />} />
          <Route
            path={PageRoutes.DocumentationPage}
            element={
              <DocumentationPage>
                <DocumentMainContent />
              </DocumentationPage>
            }
          />
          <Route
            path={PageRoutes.GetStartedStepOne}
            element={
              <DocumentationPage>
                <GetStaredStepOne />
              </DocumentationPage>
            }
          />

          <Route
            path={PageRoutes.GetStartedStepTwo}
            element={
              <DocumentationPage>
                <GetStartedStepTwo />
              </DocumentationPage>
            }
          />
          <Route
            path={PageRoutes.GetStartedStepThree}
            element={
              <DocumentationPage>
                <GetStartedStepThree />
              </DocumentationPage>
            }
          />
          <Route
            path={PageRoutes.GetStartedStepFour}
            element={
              <DocumentationPage>
                <GetStartedStepFour />
              </DocumentationPage>
            }
          />
          <Route
            path={PageRoutes.DockerFileInstruction}
            element={
              <DocumentationPage>
                <DockerFileInstruction />
              </DocumentationPage>
            }
          />
          <Route
            path={PageRoutes.GithubAppInstallation}
            element={
              <DocumentationPage>
                <GithubAppInstallationInstruction />
              </DocumentationPage>
            }
          />
          <Route element={<RequireAuth />}>
            <Route path={PageRoutes.Dashboard} element={<Dashboard />} />
            <Route path={PageRoutes.ContributorDashboard} element={<ContributorDashboard />} />

            <Route path={PageRoutes.SelectProjectType} element={<SelectProjectType />} />
            <Route path={PageRoutes.BuildNewProject} element={<BuildNewProject />} />

            <Route path={PageRoutes.Repository} element={<Repository />} />
            <Route path={PageRoutes.RepositoryDetails} element={<RepositoryDetails />} />
            <Route path={PageRoutes.ConfigureProject} element={<ConfigureProject />} />
            {/* Need to un-comment once we add API in homz */}
            <Route path={PageRoutes.ProjectConfigure} element={<ProjectConfigure />} />
            <Route
              path={PageRoutes.ManageBranchConfiguration}
              element={<ManageBranchConfiguration />}
            />
            <Route path={PageRoutes.DetectingLogs} element={<DetectingLogs />} />
          </Route>
        </Routes>
      </MainContainer>
    </Router>
  );
};

export default App;
