import styled from '@emotion/styled';
import { Button, Grid, Typography, Paper, Container } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { TypedUseSelectorHook, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircleTwoLayer from '../../assets/icons/CircleTwoLayer';
import CircleThreeLayer from '../../assets/icons/CircleThreeLayer';
import CircleZap from '../../assets/icons/CircleZap';
import TickCircleIcon from '../../assets/icons/TickCircle';
import MacBookPro from '../../assets/images/MacBookPro.jpg';

import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { PageRoutes } from '../../utils/constants';
import Style from './PricingPage.style';
import TickCircleEmpty from '../../assets/icons/TickcircleEmpty';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const PricingPage: FC = () => {
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.access_token) {
      navigate(PageRoutes.Dashboard);
    }
  }, []);

  const Item = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '40px 24px 24px',
    textAlign: 'left',
    color: '#64748B',
    height: '604px',
    borderRadius: '12px'
  });

  return (
    <Container maxWidth={'xl'}>
      <Box
        sx={{
          width: 1,
          mb: 5,
          position: 'relative'
        }}>
        <Box sx={{ width: 1, m: 'auto' }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xxs: '32px', md: '48px' },
              fontWeight: 500,
              lineHeight: { xxs: '44px', md: '64px' },
              textAlign: 'center',
              color: '#191D23',
              width: { xxs: 1, md: 0.7 },
              m: 'auto',
              mb: 2
            }}>
            Plans that start free <br /> and{' '}
            <Typography
              variant="h1"
              component="span"
              sx={{
                ...Style.HighlightHeaderText,
                fontSize: { xxs: '32px', md: '48px' },
                lineHeight: { xxs: '32px', md: '84px' }
              }}
              style={{
                fontWeight: 500
              }}>
              grow with you
            </Typography>
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '30px',
              textAlign: 'center',
              width: { xxs: 1, md: 0.55 },
              color: '#191D23',
              m: 'auto',
              mb: 9
            }}>
            Choose a plan that’s right for you
          </Typography>
          <Box>
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    background: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)',
                    padding: '2px',
                    borderRadius: '14px'
                  }}>
                  <Item
                  // border: '2px solid secondary.main'
                  >
                    <Box>
                      <Box sx={Style.CardTitle}>
                        <CircleZap />
                        <Typography
                          variant="h2"
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '24px',
                            lineHeight: '36px',
                            color: '#191D23'
                          }}>
                          Free
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          mb: 3
                        }}>
                        Ideal for individuals who need quick access to basic features.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          mb: 2,
                          visibility: 'hidden'
                        }}>
                        <Typography
                          variant="h2"
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '56px',
                            lineHeight: '84px',
                            color: '#191D23'
                          }}>
                          $0
                        </Typography>
                        <Typography>/ Month</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          gap: '12px',
                          mb: 1.5
                        }}>
                        <TickCircleEmpty sx={{ ml: 1 }} />
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#191D23'
                          }}>
                          CPU 80 MHz
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '12px',
                          mb: 1.5
                        }}>
                        <TickCircleEmpty sx={{ ml: 1 }} />
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#191D23'
                          }}>
                          Memory 64 MiB
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '12px',
                          mb: 1.5
                        }}>
                        <TickCircleEmpty sx={{ ml: 1 }} />
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#191D23'
                          }}>
                          Disk 512 MiB
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={Style.StartButton}
                        onClick={() => navigate(PageRoutes.Login)}>
                        Get Started for Free
                      </Button>
                    </Box>
                  </Item>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Item
                  sx={{
                    backgroundColor: '#FAFAFA !important',
                    boxShadow: 'none',
                    border: '1px solid #6b6b6b'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    <Box sx={Style.BestValueTag}>
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: 'secondary.main'
                        }}>
                        Best Value
                      </Typography>
                    </Box>
                    <Box sx={Style.CardTitle}>
                      <CircleTwoLayer />
                      <Typography
                        variant="h2"
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '24px',
                          lineHeight: '36px',
                          color: '#191D23'
                        }}>
                        Advanced
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        mb: 2
                      }}>
                      Ideal for individuals who who need advanced features and tools for client
                      work.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        visibility: 'hidden',
                        gap: '8px',
                        mb: 0
                      }}>
                      <Typography
                        variant="h2"
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '56px',
                          lineHeight: '84px',
                          color: '#191D23'
                        }}>
                        $0
                      </Typography>
                      <Typography>/ Month</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        CPU 256 MHz
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        Memory 512 MiB
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        Disk 1 GiB
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={Style.StartButton}
                      href={'mailto:sales@buildndeploy.co'}>
                      Contact Sales
                    </Button>
                  </Box>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Item
                  sx={{
                    backgroundColor: '#FAFAFA !important',
                    boxShadow: 'none',
                    border: '1px solid #6b6b6b'
                  }}>
                  <Box>
                    <Box sx={Style.CardTitle}>
                      <CircleThreeLayer />
                      <Typography
                        variant="h2"
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '24px',
                          lineHeight: '36px',
                          color: '#191D23'
                        }}>
                        Professional
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        mb: 2
                      }}>
                      Ideal for businesses who need personalised services and security for large
                      teams.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        visibility: 'hidden',
                        gap: '8px',
                        mb: 0
                      }}>
                      <Typography
                        variant="h2"
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '56px',
                          lineHeight: '84px',
                          color: '#191D23'
                        }}>
                        $0
                      </Typography>
                      <Typography>/ Month</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        CPU 512 MHz
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        Memory 1 GiB
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '12px',
                        mb: 1.5
                      }}>
                      <TickCircleEmpty sx={{ ml: 1 }} />
                      <Typography
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#191D23'
                        }}>
                        Disk 5 GiB
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={Style.StartButton}
                      href={'mailto:sales@buildndeploy.co'}>
                      Contact Sales
                    </Button>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default PricingPage;
