import { useEffect } from 'react';
import { useSelector, TypedUseSelectorHook, shallowEqual, useDispatch } from 'react-redux';
import { getAuthState } from '../store/reducers/authReducer';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RootState } from '../store/store';
import { APIRoutes, PageRoutes } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

import { authDetailsReducer, setAuthDetails } from '../store/reducers/authReducer';

const env = process.env;
const axiosInstance = axios.create();
let flag = false;

function getCurrentAuth() {
  var auth = JSON.parse(localStorage.getItem('auth') || '');
  return auth;
}

function setCurrentAuth(data: authDetailsReducer) {
  var auth = localStorage.setItem('auth', JSON.stringify(data));
}

function logout() {
  localStorage.clear();
  window.location.href = `${PageRoutes.Login}`;
}

const refresh = async () => {
  const auth = getCurrentAuth();
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${auth.access_token}`
    };
    const response = await axiosInstance.post(
      `${env.REACT_APP_AUTH_API_BASE_URL}${APIRoutes.RefreshToken}`,
      {
        refresh_token: auth.refresh_token
      },
      { headers: headers }
    );
    if (response.data) {
      const body: authDetailsReducer = response.data;
      setCurrentAuth(body);
      return response.data.access_token;
    }
  } catch (error: any) {
    logout();
  }
};

const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: any = undefined;
  return () => {
    if (isCalled && flag) {
      return runningPromise;
    } else {
      isCalled = true;
      flag = true;
      runningPromise = refresh();
      return runningPromise;
    }
  };
};

function shouldRetry(config: any) {
  return config.retries.count < 3;
}

const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const auth = getCurrentAuth();
    if (!config.headers!['Authorization']) {
      config.headers!['Authorization'] = `bearer ${auth.access_token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    error.config.retries = error.config.retries || {
      count: 0
    };
    const originalRequest = error.config;
    // logout user's session if refresh token api responds 401 UNAUTHORIZED
    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      shouldRetry(error.config)
    ) {
      error.config.retries.count += 1;
      if (error?.response?.data?.error == 'REFRESH_TOKEN_EXPIRED_INVALID') {
        logout();
      }
      const updatedToken = await refreshExpiredToken();
      if (!updatedToken) {
        logout();
      } else {
        originalRequest.headers['Authorization'] = `bearer ${updatedToken}`;
        flag = false;
        return axiosInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
