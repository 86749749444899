import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import Container from '@mui/material/Container';
import { FC, useEffect, useState } from 'react';
import { TypedUseSelectorHook, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../../../components/shared/Loader';
import {
  BuildFlagEnum,
  BuildServiceBranchConfigRequest,
  DeployServiceBranchConfigRequest,
  DetectResponse,
  EnvVars,
  GithubProjectBranch,
  PortConfig,
  Project
} from '../../../models';
import { BuildService } from '../../../services/API/BuildService';
import { DeployService } from '../../../services/API/DeployService';
import { DetectService } from '../../../services/API/DetectService';
import { ProjectService } from '../../../services/API/ProjectService';
import { notifyError } from '../../../services/toster';
import { setRepositoryDetect } from '../../../store/reducers/configureProjectReducer';
import { PageRoutes } from '../../../utils/constants';
import Style from './ManageBranchConfiguration.style';

export const ManageBranchConfiguration: FC = () => {
  const navigate = useNavigate();
  const projectService = new ProjectService();
  const buildAPIService = new BuildService();
  const detectAPIService = new DetectService();
  const deployAPIService = new DeployService();

  const [projectDetails, setProjectDetails] = useState<Project>({} as Project);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [branch, setBranch] = useState<string>('');
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listProjectBranches, setListProjectBranches] = useState<GithubProjectBranch[]>([]);

  async function handleListProjectBranches(namespace: string, projectName: string) {
    const result = await projectService.GetListProjectBranches(projectName, namespace);
    if (result.length > 0) {
      setListProjectBranches(result);
      setBranch(result[0].name);
    }
  }

  const { id } = useParams();

  async function getProjectById(id: string) {
    setIsSkeleton(true);
    let response = await projectService.GetProjectById(id, searchParams.get('alias'));
    if (!response?.ID) {
      navigate(PageRoutes.Dashboard);
    }
    setProjectDetails(response);
    var subStrings = response.Source.split('/');
    await handleListProjectBranches(subStrings[1], subStrings[2]);
    setIsSkeleton(false);
  }
  const handleBrnachSelect = (e: any) => {
    setBranch(e.target.value);
  };

  const handleConfigureClick = async () => {
    // Check for selected brnach
    if (!branch) {
      setIsSkeleton(false);
      notifyError('Select the branch');
      return;
    }

    if (branch.includes('/')) {
      notifyError("We are not supporting branch name having slash '/'");
      return;
    }
    setIsSkeleton(true);

    try {
      const result = await getDetection();
      let buildRequest = handleSetBuildRequest(result);
      let deployRequest = handleSetDeployRequest(result);
      let buildResult = await buildAPIService.CreateBranchConfig(
        buildRequest,
        searchParams.get('alias')
      );
      let deployResult = await deployAPIService.CreateBranchConfig(
        deployRequest,
        searchParams.get('alias')
      );
      setIsSkeleton(false);
      if (buildResult || deployResult) {
        if (searchParams.get('alias')) {
          navigate({
            pathname: PageRoutes.DetectingLogs.replace(':id', projectDetails.Name),
            search: createSearchParams({
              branch: buildRequest.Branch,
              isNew: 'true',
              alias: searchParams.get('alias') || ''
            }).toString()
          });
        } else {
          navigate({
            pathname: PageRoutes.DetectingLogs.replace(':id', projectDetails.Name),
            search: createSearchParams({ branch: buildRequest.Branch, isNew: 'true' }).toString()
          });
        }
      }
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  async function getDetection() {
    let response = await detectAPIService.DetectByBranch(
      projectDetails.Name,
      branch,
      searchParams.get('alias')
    );
    dispatch(setRepositoryDetect(response));
    return response;
  }

  function handleSetDeployRequest(data: DetectResponse) {
    let deployRequest = {} as DeployServiceBranchConfigRequest;
    deployRequest.Branch = branch || '';
    deployRequest.Project = projectDetails.Name;

    if (data?.env) {
      let envs: EnvVars[] = [];
      Object.keys(data.env).forEach((key) => {
        envs.push({
          Key: key,
          Value: data.env[key]
        });
      });
      deployRequest.Env = envs;
    }
    if (data?.ports) {
      let ports: PortConfig[] = [];
      Object.keys(data.ports).forEach((key) => {
        ports.push({
          Port: parseInt(key),
          Protocol: data.ports[key],
          HealthCheck: '/'
        });
      });
      deployRequest.Ports = ports;
    }
    return deployRequest;
  }

  function handleSetBuildRequest(data: DetectResponse) {
    let buildRequest = {} as BuildServiceBranchConfigRequest;
    buildRequest.Branch = branch || '';
    buildRequest.Project = projectDetails.Name;
    buildRequest.Context = data.build?.context;
    buildRequest.Dockerfile = data.build?.instructions_file;
    buildRequest.Language = data.language?.toLocaleLowerCase();
    buildRequest.IsAutoDeploy = true;
    buildRequest.BuildFlag = BuildFlagEnum.INLINE;
    return buildRequest;
  }

  useEffect(() => {
    if (typeof id != 'undefined') {
      getProjectById(id);
    } else {
      navigate(PageRoutes.Dashboard);
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography variant="h2" sx={Style.Heading}>
        Add New Branch Config
      </Typography>
      <Box sx={Style.DetailsHeader}>
        <Box>
          {' '}
          <Typography
            variant="caption"
            sx={{ color: 'common.blackText', fontSize: '18px', fontWeight: '600' }}>
            {projectDetails.Name}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Loader isShow={isSkeleton} />
        {!isSkeleton && (
          <FormControl fullWidth>
            <Box sx={Style.SetupCard}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ width: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel id="branch-label">Branch</InputLabel>
                    <Select
                      labelId="branch-label"
                      id="demo-simple-select"
                      value={branch || ''}
                      onChange={handleBrnachSelect}
                      label="Branch"
                      placeholder="Select Branch">
                      {listProjectBranches.map((element, index) => (
                        <MenuItem key={index + 1} value={element.name}>
                          <Typography ml={'8px'}>{element.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ width: 1, textAlign: 'center' }}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ mr: { xxs: 1, sm: 2 } }}
                    onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isSkeleton}
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleConfigureClick}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        )}
      </Box>
    </Container>
  );
};
