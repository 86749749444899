const CircleZap = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#664CCD" fillOpacity="0.1" />
      <path
        d="M20.8333 11.6667L12.5 21.6667H20L19.1667 28.3334L27.5 18.3334H20L20.8333 11.6667Z"
        stroke="#664CCD"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.5"
        y="1.5"
        width="37"
        height="37"
        rx="18.5"
        stroke="#664CCD"
        strokeOpacity="0.1"
        strokeWidth="3"
      />
    </svg>
  );
};

export default CircleZap;
