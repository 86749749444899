import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetectResponse, RepositoryListItem } from '../../models';
import { RootState } from '../store';

export type configureProjectReducerClass = {
  repository: RepositoryListItem;
  detect: DetectResponse;
};

const initialState = {} as configureProjectReducerClass;

export const configureProjectSlice = createSlice({
  name: 'configureProject',
  initialState: initialState,
  reducers: {
    setRepositoryDetails: function (
      state: configureProjectReducerClass,
      action: PayloadAction<RepositoryListItem>
    ) {
      state.repository = action.payload;
      return state;
    },
    setRepositoryDetect: function (
      state: configureProjectReducerClass,
      action: PayloadAction<DetectResponse>
    ) {
      state.detect = action.payload;
      return state;
    },
    resetRepositoryDetails: () => {
      return {} as configureProjectReducerClass;
    }
  }
});

export const getConfigureProjectState = (state: RootState) => state.configureProjectData;

export const { setRepositoryDetails, resetRepositoryDetails, setRepositoryDetect } =
  configureProjectSlice.actions;

export default configureProjectSlice.reducer;
