const Style = {
  Heading: {
    color: 'common.blackText',
    textAlign: 'center',
    mb: { xxs: 3, md: 4, lg: 5 },
    pt: { xxs: 2, md: 4 }
  },
  SearchBox: {
    mb: { xxs: 3, md: 4 },
    mx: 'auto',
    width: 1,
    maxWidth: 600
  },
  BranchSelect: {
    color: 'common.blackText',

    borderRadius: 1,

    '.MuiSelect-select': {
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      py: 1.5
    },
    '&.MuiInputBase-root': {
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    }
  },
  DropDownBox: {
    mb: { xxs: 3, md: 4 },
    mx: 'auto',
    width: 1,
    maxWidth: 600
  },
  ListItemWrapper: {
    mx: 'auto',
    width: 1,
    maxWidth: 600
  },
  ThumbImage: {
    height: { xxs: 30, md: 48 },
    width: { xxs: 30, md: 48 },
    borderRadius: '50%',
    overflow: 'hidden',
    mr: { xxs: 1, md: 2 },
    img: {
      height: 1,
      width: 1,
      objectFit: 'cover'
    }
  },
  TechnologiesText: {
    mt: { xxs: 1, md: 0 },
    display: 'flex',
    textAlign: 'right'
  },
  ListItemLeftPart: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  ListItemName: {
    display: 'flex',
    color: 'common.blackText',
    fontWeight: '500'
  },
  ListItem: {
    color: 'common.blackText',
    display: 'flex',

    justifyContent: 'space-between',
    mt: 3,
    p: { xxs: 1, md: 2 },
    border: '1px solid',
    borderColor: 'action.focus',
    borderRadius: 2
  }
};

export default Style;
