export default function IssueIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3412_23769)">
        <path
          d="M19.5 12V13.5C19.5 15.4891 18.7098 17.3968 17.3033 18.8033C15.8968 20.2098 13.9891 21 12 21C10.0109 21 8.10322 20.2098 6.6967 18.8033C5.29018 17.3968 4.5 15.4891 4.5 13.5V12H19.5Z"
          fill="#FCFDFF"
        />
        <path
          d="M14.6156 9.75C15.2369 9.75 15.7406 9.24632 15.7406 8.625C15.7406 8.00368 15.2369 7.5 14.6156 7.5C13.9943 7.5 13.4906 8.00368 13.4906 8.625C13.4906 9.24632 13.9943 9.75 14.6156 9.75Z"
          fill="#664CCD"
        />
        <path
          d="M9.3656 9.75C9.98692 9.75 10.4906 9.24632 10.4906 8.625C10.4906 8.00368 9.98692 7.5 9.3656 7.5C8.74428 7.5 8.2406 8.00368 8.2406 8.625C8.2406 9.24632 8.74428 9.75 9.3656 9.75Z"
          fill="#664CCD"
        />
        <path
          d="M12 12V21"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.875 12H1.125"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 13.5C19.5 15.4891 18.7098 17.3968 17.3033 18.8033C15.8968 20.2098 13.9891 21 12 21C10.0109 21 8.10322 20.2098 6.6967 18.8033C5.29018 17.3968 4.5 15.4891 4.5 13.5V10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5V13.5Z"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7407 17.625L19.05 16.0687"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7407 6.375L19.05 7.93125"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.2594 6.375L4.95002 7.93125"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.2594 17.625L4.95002 16.0687"
          stroke="#664CCD"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3412_23769">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
