import styled from '@emotion/styled';

export const SectionWrapper = styled.div`
  padding-top: 10vh;
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`;
export const SubscribeMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
  @media only screen and (max-width: 768px) {
    margin-top: 15%;
  }
  .SubscribeContent {
    width: 100vw;
    max-width: 1230px;
    transform: translateY(100px) scale(0.8);
    transition: ease all 1s;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
    .title {
      font-weight: 500;
      font-size: 64px;
      line-height: 84px;
      margin-bottom: 30px;
      position: relative;
      @media only screen and (max-width: 768px) {
        font-size: 34px;
        line-height: 44px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 42px;
      }
      @media only screen and (max-width: 420px) {
        font-size: 20px;
        line-height: 34px;
      }
      .titleShape {
        height: 145px;
        width: 145px;
        position: absolute;
        left: 600px;
        bottom: 75%;
        z-index: -1;
        padding: 5px 10px 10px 10px;
        background-image: radial-gradient(transparent, rgb(102 176 216 / 20%));
        border-radius: 50%;
        img {
          animation: rotation 20s infinite linear;
          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }
        }
        @media only screen and (max-width: 991px) {
          height: 125px;
          width: 125px;
        }
        @media only screen and (max-width: 768px) {
          left: 60%;
        }
      }
      .dynamicTitleAnimated {
        position: relative;
        .rotateKeyword {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          position: absolute;
          top: 0;
          left: 100%;
          animation: slide1 5s infinite;

          @keyframes slide1 {
            0% {
              top: 0;
            }
            25% {
              top: -1.4em;
            }
            /* 50% {
              top: -2.5em;
            } */
          }
          @media only screen and (max-width: 575px) {
            @keyframes slide1 {
              0% {
                top: 0;
              }
              25% {
                top: -1.8em;
              }
              /* 50% {
                top: -3.4em;
              } */
            }
          }
        }
      }
      .dynamicTitle {
        display: flex;
        position: relative;
        overflow: hidden;

        .design {
          background: linear-gradient(90deg, #ff802b 0%, #dd6311 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .development {
          background: linear-gradient(90deg, #2baaff 0%, #26479c 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .launch {
          background: linear-gradient(90deg, #ea2bff 0%, #c50cda 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
    .description {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .rocket {
    max-width: 25%;
    position: absolute;
    right: 0;
    opacity: 0.8;
    transition: ease all 2s;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;

export const DashboardMain = styled.div`
  position: relative;
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }
  .topSlide,
  .bottomSlide {
    display: flex;
    flex-wrap: wrap;
  }
  .text {
    width: 30%;
    padding-right: 15px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0 0 15px 0;
    }
  }
  .image {
    width: 70%;
    height: 70vh;
    max-height: 80vh;
    text-align: right;
    @media only screen and (max-width: 991px) {
      height: auto;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      max-height: 100%;
      width: 58vw;
      max-width: 850px;
      object-fit: contain;
      @media only screen and (max-width: 767px) {
        width: 100%;
        max-height: 25vh;
      }
    }
  }
  .topSlide {
    transform: scale(0.7);
    transform: translateY(400px);
    transition: ease all 3s;
    .text {
      padding-top: 5vh;
      @media only screen and (max-width: 767px) {
        padding-top: 15px;
      }
    }
  }
  .bottomSlide {
    position: absolute;
    top: 15%;
    align-items: center;
    @media only screen and (max-width: 767px) {
      position: static;
      transform: scale(0.7);
      transition: ease all 1s;
    }
    .text {
      padding-top: 5vh;
      @media only screen and (max-width: 767px) {
        padding-top: 15px;
      }
    }
    .image {
      img {
        max-width: 88%;
        object-fit: contain;
        @media only screen and (max-width: 767px) {
          max-width: 100%;
        }
      }
    }
  }

  .desktop {
    display: flex;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
    }
  }
`;
