import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as Style from './GetStarted.style';

import React, { useEffect } from 'react';
import Slider from 'react-slick';

import { ArrowLeft, ArrowRight } from '../../../assets/icons/ProcessArrow';
import Authentication from '../../../assets/icons/Authentication';
import { useWindowSize } from '../../../hooks/useWindowSize';
import GithubStandardIcon from '../../../assets/icons/GithubStandardIcon';
import ConfigureIcon from '../../../assets/icons/ConfigureIcon';
import DeploymentIcon from '../../../assets/icons/DeploymentIcon';

const GetStarted = () => {
  const [width, _] = useWindowSize();
  const [slideToShow, setSlideToShow] = React.useState(4);
  const data = [
    {
      icon: <Authentication className="icon" />,
      title: 'Authentication',
      description:
        'Once you onboard in the platform you can click on add project to create new project'
    },
    {
      icon: <GithubStandardIcon className="icon" />,
      title: 'Select repository',
      description:
        'Once you onboard in the platform you can click on add project to create new project'
    },
    {
      icon: <ConfigureIcon className="icon" />,
      title: 'Configure',
      description:
        'Once you onboard in the platform you can click on add project to create new project'
    },
    {
      icon: <DeploymentIcon className="icon" />,
      title: 'Deployment',
      description:
        'Once you onboard in the platform you can click on add project to create new project'
    }
  ];

  const CustomNextArrow = (props: any) => {
    const { onClick, style } = props;
    return <ArrowRight className="processArrowRight" style={style} onClick={onClick} />;
  };
  const CustomPrevArrow = (props: any) => {
    const { onClick, style } = props;
    return <ArrowLeft className="processArrowLeft" style={style} onClick={onClick} />;
  };

  useEffect(() => {
    if (width >= 1200) {
      setSlideToShow(4);
    } else if (width < 1200 && width >= 992) {
      setSlideToShow(3);
    } else if (width < 992 && width >= 768) {
      setSlideToShow(2);
    } else {
      setSlideToShow(1);
    }
  }, [width]);

  return (
    <Style.GetStartedWrapper>
      <div className="container">
        <h1>Quick four step deployment</h1>
        <div className="cardWrapper">
          <Slider
            dots={false}
            infinite={false}
            slidesToShow={slideToShow}
            slidesToScroll={1}
            nextArrow={<CustomNextArrow />}
            prevArrow={<CustomPrevArrow />}>
            {data.map((item, index) => (
              <>
                <div className="card" key={index}>
                  <div className="iconWrapper">{item.icon}</div>
                  <p className="title">{item.title}</p>
                  <p className="description">{item.description}</p>
                </div>
                <div className="stepContainer">
                  <div className={`lineLeft ${(index === 0 || slideToShow < 2) && 'hide'}`}></div>
                  <div className="centerDot">
                    <div className="dot"></div>
                  </div>
                  <p>Step {index + 1}</p>
                  <div className={`lineRight ${(index === 3 || slideToShow < 2) && 'hide'}`}></div>
                </div>
              </>
            ))}
          </Slider>
        </div>
      </div>
    </Style.GetStartedWrapper>
  );
};

export default GetStarted;
