import React from 'react';

const GithubStandardIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="45"
      viewBox="0 0 47 45"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 0C10.5162 0 0 10.3241 0 23.0706C0 33.265 6.72687 41.902 16.0681 44.9589C17.2431 45.1752 17.6103 44.483 17.6103 43.8774C17.6103 43.3295 17.6103 41.8011 17.6103 39.8689C11.0744 41.2676 9.72312 36.8265 9.72312 36.8265C8.65094 34.1589 7.10875 33.4524 7.10875 33.4524C4.97906 32.0249 7.27031 32.0537 7.27031 32.0537C9.635 32.2124 10.8688 34.4329 10.8688 34.4329C12.9691 37.9512 16.3619 36.9418 17.6984 36.3507C17.9188 34.8655 18.5209 33.8417 19.1966 33.265C13.9825 32.6882 8.48938 30.6984 8.48938 21.8594C8.48938 19.3361 9.4 17.2885 10.9128 15.6736C10.6631 15.0968 9.85531 12.7465 11.1478 9.57431C11.1478 9.57431 13.1159 8.95429 17.6103 11.939C19.4903 11.42 21.5025 11.1748 23.5 11.1604C25.4975 11.1604 27.5097 11.42 29.3897 11.939C33.8841 8.95429 35.8522 9.57431 35.8522 9.57431C37.13 12.7465 36.3222 15.0968 36.0872 15.6736C37.5853 17.2885 38.4959 19.3505 38.4959 21.8594C38.4959 30.7272 33.0028 32.6738 27.7741 33.2361C28.6112 33.9571 29.375 35.3269 29.375 37.4898V43.8774C29.375 44.4975 29.7569 45.1752 30.9466 44.9589C40.2731 41.902 47 33.265 47 23.0706C46.9853 10.3241 36.4691 0 23.5 0Z"
        fill="white"
      />
    </svg>
  );
};

export default GithubStandardIcon;
