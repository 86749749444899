import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Alert,
  Collapse,
  IconButton,
  Skeleton
} from '@mui/material';
import { Container } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { PageRoutes } from '../../utils/constants';

import { frameWork, language } from '../../utils/language';
import Style from './BuildNewProject.style';
import { ProjectService } from '../../services/API/ProjectService';
import ReactJsIcon from '../../assets/icons/ReactJsIcon';
import { BuildService } from '../../services/API/BuildService';
import { DeployService } from '../../services/API/DeployService';
import { notifyError } from '../../services/toster';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { DetectService } from '../../services/API/DetectService';
import { useDispatch } from 'react-redux';
import { setRepositoryDetect } from '../../store/reducers/configureProjectReducer';
import {
  BuildFlagEnum,
  BuildServiceBranchConfigRequest,
  DeployServiceBranchConfigRequest,
  DetectResponse,
  EnvVars,
  PortConfig
} from '../../models';
import CreateProject from '../../assets/images/createProject.gif';
import CloseIcon from '../../assets/icons/Close';

const BuildNewProject: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('configure-project');
  const projectService = new ProjectService();
  const buildAPIService = new BuildService();
  const deployAPIService = new DeployService();
  const detectAPIService = new DetectService();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isGithubAppInstall, setIsGithubAppInstall] = useState(true);
  const [isGithubAppInstallInstruction, setIsGithubAppInstallInstruction] = useState(true);

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '200px'
      }
    }
  };
  const [projectDetail, setProjectDetail] = useState({
    Name: '',
    Skelton: 'ReactSkeleton'
  });
  const [frameWorkList, setFrameWorkList] = useState<
    {
      name: string;
      value: string;
      logo: any;
    }[]
  >([
    {
      name: 'React',
      value: 'ReactSkeleton',
      logo: <ReactJsIcon />
    }
  ]);
  const [isShow, setIsShow] = useState(false);
  const [createNewProjectDetails, setCreateNewProjectDetails] = useState<any>('');
  const [projectNameError, setProjectNameError] = useState('');

  useEffect(() => {
    GetTechnologies();
  }, []);

  const GetTechnologies = async () => {
    setIsSkeleton(true);
    const list = await projectService.GetProjectTechnologies();
    const menuList: {
      name: string;
      value: string;
      logo: any;
    }[] = [];

    list.map((menu) => {
      const val = frameWork.find((x) => x.value === menu.value);
      if (val) {
        menuList.push({
          name: val?.languageName,
          logo: val?.logo,
          value: menu.value
        });
      }
    });
    setIsSkeleton(false);
    setFrameWorkList(menuList);
  };

  const handleChanges = (name: string, val: string) => {
    setProjectDetail({
      ...projectDetail,
      [name]: val
    });
    if (name === 'Name') {
      setProjectNameError('');
    }
  };

  const handleCreateProject = async () => {
    if (!projectDetail.Name) {
      setProjectNameError('This field is required');
      return;
    }
    const reg = new RegExp('^[a-zA-Z0-9-]{1,255}$');
    if (!reg.test(projectDetail.Name)) {
      setProjectNameError('Enter a proper repository project name');
      return;
    }
    setIsShow(true);

    try {
      setIsGithubAppInstall(true);
      setIsGithubAppInstallInstruction(true);
      setIsSkeleton(true);
      const response = await projectService.CreateSkeletonProject({
        Skelton: projectDetail.Skelton,
        Name: projectDetail.Name
      });

      setCreateNewProjectDetails(response);
      if (response?.Name) {
        await handleConfigureClick(response);
      }
    } catch (error) {
      setIsGithubAppInstallInstruction(false);
      setIsGithubAppInstall(false);
    } finally {
      setIsSkeleton(false);
      setIsShow(false);
    }
  };

  const handleConfigureClick = async (values: any) => {
    // Check for project name
    if (values.errorProjectName) {
      setIsShow(false);
      notifyError(values.errorProjectName);
      return;
    }
    setIsShow(true);

    gaEventTracker('creating_new_project');
    let rawUrl = values.Source?.replace(/(^\w+:|^)\/\//, '');

    const response = await projectService.CreateProject({
      Source: rawUrl || '',
      Name: values.Name
    });

    if (response?.ID) {
      const result = await getDetection(response.Name, values.Default_Branch);
      let buildRequest = handleSetBuildRequest(result, values.Default_Branch);
      let deployRequest = handleSetDeployRequest(result, values.Default_Branch);

      try {
        await buildAPIService.CreateBranchConfig(buildRequest, searchParams.get('alias'));
        await deployAPIService.CreateBranchConfig(deployRequest, searchParams.get('alias'));
        setTimeout(() => {
          navigate({
            pathname: PageRoutes.RepositoryDetails.replace(':id', response.Name),
            search: createSearchParams({ branch: buildRequest.Branch, isNew: 'true' }).toString()
          });
          setIsShow(false);
        }, 500);
      } catch (error) {
        notifyError('Error while creating configuration');
        navigate(PageRoutes.Repository);
      }
    } else {
      setIsShow(false);
    }
  };

  async function getDetection(projectName: string, branchName: string) {
    let response = await detectAPIService.DetectByBranch(
      projectName,
      branchName,
      searchParams.get('alias')
    );
    dispatch(setRepositoryDetect(response));
    return response;
  }

  function handleSetBuildRequest(data: DetectResponse, branchName: string) {
    let buildRequest = {} as BuildServiceBranchConfigRequest;
    buildRequest.Branch = branchName;
    buildRequest.Project = projectDetail.Name;
    buildRequest.Context = data.build?.context;
    buildRequest.Dockerfile = data.build?.instructions_file;
    buildRequest.Language = data.language?.toLocaleLowerCase();
    buildRequest.BuildFlag = BuildFlagEnum.DOCKERFILE;
    buildRequest.IsAutoDeploy = true;

    return buildRequest;
  }

  function handleSetDeployRequest(data: DetectResponse, branchName: string) {
    let deployRequest = {} as DeployServiceBranchConfigRequest;
    deployRequest.Branch = branchName;
    deployRequest.Project = projectDetail.Name;

    if (data?.env) {
      let envs: EnvVars[] = [];
      Object.keys(data.env).forEach((key) => {
        envs.push({
          Key: key,
          Value: data.env[key]
        });
      });
      deployRequest.Env = envs;
    }
    if (data?.ports) {
      let ports: PortConfig[] = [];
      Object.keys(data.ports).forEach((key) => {
        ports.push({
          Port: parseInt(key),
          Protocol: data.ports[key],
          HealthCheck: '/'
        });
      });
      deployRequest.Ports = ports;
    }
    return deployRequest;
  }

  return (
    <Container maxWidth={'xl'}>
      <Box sx={Style.BuildNewProjectWrapper}>
        {isShow ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5 }}>
            <Typography variant="h1">We are building your project</Typography>
            <img src={CreateProject} alt="Create Project" />
          </Box>
        ) : (
          <>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <Typography variant="h1" sx={{ fontWeight: 500, color: '#1E1E1E' }}>
                Build a Project
              </Typography>
            </Box>
            <Box sx={{ maxWidth: 600, width: 1, m: 'auto' }}>
              <Collapse in={!isGithubAppInstall && !isSkeleton}>
                <Alert
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsGithubAppInstall(true);
                      }}>
                      {' '}
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}>
                  <Typography>
                    Its look like you have not install the github app yet. Please{' '}
                    <a
                      style={{
                        fontWeight: 500,
                        cursor: 'pointer',
                        textDecoration: 'underLine',
                        color: 'inherit'
                      }}
                      href={`https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new?state=bnd-prod&redirect_uri=${window.location.href}`}>
                      install github apps.
                    </a>
                  </Typography>
                </Alert>
              </Collapse>
            </Box>

            <Box sx={{ maxWidth: 600, width: 1, m: 'auto' }}>
              <Collapse in={!isGithubAppInstallInstruction && !isSkeleton}>
                <Alert
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsGithubAppInstallInstruction(true);
                      }}>
                      {' '}
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}>
                  <Typography>
                    If issue still occurs after installation please uninstall and install again. for{' '}
                    <Link to={PageRoutes.GithubAppInstallation}>more info. </Link>
                  </Typography>
                </Alert>
              </Collapse>
            </Box>

            {!isSkeleton && (
              <>
                <Grid container spacing={3} sx={{ my: 6 }}>
                  <Grid item xs={12} md={6} sx={{ width: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Project Name"
                      fullWidth
                      placeholder="Project Name"
                      id="ProjectName"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ProjectName'
                      }}
                      value={projectDetail.Name}
                      error={
                        projectNameError == '' ? (projectDetail.Name === '' ? true : false) : true
                      }
                      helperText={
                        projectDetail.Name === '' ? 'This field is required' : projectNameError
                      }
                      onChange={(event) => handleChanges('Name', event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ width: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel id="framework-select-label">Framework</InputLabel>
                      <Select
                        labelId="framework-select-label"
                        variant="outlined"
                        label="Framework"
                        defaultValue={frameWorkList[0]?.value}
                        value={projectDetail.Skelton}
                        sx={{
                          '.MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center'
                          }
                        }}
                        onChange={(event: any) => handleChanges('Skelton', event.target.value)}
                        displayEmpty
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        {frameWorkList.map((Element, index) => (
                          <MenuItem key={index + 1} sx={Style.ProjectList} value={Element.value}>
                            <Box sx={Style.ProjectListImage}>{Element.logo}</Box>
                            <Box sx={{ ml: 1.5 }}>{Element.name}</Box>
                          </MenuItem>
                        ))}

                        {/* <MenuItem sx={Style.ProjectList} value={'vuejs'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  VueJs
                </MenuItem>
                <MenuItem sx={Style.ProjectList} value={'port'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  Rect
                </MenuItem>
                <MenuItem sx={Style.ProjectList} value={'wordpress'}>
                  <Box sx={Style.ProjectListImage}>
                    <img src={Github} alt="thumb" />
                  </Box>
                  WordPress
                </MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
                  <Button
                    onClick={() => navigate(PageRoutes.SelectProjectType)}
                    sx={{
                      py: 1.5,
                      px: 4,
                      fontSize: '16px',
                      color: '#6B6B6B',
                      border: '1px solid #DADADA'
                    }}
                    variant="outlined">
                    Back
                  </Button>
                  <Button
                    sx={{ py: 1.5, px: 4, fontSize: '16px' }}
                    variant="contained"
                    color="secondary"
                    onClick={handleCreateProject}>
                    Create Project
                  </Button>
                </Box>
              </>
            )}
            {isSkeleton && (
              <Grid item xs={12} md={6} sx={{ width: 1 }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  sx={{ borderRadius: '4px' }}
                  height={60}
                />
              </Grid>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};
export default BuildNewProject;
