import { Box, Tooltip, Typography, IconButton } from '@mui/material';
import React from 'react';
import InfoCircleIcon from '../../../assets/icons/InfoCircleIcon';

interface ITooltipWrapper {
  infoText: Array<string>;
  icon?: React.ReactNode;
}

const TooltipWrapper = ({ infoText, icon }: ITooltipWrapper) => {
  return (
    <Tooltip
      placement="right"
      disableHoverListener={!infoText.length}
      title={
        <Box display={'flex'} flexDirection={'column'} p={1} gap={2}>
          {infoText.map((text, index) => (
            <Typography fontSize={12} key={index}>
              {text}
            </Typography>
          ))}
        </Box>
      }>
      <IconButton sx={{ margin: '0 8px' }}>{icon ? icon : <InfoCircleIcon />}</IconButton>
    </Tooltip>
  );
};

export default TooltipWrapper;
