import { CSSProperties, FC, useEffect } from 'react';

import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { Box, IconButton, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Carousel } from 'react-responsive-carousel';
import Github from '../../assets/images/github.svg';
import Bitbucket from '../../assets/images/bitbucket.svg';
import Gitlab from '../../assets/images/gitlab.svg';
import { PageRoutes } from '../../utils/constants';
import Logo from '../../assets/images/bndwight.svg';
import SelectRepo from '../../assets/images/SelectRepo.svg';
import Project from '../../assets/images/Project.svg';
import Deploy from '../../assets/images/Deploy.svg';
import RepoPage from '../../assets/images/RepoPage.png';
import ProjectDetailsPage from '../../assets/images/ProjectDetailsPage.png';
import { authDetailsReducer, getAuthState, setAuthDetails } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Style from './Login.styles';
import { makeid } from '../../utils/commonfunction';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { textAlign } from '@mui/system';
import './Login.css';
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Login: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('landing_page');
  const dispatch = useDispatch();
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();

  function setAuth(data: authDetailsReducer) {
    localStorage.setItem('auth', JSON.stringify(data));

    dispatch(setAuthDetails(data));
  }

  const handleGithubSignUp = () => {
    gaEventTracker('authentication', 'sign_in / sign_up');
    const randomState = makeid(25);
    const env = process.env;
    setAuth({ state: randomState } as authDetailsReducer);
    location.href = `${env.REACT_APP_GITHUB_APP_GITHUB_URL}?client_id=${env.REACT_APP_GITHUB_APP_CLIENT_ID}&state=${randomState}`;
  };
  const indicatorStyles: CSSProperties = {
    position: 'relative',
    background: '#FCFDFF',
    opacity: '0.1',
    width: '29%',
    height: 8,
    textAlign: 'start',
    borderRadius: '0.5rem',
    display: 'inline-block'
  };

  useEffect(() => {
    // logic to disable isVscodelogin just in case
    setAuth({ ...auth, isVscodeLogin: false });
    gaEventTracker('total_visitors ', 'page_default_visitors');
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            width: 1,
            m: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xxs: 'column', md: 'row' },
            justifyContent: 'space-between'
          }}>
          <Box
            sx={{
              width: { xxs: '100%', sm: '60%', md: '35%' },
              position: 'relative',
              mx: 'auto',
              borderRadius: '16px',
              background: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)',
              px: 4,
              py: 2
            }}>
            <Box>
              <Box>
                <Box>
                  <Carousel
                    autoPlay
                    infiniteLoop
                    swipeable
                    emulateTouch
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    useKeyboardArrows
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                      if (isSelected) {
                        return (
                          <li
                            style={{ ...indicatorStyles, background: '#FCFDFF', opacity: '0.8' }}
                            aria-label={`Selected: ${label} ${index + 1}`}
                            title={`Selected: ${label} ${index + 1}`}
                          />
                        );
                      }
                      return (
                        <li
                          style={indicatorStyles}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          title={`${label} ${index + 1}`}
                          aria-label={`${label} ${index + 1}`}
                        />
                      );
                    }}>
                    <div style={{ borderRadius: '8px' }}>
                      <Box>
                        <Typography sx={Style.LoginSliderHeader}>
                          Built with Care, Deployed with Precision
                        </Typography>
                        <Typography variant="h6" sx={Style.LoginSliderSubTitle}>
                          {`Discover the world's best deployment channel for developers.`}
                        </Typography>
                      </Box>
                      <img src={SelectRepo} />
                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '16px',
                          margin: '0px',

                          fontWeight: 400,

                          marginBottom: '10px',
                          marginTop: '10px',
                          textAlign: 'start'
                        }}>
                        Step 1
                      </p>
                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '18px',
                          margin: '0px',
                          marginBottom: '50px',
                          fontWeight: 500,

                          textAlign: 'start'
                        }}>
                        Select Repository
                      </p>
                    </div>
                    <div>
                      <Box>
                        <Typography variant="h1" sx={Style.LoginSliderHeader}>
                          Smoothly Sail Your Project to Success
                        </Typography>
                        <Typography variant="h6" sx={Style.LoginSliderSubTitle}>
                          Streamlining Your Continuous Integration and Delivery Process
                        </Typography>
                      </Box>
                      <img src={Project} />
                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '16px',
                          margin: '0px',

                          fontWeight: 400,

                          marginBottom: '10px',
                          marginTop: '10px',
                          textAlign: 'start'
                        }}>
                        Step 2
                      </p>
                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '18px',
                          margin: '0px',
                          marginBottom: '50px',
                          fontWeight: 500,

                          textAlign: 'start'
                        }}>
                        Configure Project
                      </p>
                    </div>
                    <div>
                      <Box>
                        <Typography variant="h1" sx={Style.LoginSliderHeader}>
                          Simplify Your Project Deployment Process
                        </Typography>
                        <Typography variant="h6" sx={Style.LoginSliderSubTitle}>
                          Seamless CI/CD Integration for Effortless Delivery
                        </Typography>
                      </Box>
                      <img src={Deploy} />

                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '16px',

                          fontWeight: 400,
                          margin: '0',
                          marginBottom: '10px',
                          marginTop: '10px',
                          textAlign: 'start'
                        }}>
                        Step 3
                      </p>
                      <p
                        style={{
                          color: '#FFFFFF',
                          fontSize: '18px',
                          margin: '0px',
                          marginBottom: '50px',
                          fontWeight: 500,

                          textAlign: 'start'
                        }}>
                        Build & Deploy
                      </p>
                    </div>
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xxs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              mt: { xxs: 10, md: 0 },
              alignItems: 'center'
            }}>
            <Box
              onClick={() => {
                navigate(PageRoutes.LandingPage);
              }}
              sx={{ cursor: 'pointer' }}>
              <img style={{ height: '87px', width: '87px' }} src={Logo} alt="logo" />
            </Box>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Typography variant="h1" sx={{ fontWeight: 500, color: '#1E1E1E' }}>
                Sign in
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mt: 4 }}>
              <Box sx={{ textAlign: 'center' }}>
                <Button onClick={handleGithubSignUp} sx={Style.SignUpBox}>
                  <img src={Github} alt="github" />
                </Button>
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <Tooltip title={'Coming soon'}>
                  <span>
                    <IconButton disabled sx={Style.SignUpBox}>
                      <img height={'24px'} src={Gitlab} alt="Bitbucket" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <Tooltip title={'Coming soon'}>
                  <span>
                    <IconButton disabled sx={Style.SignUpBox}>
                      <img height={'24px'} src={Bitbucket} alt="Bitbucket" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
