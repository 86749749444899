import { SvgIcon } from '@mui/material';

export default function WorldIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.3327 16.0003C29.3327 8.64033 23.3593 2.66699 15.9993 2.66699C8.63935 2.66699 2.66602 8.64033 2.66602 16.0003C2.66602 23.3603 8.63935 29.3337 15.9993 29.3337"
        stroke="url(#paint0_linear_3738_2219)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6655 4.00049H11.9988C9.39883 11.7872 9.39883 20.2138 11.9988 28.0005H10.6655"
        stroke="url(#paint1_linear_3738_2219)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.999 4.00049C21.2924 7.89382 21.9457 11.9472 21.9457 16.0005"
        stroke="url(#paint2_linear_3738_2219)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99902 21.3338V20.0005C7.89236 21.2938 11.9457 21.9472 15.999 21.9472"
        stroke="url(#paint3_linear_3738_2219)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99902 12.0003C11.7857 9.40029 20.2124 9.40029 27.999 12.0003"
        stroke="url(#paint4_linear_3738_2219)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.614 20.9867L20.894 25.7067C20.7074 25.8934 20.534 26.24 20.494 26.4934L20.2407 28.2933C20.1474 28.9467 20.6007 29.4 21.254 29.3067L23.054 29.0533C23.3074 29.0133 23.6674 28.84 23.8407 28.6534L28.5607 23.9334C29.374 23.1201 29.7607 22.1733 28.5607 20.9733C27.374 19.7867 26.4274 20.1734 25.614 20.9867Z"
        stroke="url(#paint5_linear_3738_2219)"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9346 21.6665C25.3346 23.1065 26.4546 24.2265 27.8946 24.6265"
        stroke="url(#paint6_linear_3738_2219)"
        strokeWidth="1.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3738_2219"
          x1="2.66602"
          y1="16.0003"
          x2="29.3327"
          y2="16.0003"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3738_2219"
          x1="10.0488"
          y1="16.0005"
          x2="11.9988"
          y2="16.0005"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3738_2219"
          x1="19.999"
          y1="10.0005"
          x2="21.9457"
          y2="10.0005"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3738_2219"
          x1="3.99902"
          y1="20.9738"
          x2="15.999"
          y2="20.9738"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3738_2219"
          x1="3.99902"
          y1="11.0253"
          x2="27.999"
          y2="11.0253"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3738_2219"
          x1="20.2285"
          y1="24.7708"
          x2="29.3225"
          y2="24.7708"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3738_2219"
          x1="24.9346"
          y1="23.1465"
          x2="27.8946"
          y2="23.1465"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
