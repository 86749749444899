import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FC } from 'react';
import './index.scss';
interface LoaderInterfact {
  isShow: boolean;
}

export const Loader: FC<LoaderInterfact> = (props) => {
  return (
    <>
      {props.isShow && (
        <>
          <div id="loader">
            <div id="box"></div>
            <div id="hill"></div>
          </div>
          <div>
            <div className="loading loading01">
              <span>L</span>
              <span>o</span>
              <span>a</span>
              <span>d</span>
              <span>i</span>
              <span>n</span>
              <span>g</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Loader;
