import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Scroll from 'react-scroll';
import AddNewBranchDoc from '../../../assets/images/AddNewBranchDoc.png';
import DashBoardDoc from '../../../assets/images/DashBoardDoc.png';
import RepositoryPageDoc from '../../../assets/images/RepositoryPageDoc.png';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';
import { CopyBlock, dracula, atomOneDark, CodeBlock } from 'react-code-blocks';
import { Theme } from 'react-code-blocks/dist/types';
import { Link } from '@mui/material';

const GithubAppInstallationInstruction: FC = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  var theme: Theme = { ...dracula, mode: 'dark' };
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '28px', md: '32px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Uninstalling and Reinstalling GitHub Apps - A Step-by-Step Guide
        </Typography>
        <Box sx={{ mt: 6 }}>
          <Typography sx={{ textAlign: 'justify' }}>
            If you want to uninstall and re-install BnD GitHub App from your account, follow these
            simple steps
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ textAlign: 'justify' }}>
            1. Uninstalling the GitHub App <br></br> <br></br>
            a. Go to the following link:{' '}
            <a href="https://github.com/settings/installations" rel="noreferrer" target="_blank">
              Link
            </a>
            <br></br>
            b. Locate the <b>bnd-prod</b> app from the list of installed apps. <br></br>
            c. Click on the <b>Configure</b> button next to the <b>bnd-prod</b> app. <br></br>
            d. On the app configuration page, scroll down and find the Danger Zone section.{' '}
            <br></br>
            e. Click on the <b>Uninstall</b> button to remove the <b>bnd-prod</b> app from your
            account. f. Confirm the uninstallation when prompted.
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ textAlign: 'justify' }}>
            1. Reinstalling the GitHub App <br></br> <br></br>
            a. After uninstalling the app, you can reinstall click on this link:{' '}
            <a
              href="https://github.com/apps/bnd-prod/installations/select_target?state=bnd-prod"
              rel="noreferrer"
              target="_blank">
              Link
            </a>
            <br></br>
            b. Select your GitHub account from the list of accounts provided. <br></br>
            c. Choose the access level you want to grant the app, depending on your needs. <br></br>
            d. Follow any additional instructions that may be presented during the installation
            process. <br></br>
            e. Click on the <b>Uninstall</b> button to remove the <b>bnd-prod</b> app from your
            account. f. Confirm the uninstallation when prompted.
            <br></br>
            <br></br>
            Thats it! You have now successfully uninstalled and re-installed the <b>
              bnd-prod
            </b>{' '}
            GitHub App. If you encounter any issues during this process, please do not hesitate to
            contact us on email <b>hello@buildndeploy.co </b> for assistance. We are more than happy
            to help you with any problems you may face.
          </Typography>
        </Box>
        <Box
          className={'upnext'}
          onClick={() => navigate(PageRoutes.Login)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Start using BnD{' '}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>We will deploy the first build</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default GithubAppInstallationInstruction;
