import SvgIcon from '@mui/material/SvgIcon';

export default function DownloadIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0007 11.9998V2.6665L13.334 5.33317"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2.6665L18.6667 5.33317"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64062 17.3335H8.52062C9.02729 17.3335 9.48062 17.6135 9.70729 18.0668L11.2673 21.1868C11.7206 22.0935 12.6406 22.6668 13.654 22.6668H18.3606C19.374 22.6668 20.294 22.0935 20.7473 21.1868L22.3073 18.0668C22.534 17.6135 23.0006 17.3335 23.494 17.3335H29.3073"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33268 6.83984C4.61268 7.53318 2.66602 10.3065 2.66602 15.9998V19.9998C2.66602 26.6665 5.33268 29.3332 11.9993 29.3332H19.9993C26.666 29.3332 29.3327 26.6665 29.3327 19.9998V15.9998C29.3327 10.3065 27.386 7.53318 22.666 6.83984"
        stroke="#664CCD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
