import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Scroll from 'react-scroll';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';
import { CopyBlock, dracula } from 'react-code-blocks';

const DockerFileInstruction = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  var theme = { ...dracula, mode: 'dark' };
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Dockerfile Instruction
        </Typography>
        <Box>
          <Typography>
            If your project doesnt have a Dockerfile, dont worry! Here are some examples of
            Dockerfile for you to use.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'dashboard'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Node JS
          </Typography>
          <CopyBlock
            language="bash"
            text={`
            # You can abjust the node version
            FROM node:16

            # Create app directory
            WORKDIR /usr/src/app
            
            # Install app dependencies
            # A wildcard is used to ensure both package.json AND package-lock.json are copied
            # where available (npm@5+)
            COPY package*.json ./
            
            RUN npm install
            # If you are building your code for production
            # RUN npm ci --only=production
            
            # Bundle app source
            COPY . .
            
            EXPOSE 80
            
            CMD [ "node", "index.js" ]`}
            showLineNumbers={false}
            codeBlock
            theme={theme}
            copied={false}
            wrapLongLines={false}
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'dashboard'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            React JS / Angular Js / Next Js
          </Typography>

          <CopyBlock
            language={'bash'}
            text={`FROM node
            WORKDIR /src
            COPY . ./
            RUN npm install -f && npm run build
            
            FROM nginx:stable-alpine
            COPY --from=0 /src/build /usr/share/nginx/html
            COPY nginx.conf /etc/nginx/nginx.conf
            EXPOSE 80
            CMD ["nginx", "-g", "daemon off;"]`}
            showLineNumbers={false}
            codeBlock
            theme={theme}
            copied={false}
            wrapLongLines={false}
          />
          <Typography sx={{ textAlign: 'justify' }}>
            Including the NGINX configuration file in the root directory when deploying a frontend
            application with Docker is necessary because it enables the setup of the NGINX web
            server within the container. This file allows customization, optimization, load
            balancing, SSL/TLS termination, and serves as a crucial component for hosting and
            accessing the frontend application.
          </Typography>
          <CopyBlock
            language={'bash'}
            text={`user  nginx;
            worker_processes  auto;
            error_log  /var/log/nginx/error.log warn;
            pid        /var/run/nginx.pid;
            events {
              worker_connections  1024;
            }
            http {
              include       /etc/nginx/mime.types;
              default_type  application/octet-stream;
              log_format  main  '$remote_addr - $remote_user [$time_local] "$request" '
              '$status $body_bytes_sent "$http_referer" '
              '"$http_user_agent" "$http_x_forwarded_for"';
              access_log  /var/log/nginx/access.log  main;
              sendfile        on;
              #tcp_nopush     on;
              
              keepalive_timeout  65;
              #gzip  on;
              #include /etc/nginx/conf.d/*.conf;
            server {
              listen 80;
              location / {
                root   /usr/share/nginx/html;
                index  index.html index.htm;
                try_files $uri $uri/ /index.html;
              }
            }
            }`}
            showLineNumbers={false}
            codeBlock
            theme={theme}
            copied={false}
            wrapLongLines={false}
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'dashboard'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Golang
          </Typography>
          <CopyBlock
            language="bash"
            text={`
            FROM golang

            WORKDIR /src
            
            COPY go.mod .
            RUN go mod download
            
            COPY . .
            
            EXPOSE 80/tcp
            
            CMD [ "go","run", "main.go" ]`}
            showLineNumbers={false}
            codeBlock
            theme={theme}
            copied={false}
            wrapLongLines={false}
          />
        </Box>
        <Box
          className={'upnext'}
          onClick={() => navigate(PageRoutes.Login)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Start using BnD{' '}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>We will deploy the first build</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default DockerFileInstruction;
