import PlanetImage from '../../../assets/images/planet.svg';
import Logo from '../../../assets/images/bnd.svg';
import SubscribeForm from '../../../components/SubscribeForm';
import * as Style from './Notified.style';
import React from 'react';
import { motion, useInView } from 'framer-motion';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { Box, Link, Typography } from '@mui/material';
import Feedback from '../../shared/Feedback';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../../utils/constants';

const Notified = () => {
  const ref = React.useRef(null);
  const navigate = useNavigate();
  const isInView = useInView(ref, { once: false, amount: 'some' });
  const url = process.env.REACT_APP_MAILCHIMP_URL || '';

  const variants = {
    start: { scale: 1, transition: { duration: 0, delay: 0 } },
    stop: { scale: 0.9, transition: { duration: 0 } }
  };

  return (
    <Style.SectionWrapper>
      <div className="sectionContent">
        <div className="notifyForm">
          <div className="logoImage" ref={ref}>
            <img src={Logo} alt="planet" />
          </div>
          <div className="title">Stay tuned and never miss our updates</div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <SubscribeForm
                status={status}
                message={message}
                onValidated={(formData: EmailFormFields) => subscribe(formData)}
              />
            )}
          />
          {/* <SocialIcons /> */}
        </div>
      </div>
      <div className="sectionImage" ref={ref}>
        <motion.div className="image" variants={variants} animate={isInView ? 'start' : 'stop'}>
          <img src={PlanetImage} alt="planet" />
        </motion.div>
      </div>
      <div className="footer">
        <div className="footerBlock">
          <div className="footerMenu">
            <Link
              sx={{ textDecoration: 'none', color: 'common.white' }}
              onClick={() => navigate(PageRoutes.PrivacyPolicy)}>
              Privacy Policy
            </Link>
          </div>
          <div className="footerMenu">
            <Link
              sx={{ textDecoration: 'none', color: 'common.white' }}
              onClick={() => navigate(PageRoutes.TermAndCondition)}>
              Terms & Conditions
            </Link>
          </div>
          <Feedback>
            <div className="footerMenu">Feedback</div>
          </Feedback>
          <div className="footerMenu">
            <Link
              sx={{ textDecoration: 'none', color: 'common.white' }}
              href={'mailto:hello@buildndeploy.co'}>
              Contact us
            </Link>
          </div>
        </div>
        <Box sx={{ mt: 1.5, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="caption">{`© 2023 Build 'n Deploy`}</Typography>
        </Box>
      </div>
    </Style.SectionWrapper>
  );
};

export default Notified;
