import { Framework } from '../pages/DetectingLogs';

export type LoginRequest = {
  code: string;
  state: string;
};

export type LoginUserDetails = {
  ID: string;
  Name: string;
  Alias: string;
  AvatarURL: string;
  Provider: string;
};

export enum BuildFlagEnum {
  'DOCKERFILE' = 1,
  'INLINE' = 2,
  'COMMANDS' = 3
}

export type RepositoryListItem = {
  name: string;
  full_name?: string;
  html_url?: string;
  description: string;
  private: boolean;
  technologies?: string;
  technologiesColor?: string;
  clone_url: string;
  default_branch: string;
  configured: boolean;
};

export type CreateProjectRequest = {
  Name: string;
  Source: string;
};

export type CreateSkeletonProjectRequest = {
  Name: string;
  Skelton: string;
};

export type ProjectListResponse = {
  ID: string;
  AccountID: string;
  Name: string;
  Source: string;
  UpdatedAt: Date;
  Alias: string;
};

export type PaginationHelper = {
  totalPages: number;
  totalCount: number;
  currentPage: number;
  pageSize: number;
};

export type Project = {
  ID: string;
  AccountID: string;
  Name: string;
  Source: string;
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type DetectRequest = {
  ns: string;
  repository: string;
};

export type Build = {
  dockerfile: string;
  context: string;
  instructions: string;
  instructions_file: string;
};

export type DetectResponse = {
  branch: string;
  commit: string;
  language: string;
  dependencies: string;
  build: Build;
  env: any;
  ports: any;
};

export type BuildConfig = {
  Image: string;
  Dockerfile: string;
  Context: string;
};

export type BranchConfigRequest = {
  Branch: string;
  Build: any;
  Env: EnvVars[];
  Ports: PortConfig[];
};

export type EnvVars = {
  Key: string;
  Value: string;
};

export type PortConfig = {
  Port: number | string;
  Protocol: string;
  HealthCheck: string;
};

export type BranchConfigResponse = {
  ID: string;
  ProjectID: string;
  Branch: string;
  Build: any;
  Env: EnvVars[];
  Ports: PortConfig[];
  Domains: [];
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type DeployServiceBranchConfigResponse = {
  Namespace: string;
  Project: string;
  Branch: string;
  Env: EnvVars[];
  Ports: PortConfig[];
  CustomDomains: CustomDomainsConfig[];
  FQDNs: string[];
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type DeployServiceBranchConfigRequest = {
  Namespace: string;
  Project: string;
  Branch: string;
  Env: EnvVars[];
  Ports: PortConfig[];
  FQDNs: string[];
  CustomDomains: CustomDomainsConfig[];
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type BuildServiceBranchConfigResponse = {
  Namespace: string;
  Project: string;
  Branch: string;
  Dockerfile: string;
  DockerfileContent: string;
  IsAutoDeploy: boolean;
  BuildFlag: BuildFlagEnum;
  Framework: Framework;
  Context: string;
  Language: string;
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type BuildServiceBranchConfigRequest = {
  Namespace: string;
  Project: string;
  Branch: string;
  Dockerfile: string;
  Context: string;
  DockerfileContent: string;
  IsAutoDeploy: boolean;
  BuildFlag: BuildFlagEnum;
  Framework: Framework;
  Language: string;
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type BuildResponse = {
  ID: string;
  JobID: string;
  Namespace: string;
  Project: string;
  Branch: string;
  Commit: string;
  Artifact: string;
  Author: string;
  AuthorAvatarURL: string;
  Description: string;
  Status: string;
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type TaskState = {
  State: string;
  Failed: boolean;
  Restarts: number;
  LastRestart: Date;
  StartedAt: Date;
  FinishedAt: Date;
  Events: any;
};

export type DeployInfo = {
  Status: string;
  StatusDescription: string;
  SubmittedAt: Date;
  Allocation: any;
  Service: TaskState;
};
export class RetriableError extends Error {}

export type GithubProjectBranch = {
  name: string;
  protected: boolean;
  commit: any;
};

export type CustomDomainsConfig = {
  Domain: string;
  IsVerified: boolean;
  Tags: string[];
  CreatedAt: Date;
  UpdatedAt: Date;
};

export type DnsConfig = {
  CNAME: string;
  IP: string;
};

export type DnsTableConfig = {
  Type: string;
  Name: string;
  Value: string;
};
