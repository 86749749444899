import { Box, Skeleton } from '@mui/material';
import { FC } from 'react';

export const SkeletonHistory: FC = () => {
  return (
    <Box
      sx={{
        border: '1px solid #DADADA',
        borderRadius: 2,
        p: 2,
        width: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
      <Box
        sx={{ width: 0.7, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Skeleton sx={{ width: 0.3 }} variant="rounded" height={22} />
        <Skeleton sx={{ width: 0.1 }} variant="rounded" height={22} />
        <Skeleton sx={{ width: 0.3 }} variant="rounded" height={22} />
      </Box>
      <Box sx={{ width: 0.2, display: 'flex', alignItems: 'center' }}>
        <Skeleton sx={{ mr: 1, width: 0.8 }} variant="rounded" height={22} />
        <Skeleton width={'32px'} height={'32px'} variant="circular" />
      </Box>
    </Box>
  );
};
