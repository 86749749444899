import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  BuildResponse,
  BuildServiceBranchConfigRequest,
  BuildServiceBranchConfigResponse,
  PaginationHelper
} from '../../models';
import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { GetRefiendEndpoint } from '../../utils/commonfunction';
import { APIRoutes } from '../../utils/constants';
import { BuildClient } from '../axios';
import { notifyError } from '../toster';
import { Framework } from '../../pages/DetectingLogs';
import HttpService from '../Https-services';
import axios from 'axios';

const env = process.env;
const baseURL = env.REACT_APP_BUILD_API_BASE_URL || '';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export class BuildService {
  auth = useTypedSelector(getAuthState, shallowEqual);

  async GetHealthCheck() {
    var status = false;
    try {
      var result = await axios.get(baseURL + APIRoutes.HealthCheck);
      if (result.status >= 200 && result.status < 300) {
        status = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return status;
  }

  async InitiateProject(projectName: string, alias: string | null) {
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      await HttpService.put(
        baseURL,
        GetRefiendEndpoint(APIRoutes.InitiateBuildAccount, [alias, projectName])
      );
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
  }

  // Branch Config

  async ListBranchConfig(projectName: string, alias: string | null) {
    let repos: BuildServiceBranchConfigResponse[] = [];
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }

      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListBuildBranchConfig, [alias, projectName])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        response.data.map((s: any) => {
          repos.push({
            Namespace: s?.Namespace,
            Project: s?.Project,
            Branch: s?.Branch,
            Dockerfile: s?.Dockerfile,
            Context: s?.Context,
            DockerfileContent: s?.DockerfileContent,
            IsAutoDeploy: s?.IsAutoDeploy,
            BuildFlag: s?.BuildFlag,
            Framework: s?.Framework,
            Language: s?.Language,
            CreatedAt: s?.CreatedAt,
            UpdatedAt: s?.UpdatedAt
          });
        });
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return repos;
  }

  async CreateBranchConfig(requestBody: BuildServiceBranchConfigRequest, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      requestBody.Namespace = alias;
      var response = await HttpService.put(
        baseURL,
        GetRefiendEndpoint(APIRoutes.CreateBuildBranchConfig, [
          alias,
          requestBody.Project,
          requestBody.Branch
        ]),
        requestBody
      );
      if (response.status >= 200 && response.status < 300) {
        result = true;
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('document already exists')) {
        notifyError('Branch with same name already exists');
      }
    }
    return result;
  }

  async GetProjectBranchConfig(projectName: string, branchName: string, alias: string | null) {
    let result = {} as BuildServiceBranchConfigResponse;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetBuildBranchConfig, [alias, projectName, branchName])
      );
      result = response.data;
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async UpdateBranchConfig(
    projectName: string,
    branchName: string,
    requestBody: BuildServiceBranchConfigRequest,
    alias: string | null
  ) {
    let result = {} as BuildServiceBranchConfigResponse;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      requestBody.Namespace = alias;
      requestBody.Project = projectName;
      requestBody.Branch = branchName;

      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.UpdateBuildBranchConfig, [alias, projectName, branchName]),
        requestBody
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async DeleteBranchConfig(projectName: string, branchName: string, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService._delete(
        baseURL,
        GetRefiendEndpoint(APIRoutes.DeleteBuildBranchConfig, [alias, projectName, branchName])
      );
      if (response.status === 204) {
        result = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  // Build

  async ListBuild(
    projectName: string,
    branchName: string,
    alias: string | null,
    pageParams: PaginationHelper
  ) {
    let repos: BuildResponse[] = [];
    let totalResults: number = 0;
    pageParams.currentPage = pageParams.pageSize * (pageParams.currentPage - 1);
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListBuilds, [
          alias,
          projectName,
          branchName,
          pageParams.currentPage.toString(),
          pageParams.pageSize.toString()
        ])
      );
      if (response.status >= 200 && response.status < 300 && response.data?.results) {
        totalResults = response?.data?.total ? response?.data?.total : 0;
        response.data.results.map((s: any) => {
          repos.push({
            ID: s?.ID,
            JobID: s.JobID,
            Namespace: s?.Namespace,
            Project: s?.Project,
            Branch: s?.Branch,
            Commit: s?.Commit,
            Artifact: s.Artifact,
            Author: s?.Author,
            AuthorAvatarURL: s?.AuthorAvatarURL,
            Description: s?.Description,
            Status: s.Status,
            CreatedAt: s?.CreatedAt,
            UpdatedAt: s?.UpdatedAt
          });
        });
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return { results: repos, totalResults: totalResults };
  }
  async StartBuild(projectName: string, branchName: string, alias: string | null) {
    let result = {} as BuildResponse;

    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.StartBuild, [alias, projectName, branchName]),
        {}
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return result;
  }
  async GetStramsLogs(
    projectName: string,
    branchName: string,
    alias: string | null,
    buildId: string
  ) {
    let result: any;

    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetBuildStreamLogs, [alias, projectName, branchName, buildId])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return result;
  }
  async GetBuild(projectName: string, branchName: string, alias: string | null, buildId: string) {
    let result = {} as BuildResponse;

    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetBuildDetails, [alias, projectName, branchName, buildId])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return result;
  }
  async GetFrameworkList() {
    let result = {} as Array<Framework>;

    try {
      var response = await HttpService.get(baseURL, GetRefiendEndpoint(APIRoutes.GetFrameworks));
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return result;
  }
}
