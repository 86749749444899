const Style = {
  StatusHeader: {
    textAlign: 'center'
  },
  ServiceList: {
    border: '1px solid #DADADA',
    borderRadius: 2,
    width: 1,
    display: 'flex',
    alignItems: 'center',
    px: 3,
    py: 2.5,
    justifyContent: 'space-between'
  }
};

export default Style;
