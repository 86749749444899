import { Link as NavLink, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll';
import LoginPageImage from '../../../assets/images/LoginPageImage.png';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';

const GetStaredStepOne: FC = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;
  var Events = Scroll.Events;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Authentication
        </Typography>

        <Box>
          <Typography>You need to sign in / sign up with your GitHub account.</Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={LoginPageImage} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Authentication Page
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            className={'signup'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Sign in
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
            To sign up for BnD, go to{' '}
            <NavLink sx={Style.WebLink} onClick={() => navigate(PageRoutes.Login)}>
              https://buildndeploy.co/login.
            </NavLink>{' '}
            You can choose to authenticate with a Git provider (We only support Github right now).
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Typography className="upnext" sx={{ fontSize: '24px', fontWeight: 600, color: 'black' }}>
            Up next
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            {' '}
            Continue getting started with BnD:
          </Typography>
        </Box>
        <Box
          onClick={() => navigate(PageRoutes.GetStartedStepTwo)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Select Repository
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>
            select the appropriate repository you want to publish
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={Style.OnthisPage}>
          <Typography sx={{ mb: 1.5 }}>On this page</Typography>
          <Link activeClass="active" to="signup" spy={true} smooth={true}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Sign in
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="upnext" spy={true} smooth={true}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, cursor: 'pointer' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Up next
              </Typography>
            </Box>
          </Link>
          <Box sx={{ mt: 2, cursor: 'pointer' }}>
            <Typography onClick={scrollToTop}>Back to top</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GetStaredStepOne;
