export const PageRoutes = {
  LandingPage: '/',
  GithubAuthComplete: 'auth/success',
  RepositoryDetails: '/:id/details',
  Login: '/login',
  VscodeLogin: '/vscode-login',
  Status: '/status',
  DocumentationPage: '/documentation',
  Any: '*',
  Repository: '/repository',
  PricingPage: '/pricing',
  Dashboard: '/dashboard',
  ContributorDashboard: '/ContributorDashboard',
  ConfigureProject: '/configure-project',
  ProjectConfigure: '/project-configure',
  DetectingLogs: '/:id/configuration',
  ManageBranchConfiguration: '/:id/managebranch',
  PlatformStatus: '/status',
  GetStartedStepOne: '/getstared/login-step',
  GetStartedStepTwo: '/getstared/select-repositoy',
  GetStartedStepThree: '/getstared/project-configure',
  GetStartedStepFour: '/getstared/deployment',
  PrivacyPolicy: '/privacy-policy',
  SelectProjectType: '/select-project',
  BuildNewProject: '/build-new-project',
  TermAndCondition: '/term-conditions',
  DockerFileInstruction: '/docker-instruction',
  GithubAppInstallation: '/github-app-installation'
};

export const APIRoutes = {
  // Health check endpoint for backend services
  HealthCheck: '/health',

  // AuthService endpoint
  Login: '/github/login',
  // AuthService endpoint
  RefreshToken: '/github/login/refresh',
  // AuthService endpoint
  GetUserDetails: '/account',
  // AuthService endpoint
  CheckAppInstallationStatus: '/check-app-installation',

  // Create Project Technologies list endpoint
  GetProjectTechnologies: '/projects/technologies',
  // ProjectService endpoint - /:namespace/projects
  InitiateAccount: '/{0}/projects',
  // ProjectService endpoint - :namespace/github/repos
  ListGithubRepos: '/{0}/github/repos?q={1}&namespace={2}',
  // ProjectService endpoint - github/organisations
  ListGithubOrganisations: '/{0}/github/orgs',
  // ProjectService endpoint - :namespace/project
  AddProject: '/{0}/project',
  // Create a New ProjectService endpoint - :namespace/project/import
  CreateNewProject: '/{0}/project/import',
  // ProjectService endpoint - :namespace/projects
  ListProject: '/{0}/projects?q={1}&page={2}&pagesize={3}',
  // ProjectService endpoint - :namespace/contributors/projects
  ListContributorsProject: '/{0}/contributors/projects?q={1}&page={2}&pagesize={3}',
  // Sync Repository Collaborator - /:namespace/:project/sync
  SyncProjectContributors: '/{0}/{1}/sync',
  // ProjectService endpoint - :namespace/:project
  GetProject: '/{0}/{1}',
  // ProjectService endpoint - :namespace/:project/branches
  ListBranchConfig: '/{0}/{1}/branches',
  // ProjectService endpoint - :namespace/:project/:branch
  AddBranchConfig: '/{0}/{1}/{2}',
  // ProjectService endpoint - :namespace/:project/:branch
  GetBranchConfig: '/{0}/{1}/{2}',
  // ProjectService endpoint - :namespace/:project/:branch
  UpdateBranchConfig: '/{0}/{1}/{2}',
  // ProjectService endpoint - /:namespace/:project/github/branches
  GetListProjectBranches: '/{0}/{1}/github/branches',
  // ProjectService endpoint - :namespace/:project
  DeleteProject: '/{0}/{1}',

  // BuildService endpount - /frameworks - GET
  GetFrameworks: '/frameworks',
  // BuildService endpount - /:namespace/:project
  InitiateBuildAccount: '/{0}/{1}',
  // BuildService endpoint - /:namespace/:project/configs
  ListBuildBranchConfig: '/{0}/{1}/configs',
  // BuildService endpoint - /:namespace/:project/:branch/config
  GetBuildBranchConfig: '/{0}/{1}/{2}/config',
  // BuildService endpoint - /:namespace/:project/:branch/config - PUT
  CreateBuildBranchConfig: '/{0}/{1}/{2}/config',
  // BuildService endpoint - /:namespace/:project/:branch/config - POST
  UpdateBuildBranchConfig: '/{0}/{1}/{2}/config',
  // BuildService endpoint - /:namespace/:project/:branch/config - DELETE
  DeleteBuildBranchConfig: '/{0}/{1}/{2}/config',
  // BuildService endpoint - /:namespace/:project/:branch/builds
  ListBuilds: '/{0}/{1}/{2}/builds?page={3}&pagesize={4}',
  // BuildService endpoint - /:namespace/:project/:branch/:build
  GetBuildDetails: '/{0}/{1}/{2}/{3}',
  // BuildService endpoint - /:namespace/:project/:branch/build - POST
  StartBuild: '/{0}/{1}/{2}/build',
  // BuildService endpoint -/:namespace/:project/:branch/:build - POST
  UpdateBuild: '/{0}/{1}/{2}/build',
  // BuildService endpoint -/:namespace/:project/:branch/:build/logs
  // query params are follow:bool, offset:int, origin:string
  GetBuildStreamLogs: '/{0}/{1}/{2}/{3}/logs',

  // DeployService endpoint - /:namespace/:project
  InitiateDeployAccount: '/{0}/{1}',
  // DeployService endpoint - /:namespace/:project/configs
  ListDeployBranchConfig: '/{0}/{1}/configs',
  // DeployService endpoint - /:namespace/:project/:branch/config
  GetDeployBranchConfig: '/{0}/{1}/{2}/config',
  // DeployService endpoint - /:namespace/:project/:branch/config - PUT
  CreateDeployBranchConfig: '/{0}/{1}/{2}/config',
  // DeployService endpoint - /:namespace/:project/:branch/config - POST
  UpdateDeployBranchConfig: '/{0}/{1}/{2}/config',
  // DeployService endpoint - /:namespace/:project/:branch/config - DELETE
  DeleteDeployBranchConfig: '/{0}/{1}/{2}/config',
  // DeployService endpoint - /:namespace/:project/:branch/:build_id - POST
  DeployServiceBuild: '/{0}/{1}/{2}/{3}',
  // DeployService endpoint - /:namespace/:project/:branch - GET
  GetServiceDeployment: '/{0}/{1}/{2}',
  // DeployService endpoint - /:namespace/:project/:branch/logs
  GetDeployServiceLogs: '/{0}/{1}/{2}/logs',
  // DeployService endpoint - /:namespace/:project/:branch/logs
  GetRefreshCustomDomainLookups: '/{0}/{1}/{2}/lookups',
  // DeployService endpoint - /cname/lookups
  GetCnameDomainLookups: '/cname/lookups',
  // DeployService endpoint - /dns/config - GET
  GetDNSConfig: '/dns/config'
};

export const Providers = {
  Github: 'Github'
};

export const PortRange = {
  MinPortRange: 1,
  MaxPortRange: 65535
};

export const DefaultPagination = {
  Page: 1,
  PageSize: 10
};
