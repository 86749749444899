import styled from '@emotion/styled';

const HeaderSection = styled.div`
  position: fixed;
  z-index: 0;
  width: 100vw;
  top: 0;
  transition: all 1s;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  opacity: 0;

  &.active {
    z-index: 3;
    opacity: 1;
  }
  &.solid {
    z-index: 3;
    opacity: 1;
    background: #0c0b1d;
    box-shadow: 0 3px 6px #0c0b1d;
  }
  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    @media only screen and (max-width: 767px) {
      padding: 15px 0;
    }
    .logo {
      height: 70px;
      display: flex;
      align-items: center;
      gap: 28px;
      a {
        height: 70px;
      }
      @media only screen and (max-width: 767px) {
        height: 50px;
        a {
          height: 50px;
        }
      }
      img {
        height: 100%;
        @media only screen and (max-width: 767px) {
          max-width: 40px;
          margin-left: 0.2em;
        }
      }
    }
    .joinButton {
      button {
        background: linear-gradient(90deg, #664ccd 0%, #8f5de5 100%);
        box-shadow: 0px 10px 40px rgb(255 255 255 / 12%);
        border-radius: 8px;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 20px;
        min-width: 170px;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
          padding: 10px 15px;
          min-width: 130px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 14px;
          line-height: 22px;
          padding: 10px 15px;
          min-width: 100px;
        }
      }
    }
  }
`;

const LoginButton = {
  width: 1,
  color: 'common.white',
  background: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)',
  boxShadow: '0px 10px 40px rgba(255, 255, 255, 0.12)',
  borderRadius: '8px'
};
const DrawerBlock = {
  p: 2,
  height: 1,
  backgroundColor: '#101119'
};

export { DrawerBlock, HeaderSection, LoginButton };
