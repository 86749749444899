import styled from '@emotion/styled';

const GetStartedWrapper = styled.div`
  .container {
    @media only screen and (max-width: 992px) {
      max-width: 95%;
    }
  }
  margin: 0 auto;
  h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    @media only screen and (max-width: 820px) {
      font-size: 28px;
      line-height: 38px;
    }
  }

  .cardWrapper {
    margin-top: 100px;
    width: 100%;
    max-width: 100vw;
    .slick-slider {
      padding: 0 31px;
      .processArrowLeft {
        position: absolute;
        left: 0;
        bottom: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;
        z-index: 1;
      }
      .processArrowRight {
        position: absolute;
        right: 0;
        bottom: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;
        z-index: 1;
      }
    }
    .slick-slide > div {
      .card {
        margin: 0px 10px;
        display: flex;
        flex-direction: column;
        min-width: 266px;
        height: 305px;
        padding: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(223, 219, 216, 0.2);
        background: rgba(252, 253, 255, 0.1);
        backdrop-filter: blur(12px);

        .title {
          color: #fff;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
          margin-bottom: 18px;
          text-align: center;
        }

        .description {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .iconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 24px 32px;
          border-radius: 10px;
          background: var(--style, linear-gradient(90deg, #664ccd 0%, #8f5de5 100%));
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
          .icon {
            width: 36px;
            height: 36px;
          }
        }
      }

      .stepContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-top: 32px;

        .lineLeft {
          flex: 1;
          height: 1px;
          background: #6a6c6c;
          &.hide {
            height: 0px;
          }
        }
        .centerDot {
          width: 24px;
          height: 24px;
          background: transparent;
          border: 1px solid #6164fa;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          .dot {
            width: 18px;
            height: 18px;
            background: #6164fa;
            border-radius: 18px;
          }
        }
        p {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.72px;
        }
        .lineRight {
          flex: 1;
          height: 1px;
          background: #6a6c6c;
          &.hide {
            height: 0px;
          }
        }
      }
    }
  }
`;

export { GetStartedWrapper };
