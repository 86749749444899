export function ArrowLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="31"
      viewBox="0 0 18 31"
      fill="none"
      {...props}>
      <path
        d="M0 15.5L15.5769 31L18 28.4167L5.19231 15.5L18 2.58333L15.5769 0L0 15.5Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export function ArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="31"
      viewBox="0 0 18 31"
      fill="none"
      {...props}>
      <path
        d="M18 15.5L2.42307 -5.45571e-07L-5.76915e-07 2.58333L12.8077 15.5L1.68151e-06 28.4167L2.42308 31L18 15.5Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
