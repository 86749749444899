import { createTheme } from '@mui/material';

// Create a theme instance.
let theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 567,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1248
    }
  },
  palette: {
    common: {
      blackText: '#1E1E1E',
      black: '#000000',
      white: '#ffffff',
      grey: '#6B6B6B',
      offGrey: '#DADADA',
      green: {
        100: 'rgba(0, 166, 117, 0.1)',
        800: '#00A675'
      }
    },
    background: {
      light: '#F8F9FE',
      dark: '#101119'
    },
    gradient: {
      buttonBg: 'linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)'
    },
    primary: {
      light: '#5077dc',
      main: '#8F5DE5',
      dark: '#0b2b7d',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#5cb3ee',
      main: '#664CCD',
      dark: '#0f4c74',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#6B6B6B'
    },
    error: {
      main: '#E24329'
    }
  },
  typography: {
    fontFamily: 'Poppins',
    lineHeight: 1.3,
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightBold: '700',
    fontWeightBlack: '900'
  }
});

theme = createTheme({
  ...theme,
  typography: {
    h1: {
      fontFamily: 'Poppins',
      fontWeight: '600',
      fontSize: '32px',
      lineHeight: '42px',
      letterSpacing: 'normal',
      [theme.breakpoints.down('lg')]: {
        fontSize: '30px',
        lineHeight: '40px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '28px',
        lineHeight: '38px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
        lineHeight: '36px'
      }
    },
    h2: {
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: 'normal',
      [theme.breakpoints.down('lg')]: {
        fontSize: '22px',
        lineHeight: '30px'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: '26px'
      }
    },
    h4: {
      fontFamily: 'Poppins',
      fontWeight: '700',
      fontSize: '2.25rem',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.75rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem'
      }
    },
    h5: {
      fontFamily: 'Poppins',
      fontWeight: '700',
      fontSize: '1.875rem',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.75rem'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.375rem'
      }
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: '700',
      fontSize: '1.5rem',
      lineHeight: 1.4,
      letterSpacing: 'normal',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.375rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem'
      }
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontWeight: '700',
      fontSize: '1.375rem',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem'
      }
    },
    subtitle2: {
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: 'normal',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '28px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '26px'
      }
    },
    body1: {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: 1.5
    },
    body2: {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '1.125rem',
      lineHeight: 1.5,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem'
      }
    },
    button: {
      fontFamily: 'Poppins',
      fontWeight: '500'
    },
    caption: {
      fontFamily: 'Poppins',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: 1.5
    },
    overline: {
      fontFamily: 'Poppins',
      fontWeight: '400'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        },
        containedSecondary: {
          background: theme.palette.gradient.buttonBg
        }
      }
    }
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       'input':{
    //       [theme.breakpoints.down('md')]: {
    //         padding: '12px 16px',
    //       },
    //     }
    //     },
    //   },
    // },
  }
});

export default theme;
