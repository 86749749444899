import { Box, List, ListItem, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { FC, useEffect } from 'react';
import Style from './TermAndCondition.style';
import * as Scroll from 'react-scroll';
const TermAndCondition: FC = () => {
  var scroll = Scroll.animateScroll;

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ padding: { xxs: 4, md: 3 } }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xxs: '36px', md: '64px' },
          color: 'black',
          textAlign: 'center',
          fontWeight: 500,
          my: { xxs: 4, md: 6 }
        }}>
        Terms & Conditions
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xxs: '2rem', md: '4rem' },
          mt: { xxs: '2rem', md: '4rem' }
        }}>
        <Typography sx={Style.PrivacyParaStyle}>
          Terms and conditions for software use please read these terms and conditions carefully
          Before using this software. By using this software, you agree to be bound by these terms
          And conditions. If you do not agree to these terms and conditions, do not use this
          SOFTWARE.
        </Typography>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            1. LICENSE GRANT
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            The software and any accompanying documentation (collectively, the Software ) is
            licensed, not sold, to you by the software owner -{' '}
            <span style={{ whiteSpace: 'nowrap' }}>Build &apos;n Deploy.</span> The Owner grants you
            a non-exclusive, non-transferable license to use the Software in accordance with these
            terms and conditions
          </Typography>
        </Box>

        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            2. RESTRICTIONS
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            You may not, without the prior written consent of the Owner:
          </Typography>
          <List
            sx={{
              pl: 5
            }}>
            <ListItem sx={Style.ListItemStyle}>Copy, distribute, or modify the Software; </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              {' '}
              Reverse engineer, decompile, or disassemble the Software;{' '}
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              {' '}
              Remove any copyright, trademark, or other proprietary notices from the Software or any
              documentation;
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              {' '}
              Use the Software to create a competitive product or service;
            </ListItem>
            <ListItem sx={Style.ListItemStyle}>
              {' '}
              Use the Software in any manner that violates any applicable laws or regulations.
            </ListItem>
          </List>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            3. OWNERSHIP
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            The Software and any copies thereof are the property of the Owner or its licensors and
            are protected by copyright laws and international treaty provisions. The Owner retains
            all rights, title, and interest in and to the Software, including all intellectual
            property rights.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            4. DISCLAIMER OF WARRANTIES
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            {`The software is provided "as is" without warranty of any kind, either express or
            Implied, including, but not limited to, the implied warranties of merchantability and
            Fitness for a particular purpose. The owner does not warrant that the software will meet
            Your requirements or that the operation of the software will be uninterrupted or
            Error-free.`}
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            5. LIMITATION OF LIABILITY
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            In no event shall the owner be liable for any direct, indirect, incidental, special, or
            Consequential damages arising out of or in connection with the use or inability to use
            The software, even if the owner has been advised of the possibility of such damages.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            6. TERMINATION
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            This license will terminate automatically if you fail to comply with any of the terms
            and conditions of this agreement. Upon termination, you must immediately cease all use
            of the Software and destroy all copies of the Software.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            7. GOVERNING LAW
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            This agreement shall be governed by and construed in accordance with the laws of the
            jurisdiction in which the Owner is located. Any disputes arising under or in connection
            with this agreement shall be resolved by the courts of that jurisdiction.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            8. ENTIRE AGREEMENT
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            This agreement constitutes the entire agreement between you and the Owner with respect
            to the use of the Software and supersedes all prior or contemporaneous communications
            and proposals, whether oral or written, between you and the Owner.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            9. SEVERABILITY
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            If any provision of this agreement is held to be invalid or unenforceable, the remaining
            provisions shall remain in full force and effect.
          </Typography>
        </Box>
        <Box sx={Style.PrivacyBox} style={{ marginBottom: '24px' }}>
          <Typography variant="h1" sx={Style.PrivacyHeading}>
            10. ACKNOWLEDGMENT
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            By using the Software, you acknowledge that you have read this agreement, understand it,
            and agree to be bound by its terms and conditions.
          </Typography>
          <Typography sx={Style.PrivacyParaStyle}>
            If you have any questions regarding these terms and conditions, please contact{' '}
            <a
              style={{ whiteSpace: 'nowrap' }}
              href="mailto:legal@buildndeploy.co">{`Build 'n Deploy`}</a>
            .
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
export default TermAndCondition;
