import React from 'react';

import Rocket from '../../../assets/images/astronaut.png';
import Shape from '../../../assets/images/title-round.svg';

import SubscribeForm from '../../../components/SubscribeForm';
import * as Style from './Subscribe.style';
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe';
import { motion } from 'framer-motion';

const Subscribe = ({ currentPage }: { currentPage?: number }) => {
  const variants = {
    start: {
      x: -50,
      y: -100,
      transition: { duration: 1, delay: 0.1 }
    },
    stop: { x: 0, y: 100, transition: { duration: 1 } }
  };

  const ref = React.useRef(null);
  const url = process.env.REACT_APP_MAILCHIMP_URL || '';

  return (
    <Style.SectionWrapper>
      <div className="container" ref={ref}>
        <Style.SubscribeMain>
          <div className="SubscribeContent">
            <div className="title">
              <div className="titleShape">
                <img src={Shape} alt="shape" />
              </div>
              Simplify your work.
              <div className="dynamicTitle">
                <div className="dynamicTitleAnimated">
                  Focus on your
                  <div className="rotateKeyword">
                    <span className="design">design</span>
                    <span className="development">development</span>
                    {/* <span className="launch">deploy</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="description">Stay up to date with BnD.</div>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <SubscribeForm
                  status={status}
                  message={message}
                  onValidated={(formData: EmailFormFields) => subscribe(formData)}
                />
              )}
            />
          </div>
          <motion.div
            className="rocket"
            variants={variants}
            animate={currentPage === 1 ? 'start' : 'stop'}>
            <img src={Rocket} alt="rocket" />
          </motion.div>
        </Style.SubscribeMain>
      </div>
    </Style.SectionWrapper>
  );
};

export default Subscribe;
