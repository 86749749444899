import { FC, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { PaginationHelper, ProjectListResponse } from '../../models';
import { DefaultPagination, PageRoutes } from '../../utils/constants';

import { Pagination, TextField } from '@mui/material';
import moment from 'moment';
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoDataFound from '../../components/shared/NoDataFound';
import { ProjectService } from '../../services/API/ProjectService';
import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import Style from '../Dashboard/Dashboard.style';
import { SkeletonDashboard } from '../Dashboard/SkeletonDashboard/index';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
var debouce = require('lodash.debounce');
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const ContributorDashboard: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('dashboard');
  const [repoList, setRepoList] = useState<ProjectListResponse[]>([]);
  const [pagination, setpagination] = useState<PaginationHelper>({
    pageSize: DefaultPagination.PageSize,
    currentPage: DefaultPagination.Page
  } as PaginationHelper);
  const [isLoader, setIsLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();
  const projectService = new ProjectService();
  const [searchQuery, setSearchQuery] = useState('');

  const handleRepoSearch = async (event: any) => {
    let resetPagination = pagination;
    resetPagination.pageSize = DefaultPagination.PageSize;
    resetPagination.currentPage = DefaultPagination.Page;
    setpagination({ ...resetPagination });
    setHasMore(true);

    setSearchQuery(event.target.value);
    if (event.target.value?.length >= 2) {
      await handleGetContributorsProjectList(pagination, event.target.value);
    } else {
      if (event.target.value?.length == 0) {
        await handleGetContributorsProjectList(pagination, '');
        setHasMore(true);
      }
    }
  };
  const handleAddNewProject = () => {
    gaEventTracker('add_new_project');
    navigate(PageRoutes.SelectProjectType);
  };

  async function handleGetContributorsProjectList(
    paginationParams: PaginationHelper,
    searchParams?: string
  ) {
    setIsLoader(true);
    const repos = await projectService.ListContributorsProjects(searchParams, {
      ...paginationParams
    });
    setIsLoader(false);

    let item = pagination;
    item.totalPages = Math.ceil(repos.totalResults / pagination.pageSize);
    item.totalCount = repos.totalResults;

    if (repos.results) {
      let nextRepolist = pagination.currentPage == 1 ? [] : [...repoList];
      setRepoList([...nextRepolist, ...repos.results]);
      if (repos.totalResults <= pagination.currentPage * pagination.pageSize) {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }

  const debouncedResults = useMemo(() => {
    return debouce(handleRepoSearch, 500);
  }, [repoList]);

  useEffect(() => {
    if (auth.access_token && auth.user_details?.Alias) {
      if (localStorage.getItem('isInitiate') !== 'true') {
        projectService.InitiateAccount();
        localStorage.setItem('isInitiate', 'true');
      }
      handleGetContributorsProjectList(pagination, '');
    }
  }, [auth]);

  const handleNextPage = async () => {
    let item = pagination;
    item.currentPage = item.currentPage + 1;
    setpagination({ ...item });
    handleGetContributorsProjectList({ ...pagination }, searchQuery);
  };

  return (
    <Box sx={Style.DashboardWrapper}>
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="flex-end">
        <Grid item xs={12} sm={12} sx={{ width: 1 }}>
          <FormControl sx={{ width: 1 }} variant="outlined">
            <TextField
              label="Search for a project"
              variant="outlined"
              id="search"
              autoComplete="off"
              placeholder="Search"
              onChange={debouncedResults}
              aria-describedby="search"
              inputProps={{
                'aria-label': 'search'
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <List>
        <InfiniteScroll
          dataLength={repoList.length}
          next={async () => await handleNextPage()}
          hasMore={hasMore}
          loader={isLoader && <SkeletonDashboard />}>
          {repoList.map((Element, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={Style.ProjectList}
              onClick={() =>
                navigate(
                  PageRoutes.RepositoryDetails.replace(':id', Element.Name) +
                    '?alias=' +
                    Element.Alias
                )
              }>
              <Box sx={Style.UserBox}>
                {/* <Box sx={Style.UserThumb}>
                  <img src={Element.profilePicture} alt="profile" />
                </Box> */}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'common.blackText',
                      fontWeight: '500',
                      wordBreak: 'break-word'
                    }}>
                    {Element.Name}
                  </Typography>
                  <Typography variant="caption" sx={{ wordBreak: 'break-word' }}>
                    {Element.Source}
                  </Typography>
                </Box>
              </Box>
              <Box sx={Style.CommitBoxWrapper}>
                <Typography variant="body1" sx={{ color: 'common.blackText' }}>
                  {moment(Element.UpdatedAt).format('MM-DD-yyyy hh:mm a')}
                </Typography>
                {/* <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ padding: '0 4px' }}>
                    {'Element.commitTime'}
                  </Box>
                  <HierarchIcon
                    sx={{ fontSize: 16, mx: 0.5 }}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                  />
                  <Box component="span">{'Element.branchName'}</Box>
                </Typography> */}
              </Box>
            </ListItem>
          ))}
        </InfiniteScroll>
        {repoList.length === 0 && !isLoader && <NoDataFound />}
      </List>
    </Box>
  );
};
