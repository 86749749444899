import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  CreateSkeletonProjectRequest,
  CreateProjectRequest,
  GithubProjectBranch,
  PaginationHelper,
  Project,
  ProjectListResponse,
  RepositoryListItem
} from '../../models';
import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { GetRefiendEndpoint } from '../../utils/commonfunction';
import { APIRoutes } from '../../utils/constants';
import { ProjectClient } from '../axios';
import { notifyError, notifySuccess } from '../toster';
import { BuildService } from './BuildService';
import { DeployService } from './DeployService';
import HttpService from '../Https-services';
import axios from 'axios';

const env = process.env;
const baseURL = env.REACT_APP_PROJECT_API_BASE_URL || '';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export class ProjectService {
  auth = useTypedSelector(getAuthState, shallowEqual);

  deployService = new DeployService();
  buildService = new BuildService();

  async GetHealthCheck() {
    var status = false;
    try {
      var result = await axios.get(baseURL + APIRoutes.HealthCheck);
      if (result.status >= 200 && result.status < 300) {
        status = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return status;
  }

  async GetProjectTechnologies() {
    let result: { name: string; value: string }[] = [];

    try {
      const response = await HttpService.get(baseURL, APIRoutes.GetProjectTechnologies);

      if (response.data) {
        Object.keys(response.data).forEach((key) => {
          result.push({
            name: key,
            value: response.data[key]
          });
        });
      }
    } catch (error) {
      return result;
    }
    return result;
  }

  async ListGithubRepos(
    searchParam: string = '',
    namespace: string = this.auth.user_details.Alias
  ) {
    let repos: RepositoryListItem[] = [];

    try {
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListGithubRepos, [
          this.auth.user_details.Alias,
          searchParam,
          namespace
        ])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        response.data.map((s: any) => {
          repos.push({
            name: s.name,
            full_name: s.full_name,
            html_url: s.html_url,
            private: s.private,
            description: s.description,
            technologies: s.language,
            technologiesColor: 'primary.main',
            clone_url: s.clone_url,
            default_branch: s.default_branch,
            configured: s.configured
          });
        });
      }
    } catch (error) {
      return repos;
    }
    return repos;
  }

  async ListGithubOrganisations() {
    let result: {
      name: string;
      avatar: string;
    }[] = [];

    try {
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListGithubOrganisations, [this.auth.user_details.Alias])
      );

      if (response.data) {
        response.data.forEach((element: any) => {
          result.push({ name: element.login, avatar: element.avatar_url });
        });
      }
    } catch (error) {
      return result;
    }
    return result;
  }

  async InitiateAccount() {
    try {
      await HttpService.put(
        baseURL,
        GetRefiendEndpoint(APIRoutes.InitiateAccount, [this.auth.user_details.Alias])
      );
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
  }

  async CreateProject(requestBody: CreateProjectRequest) {
    let result = {} as Project;
    try {
      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.AddProject, [this.auth.user_details.Alias]),
        requestBody
      );

      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
        await this.deployService.InitiateProject(requestBody.Name, this.auth.user_details.Alias);
        await this.buildService.InitiateProject(requestBody.Name, this.auth.user_details.Alias);
        notifySuccess('Project Created Successfully');
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('already exists')) {
        notifyError('Project with name already exists');
      } else {
        notifyError(error.response?.data?.error);
      }
    }
    return result;
  }
  async CreateSkeletonProject(requestBody: CreateSkeletonProjectRequest) {
    let result = {} as Project;
    try {
      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.CreateNewProject, [this.auth.user_details.Alias]),
        requestBody
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('already exists')) {
        notifyError('Project with name already exists');
      } else {
        notifyError(error.response?.data?.error);
        throw error;
      }
    }
    return result;
  }

  async ListProjects(searchParam: string = '', pageParams: PaginationHelper) {
    let repos: ProjectListResponse[] = [];
    let totalResults: number = 0;
    pageParams.currentPage = pageParams.pageSize * (pageParams.currentPage - 1);
    try {
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListProject, [
          this.auth.user_details.Alias,
          searchParam,
          pageParams.currentPage.toString(),
          pageParams.pageSize.toString()
        ])
      );
      if (response.status >= 200 && response.status < 300 && response.data?.results) {
        totalResults = response?.data?.total ? response?.data?.total : 0;
        response.data.results.map((s: any) => {
          repos.push({
            ID: s?.ID,
            AccountID: s.AccountID,
            Name: s.Name,
            Source: s.Source,
            UpdatedAt: s.UpdatedAt,
            Alias: ''
          });
        });
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('index_not_found_exception')) {
        this.InitiateAccount();
      } else {
        notifyError(error.response?.data?.error);
      }
    }
    return { results: repos, totalResults: totalResults };
  }

  async ListContributorsProjects(searchParam: string = '', pageParams: PaginationHelper) {
    let repos: ProjectListResponse[] = [];
    let totalResults: number = 0;
    pageParams.currentPage = pageParams.pageSize * (pageParams.currentPage - 1);
    try {
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListContributorsProject, [
          this.auth.user_details.Alias,
          searchParam,
          pageParams.currentPage.toString(),
          pageParams.pageSize.toString()
        ])
      );
      if (response.status >= 200 && response.status < 300 && response.data?.results) {
        totalResults = response?.data?.total ? response?.data?.total : 0;
        response.data.results.map((s: any) => {
          repos.push({
            ID: s?.ID,
            AccountID: s.AccountID,
            Name: s.Name,
            Source: s.Source,
            UpdatedAt: s.UpdatedAt,
            Alias: s.Alias
          });
        });
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('index_not_found_exception')) {
        this.InitiateAccount();
      } else {
        notifyError(error.response?.data?.error);
      }
    }
    return { results: repos, totalResults: totalResults };
  }

  async SyncRepositoryCollaborator(id: string, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }

      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.SyncProjectContributors, [alias, id])
      );

      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data?.sync;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async GetProjectById(id: string, alias: string | null) {
    let result = {} as Project;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }

      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetProject, [alias, id])
      );

      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async GetListProjectBranches(id: string, defaultNamespace?: string) {
    let branches: GithubProjectBranch[] = [];
    try {
      defaultNamespace = defaultNamespace ? defaultNamespace : this.auth.user_details.Alias;
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetListProjectBranches, [defaultNamespace, id])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        response.data.map((s: any) => {
          branches.push({
            name: s?.name,
            protected: s?.protected,
            commit: s?.commit
          });
        });
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return branches;
  }

  async DeleteProject(id: string, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService._delete(
        baseURL,
        GetRefiendEndpoint(APIRoutes.DeleteProject, [alias, id])
      );
      if (response.status === 201) {
        result = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return result;
  }
}
