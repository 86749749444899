import { SvgIcon } from '@mui/material';

export function RightRotate(prop) {
  return (
    <svg
      width="32"
      height="32"
      {...prop}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8538 6.77353C18.6938 6.42686 17.4138 6.2002 16.0004 6.2002C9.61376 6.2002 4.44043 11.3735 4.44043 17.7602C4.44043 24.1602 9.61376 29.3335 16.0004 29.3335C22.3871 29.3335 27.5604 24.1602 27.5604 17.7735C27.5604 15.4002 26.8404 13.1869 25.6138 11.3469"
        stroke="#6B6B6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5067 7.09317L17.6533 2.6665"
        stroke="#6B6B6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.507 7.09326L17.0137 10.3733"
        stroke="#6B6B6B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
