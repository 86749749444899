import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  DeployInfo,
  DeployServiceBranchConfigRequest,
  DeployServiceBranchConfigResponse,
  DnsConfig
} from '../../models';
import { getAuthState } from '../../store/reducers/authReducer';
import { RootState } from '../../store/store';
import { GetRefiendEndpoint } from '../../utils/commonfunction';
import { APIRoutes } from '../../utils/constants';
import { DeployClient } from '../axios';
import { notifyError } from '../toster';
import HttpService from '../Https-services';
import axios from 'axios';

const env = process.env;
const baseURL = env.REACT_APP_DEPLOY_API_BASE_URL || '';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export class DeployService {
  auth = useTypedSelector(getAuthState, shallowEqual);

  async GetHealthCheck() {
    var status = false;
    try {
      var result = await axios.get(baseURL + APIRoutes.HealthCheck);

      if (result.status >= 200 && result.status < 300) {
        status = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return status;
  }

  async InitiateProject(projectName: string, alias: string | null) {
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      await HttpService.put(
        baseURL,
        GetRefiendEndpoint(APIRoutes.InitiateDeployAccount, [alias, projectName])
      );
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
  }

  async ListBranchConfig(projectName: string, alias: string | null) {
    let repos: DeployServiceBranchConfigResponse[] = [];

    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.ListDeployBranchConfig, [alias, projectName])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        response.data.map((s: any) => {
          repos.push({
            Namespace: s?.Namespace,
            Project: s?.Project,
            Branch: s?.Branch,
            Env: s?.Env,
            Ports: s?.Ports,
            FQDNs: s?.FQDNs,
            CustomDomains: s?.CustomDomains,
            CreatedAt: s?.CreatedAt,
            UpdatedAt: s?.UpdatedAt
          });
        });
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }

    return repos;
  }

  async CreateBranchConfig(requestBody: DeployServiceBranchConfigRequest, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      requestBody.Namespace = alias;
      var response = await HttpService.put(
        baseURL,
        GetRefiendEndpoint(APIRoutes.CreateDeployBranchConfig, [
          this.auth.user_details.Alias,
          requestBody.Project,
          requestBody.Branch
        ]),
        requestBody
      );
      if (response.status >= 200 && response.status < 300) {
        result = true;
      }
    } catch (error: any) {
      if (error.response?.data?.error?.includes('document already exists')) {
        notifyError('Branch with same name already exists');
      }
    }
    return result;
  }

  async GetProjectBranchConfig(projectName: string, branchName: string, alias: string | null) {
    let result = {} as DeployServiceBranchConfigResponse;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      const response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetDeployBranchConfig, [alias, projectName, branchName])
      );
      result = response.data;
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async UpdateBranchConfig(
    projectName: string,
    branchName: string,
    alias: string | null,
    requestBody: DeployServiceBranchConfigRequest
  ) {
    let result = {} as DeployServiceBranchConfigResponse;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      requestBody.Namespace = alias;
      requestBody.Project = projectName;
      requestBody.Branch = branchName;

      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.UpdateDeployBranchConfig, [alias, projectName, branchName]),
        requestBody
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async DeleteBranchConfig(projectName: string, branchName: string, alias: string | null) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService._delete(
        baseURL,
        GetRefiendEndpoint(APIRoutes.DeleteDeployBranchConfig, [alias, projectName, branchName])
      );
      if (response.status === 204) {
        result = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  // Deploy Build

  async DeployServiceBuild(
    projectName: string,
    branchName: string,
    alias: string | null,
    buildId: string
  ) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.post(
        baseURL,
        GetRefiendEndpoint(APIRoutes.DeployServiceBuild, [alias, projectName, branchName, buildId]),
        {}
      );
      if (response.status === 204) {
        result = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async GetServiceDeployment(projectName: string, branchName: string, alias: string | null) {
    let result = {} as DeployInfo;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetServiceDeployment, [alias, projectName, branchName])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      if (error.response?.status == 404 || error.response?.data?.error?.includes('404')) {
        result.Status = 'Not Running';
      }
    }
    return result;
  }

  async GetLogs(projectName: string, branchName: string, alias: string | null) {
    let result: any;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetDeployServiceLogs, [alias, projectName, branchName])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return result;
  }

  async GetRefreshCustomDomainStatus(
    projectName: string,
    branchName: string,
    alias: string | null
  ) {
    let result = false;
    try {
      if (!alias) {
        alias = this.auth.user_details.Alias;
      }
      var response = await HttpService.get(
        baseURL,
        GetRefiendEndpoint(APIRoutes.GetRefreshCustomDomainLookups, [
          alias,
          projectName,
          branchName
        ])
      );
      if (response.status >= 200 && response.status < 300 && response.data) {
        if (response?.data?.isNewDomainVerified) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error: any) {
      result = false;
    }
    return result;
  }

  async GetDNSConfig() {
    let result = {} as DnsConfig;
    try {
      var response = await HttpService.get(baseURL, APIRoutes.GetDNSConfig);
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      result.CNAME = '';
      result.IP = '';
    }
    return result;
  }
}
