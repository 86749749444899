export function makeid(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function GetRefiendEndpoint(apiEndPoint: string, Argumentlist?: string[]) {
  if (Argumentlist) {
    Argumentlist.forEach(function (value: any, i: number) {
      apiEndPoint = apiEndPoint.replace('{' + i + '}', arguments[0]);
    });
  }
  return apiEndPoint;
}
