import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Style from './NoDataFound.styles';
import NoRepoFound from '../../../assets/images/RepoNotFound.svg';

export const NoRepositoryFound: FC<{ message?: string; image?: string }> = (props) => {
  return (
    <Box sx={Style.NoDataFoundWrapper}>
      <Box sx={{ mb: 5 }}>
        <img src={props.image ? props.image : NoRepoFound} alt="No Resource Found" />
      </Box>
      <Typography variant="subtitle2" sx={{ color: '#1E1E1E' }}>
        {props.message ? props.message : 'No Data Found'}
      </Typography>
    </Box>
  );
};

export default NoRepositoryFound;
