import axiosInstance from '../hooks/useAxiosPrivate';

const get = (baseUrl: string, url: string) => {
  return axiosInstance.get(baseUrl + `${url}`, {});
};

const post = (baseUrl: string, url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data);
};

const put = (baseUrl: string, url: string, data?: any) => {
  return axiosInstance.put(baseUrl + url, data);
};

const _delete = (baseUrl: string, url: string, data?: any) => {
  return axiosInstance.delete(baseUrl + url);
};

const HttpService = {
  get,
  post,
  put,
  _delete
};

export default HttpService;
