import { Box, Drawer, IconButton, ListItemButton, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '../../../assets/icons/Close';
import MenuIcon from '../../../assets/icons/Menu';
import Logo from '../../../assets/images/bndwight.svg';
import ProfilePic from '../../../assets/images/profile.png';
import { AuthService } from '../../../services/API/AuthService';
import {
  authDetailsReducer,
  getAuthState,
  logout,
  setAuthDetails
} from '../../../store/reducers/authReducer';
import { RootState } from '../../../store/store';
import { PageRoutes } from '../../../utils/constants';
import Style from './Header.styles';
import RouterBreadcrumbs from '../Breadcrumbs';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const Profile: FC = () => {
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const authService = new AuthService();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate(PageRoutes.LandingPage);
  };
  const handleStatusPage = () => {
    navigate(PageRoutes.Status);
    handleClose();
  };

  function setAuth(data: authDetailsReducer) {
    dispatch(setAuthDetails(data));
  }

  const getUserDetails = async () => {
    const response = await authService.GetUserDetails();
    if (response?.ID) {
      setAuth({
        ...auth,
        user_details: response,
        provider: response.Provider
      } as authDetailsReducer);
    }
  };

  return (
    <>
      <Button
        id="menu-button"
        aria-controls={open ? 'header-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={Style.UserImage}>
        {auth?.user_details?.AvatarURL ? (
          <img src={auth?.user_details?.AvatarURL} alt="profile" />
        ) : (
          <img src={ProfilePic} alt="profile" />
        )}
      </Button>
      <Menu
        id="header-menu"
        aria-labelledby="menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: Style.MenuDropDown
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem sx={Style.MenuUserImageWrapper}>
          <Box sx={Style.MenuUserImage}>
            {auth?.user_details?.AvatarURL ? (
              <img src={auth?.user_details?.AvatarURL} alt="profile" />
            ) : (
              <img src={ProfilePic} alt="profile" />
            )}
          </Box>
          <Typography variant="body1" sx={{ color: 'common.blackText' }}>
            {auth.user_details?.Name}
          </Typography>
        </MenuItem>
        {false && (
          <>
            <MenuItem onClick={handleClose}>Your Profile</MenuItem>
            <MenuItem onClick={handleClose}>Settings</MenuItem>
          </>
        )}
        <Divider />
        <MenuItem onClick={handleStatusPage}>Status</MenuItem>
        <MenuItem onClick={handleLogout} sx={{ color: 'error.main' }}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

const NamespaceSelector: FC = () => {
  const [age, setAge] = useState('');

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  return (
    <FormControl sx={Style.menuSelectWrapper}>
      <Select
        sx={Style.menuSelect}
        value={age}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}>
        <MenuItem value="" sx={Style.ProfileImageWrapper}>
          <Box sx={Style.ProfileImage}>
            <img src={ProfilePic} alt="profile" />
          </Box>
          <Typography variant="body1" sx={{ color: 'common.blackText' }}>
            pacenthink
          </Typography>
        </MenuItem>
        <MenuItem value="" sx={Style.ProfileImageWrapper}>
          <Box sx={Style.ProfileImage}>
            <img src={ProfilePic} alt="profile" />
          </Box>
          <Typography variant="body1" sx={{ color: 'common.blackText' }}>
            buildndeploy
          </Typography>
        </MenuItem>
        <MenuItem value="" sx={Style.ProfileImageWrapper}>
          <Box sx={Style.ProfileImage}>
            <img src={ProfilePic} alt="profile" />
          </Box>
          <Typography variant="body1" sx={{ color: 'common.blackText' }}>
            bnd
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export const Header: FC = () => {
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate(PageRoutes.Login);
  };
  const handleStatus = () => {
    navigate(PageRoutes.Status);
  };
  const location = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Box
      component="header"
      sx={{
        ...Style.HeaderWrapper,
        display: [PageRoutes.Login, PageRoutes.VscodeLogin, PageRoutes.GithubAuthComplete].includes(
          location.pathname
        )
          ? 'none'
          : 'flex'
      }}>
      <Container sx={Style.HeaderMainOuter} maxWidth="xl">
        <Box sx={Style.HeaderMain}>
          <Box sx={Style.LogoBox}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
                to={PageRoutes.Dashboard}>
                <img style={{ height: '50px', width: '50px' }} src={Logo} alt="logo" />
              </Link>
              {location.pathname !== PageRoutes.LandingPage && <RouterBreadcrumbs />}
            </Box>

            {!auth.access_token && (
              <Box sx={{ display: { xxs: 'none', md: 'flex' }, gap: '24px' }}>
                <Typography
                  onClick={() => navigate(PageRoutes.DocumentationPage)}
                  sx={{
                    cursor: 'pointer',
                    color:
                      location.pathname === PageRoutes.DocumentationPage
                        ? 'secondary.main'
                        : '#000000'
                  }}
                  variant="body1">
                  Documentation
                </Typography>
                <Typography
                  sx={{
                    cursor: 'pointer',
                    color:
                      location.pathname === PageRoutes.PricingPage ? 'secondary.main' : '#000000'
                  }}
                  variant="body1"
                  onClick={() => navigate(PageRoutes.PricingPage)}>
                  Pricing
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 3
              // ml:
              //   location.pathname === PageRoutes.PricingPage ||
              //   location.pathname == PageRoutes.LandingPage
              //     ? '8rem'
              //     : 0
            }}>
            {auth.access_token ? (
              <Box
                sx={{
                  ...Style.MenuWrapper,
                  display: {
                    xs: 'flex',
                    md: 'none'
                  }
                }}>
                <Profile />
              </Box>
            ) : (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'none'
                    }
                  }}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              </>
            )}
          </Box>

          <Drawer anchor="top" open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={Style.DrawerBlock}>
              <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
                <CloseIcon
                  sx={{
                    color: 'common.black'
                  }}
                />
              </IconButton>

              <Divider sx={{ mb: 2, backgroundColor: '#6b6b6b' }} />

              <Box sx={{ mb: 2 }}>
                <ListItemButton>
                  <ListItemText
                    primary="Documentation"
                    sx={{
                      color: 'common.black'
                    }}
                    onClick={() => {
                      setOpenDrawer(false);
                      navigate(PageRoutes.DocumentationPage);
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <ListItemText
                    primary="Pricing"
                    sx={{
                      color: 'common.black'
                    }}
                    onClick={() => {
                      setOpenDrawer(false);
                      navigate(PageRoutes.PricingPage);
                    }}
                  />
                </ListItemButton>
              </Box>

              {auth.access_token ? (
                <Box sx={Style.MenuWrapper}>
                  {/* For now we are disabling this as it will not be part of the first release */}
                  {false && auth.access_token && (
                    <Box sx={Style.Profile}>
                      <NamespaceSelector />
                    </Box>
                  )}
                  <Profile />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Button
                    onClick={() => {
                      setOpenDrawer(false);
                      handleLogin();
                    }}
                    sx={{
                      border: '1px solid',
                      borderColor: 'action.focus',
                      color: 'secondary.main',
                      minWidth: '90px'
                    }}>
                    Login
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      minWidth: '90px'
                    }}
                    onClick={() => {
                      setOpenDrawer(false);
                      handleLogin();
                    }}>
                    Try out free
                  </Button>
                </Box>
              )}
            </Box>
          </Drawer>
          {location.pathname === PageRoutes.LandingPage ||
          location.pathname === PageRoutes.PricingPage ? (
            <Box sx={{ ...Style.MenuWrapper, gap: 1 }}>
              <Button
                onClick={handleLogin}
                sx={{
                  border: '1px solid',
                  borderColor: 'action.focus',
                  color: 'secondary.main',
                  minWidth: '90px'
                }}>
                Login
              </Button>
              <Button
                onClick={handleLogin}
                variant="contained"
                color="secondary"
                sx={{
                  minWidth: '90px'
                }}>
                Try out free
              </Button>
            </Box>
          ) : (
            <Box sx={Style.MenuWrapper}>
              {/* For now we are disabling this as it will not be part of the first release */}
              {false && auth.access_token && (
                <Box sx={Style.Profile}>
                  <NamespaceSelector />
                </Box>
              )}
              {auth.access_token ? (
                <Profile />
              ) : location.pathname === PageRoutes.Login ? (
                <Button onClick={handleStatus} variant="contained" color="secondary" size="large">
                  Status
                </Button>
              ) : (
                <Button onClick={handleLogin} variant="contained" color="secondary" size="large">
                  Login
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
