import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';

const DocumentMainContent: FC = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, fontWeight: 700, color: 'black' }}>
        Get Started with BnD
      </Typography>
      <Box>
        <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
          BnD is an end-to-end platform for developers, that allows you to create and deploy your
          applications. BnD provides the following features to enable you to serve fast and
          personalized content to your users:
        </Typography>
        <Box>
          <Typography variant="h2" sx={{ color: 'black', mt: 3 }}>
            Deployments
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
            Managing your deployments is at the heart of everything BnD does. Once you import your
            project, BnD automatically handles the configuration of the build settings for your
            framework. BnD also provides build logs, to help you to identify issues before you
            deploy to production.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2" sx={{ color: 'black', mt: 3 }}>
            CI/CD
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
            As you are developing a project, BnD automatically provides a preview deployment with
            its own URL. The preview URL is automatically integrated with your connected Git
            provider and is accessible in the body of your pull request. Once your pull request is
            merged into the main branch, BnD will make a Production Deployment.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h2" sx={{ color: 'black', mt: 3 }}>
            Sign up
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
            To sign up for BnD, go to
            <Link sx={Style.WebLink} onClick={() => navigate(PageRoutes.Login)}>
              https://buildndeploy.co/login.
            </Link>
            You can choose to authenticate with a Git provider.
          </Typography>
          <Typography variant="h2" sx={{ color: 'black', mt: 3 }}>
            Connect to a Git provider
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'justify', mt: 2 }}>
            Connecting to a Git provider is an important part of the BnD journey. This is because
            every time you push code, BnD automatically creates a new deployment. We currently
            support the following Git providers.
          </Typography>
          <Box sx={{ p: 3, pr: 0 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 1
              }}>
              <Box>
                <Typography sx={{ color: 'black' }}>1.</Typography>
              </Box>
              <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                Github
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                <span
                  style={{
                    fontWeight: 600,
                    color: 'black',
                    backgroundColor: '#DADADA',
                    borderRadius: '4px',
                    paddingRight: 8,
                    paddingLeft: 8
                  }}>
                  {' '}
                  <Link sx={Style.WebLink} target="_blank" href="https://github.com/">
                    https://github.com
                  </Link>
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => navigate(PageRoutes.GetStartedStepOne)}
            sx={{
              border: '1px solid #DADADA',
              borderRadius: 2,
              padding: 3,
              mt: 4,
              cursor: 'pointer',
              backgroundColor: '#DADADA'
            }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
              Get Started
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>
              Streamline your application deployment process with our expert guide.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DocumentMainContent;
