const CircleThreeLayer = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.333252" width="40" height="40" rx="20" fill="#664CCD" fillOpacity="0.1" />
      <g clipPath="url(#clip0_3404_22856)">
        <path
          d="M11.6667 24.1667L20.0001 28.3334L28.3334 24.1667M11.6667 20L20.0001 24.1667L28.3334 20M20.0001 11.6667L11.6667 15.8334L20.0001 20L28.3334 15.8334L20.0001 11.6667Z"
          stroke="#664CCD"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="1.83325"
        y="1.5"
        width="37"
        height="37"
        rx="18.5"
        stroke="#664CCD"
        strokeOpacity="0.1"
        strokeWidth="3"
      />
      <defs>
        <clipPath id="clip0_3404_22856">
          <rect width="20" height="20" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleThreeLayer;
