const Style = {
  Heading: {
    color: 'common.blackText',
    textAlign: 'center',
    mb: { xxs: 3, md: 4, lg: 5 }
  },
  MainHeading: {
    color: 'common.blackText',
    ml: { xxs: 1, sm: 1.5 }
  },
  SetupCard: {
    pb: { xxs: 3, md: 4 },
    borderBottom: '1px solid',
    borderColor: 'action.focus'
  },
  ProjectList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  ProjectListImage: {
    mr: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  EditDirectory: {
    padding: '15px'
  },
  LogsCardHead: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: { xxs: 2, sm: 3 }
  },
  LogsCardTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  LogsCard: {
    borderRadius: 4,
    bgcolor: 'common.white',
    p: { xxs: 2, sm: 3.5, lg: 4.5 },
    mb: 3,
    '&:last-child': {
      mb: 0
    },
    '&.disable': {
      bgcolor: 'grey.200',
      pointerEvents: 'none'
    }
  },
  DetailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: {
      xxs: 'column',
      sm: 'row'
    },
    gap: 2,
    alignItems: 'center',
    mb: 2
  }
};

export default Style;
