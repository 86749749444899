import SvgIcon from '@mui/material/SvgIcon';

export default function Authentication(props) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M11.5176 27.105L8.95807 24.5442L7.15234 26.3487L11.5176 30.7139L19.1961 23.0354L17.3916 21.231L11.5176 27.105Z"
        fill="white"
      />
      <path
        d="M33.2737 35.833H28.1546V33.2735H33.2737V17.9165H28.1546V7.6785C28.1531 6.32133 27.6133 5.02018 26.6536 4.06051C25.694 3.10084 24.3928 2.56103 23.0356 2.5595V0C25.0714 0.00226948 27.0232 0.811981 28.4627 2.25149C29.9022 3.691 30.7119 5.64274 30.7141 7.6785V15.357H33.2737C33.9523 15.3577 34.6029 15.6276 35.0827 16.1075C35.5625 16.5873 35.8324 17.2379 35.8332 17.9165V33.2735C35.8324 33.9521 35.5625 34.6027 35.0827 35.0826C34.6029 35.5624 33.9523 35.8323 33.2737 35.833Z"
        fill="white"
      />
      <path
        d="M23.0355 15.357H20.476V7.6785C20.476 5.64204 19.667 3.68898 18.227 2.24898C16.787 0.808983 14.834 0 12.7975 0C10.761 0 8.80798 0.808983 7.36798 2.24898C5.92799 3.68898 5.119 5.64204 5.119 7.6785V15.357H2.5595C1.88068 15.357 1.22966 15.6267 0.74966 16.1067C0.269661 16.5867 0 17.2377 0 17.9165V33.2735C0 33.9523 0.269661 34.6034 0.74966 35.0834C1.22966 35.5634 1.88068 35.833 2.5595 35.833H23.0355C23.7143 35.833 24.3654 35.5634 24.8454 35.0834C25.3254 34.6034 25.595 33.9523 25.595 33.2735V17.9165C25.595 17.2377 25.3254 16.5867 24.8454 16.1067C24.3654 15.6267 23.7143 15.357 23.0355 15.357ZM7.6785 7.6785C7.6785 6.32086 8.21783 5.01882 9.17782 4.05882C10.1378 3.09882 11.4399 2.5595 12.7975 2.5595C14.1552 2.5595 15.4572 3.09882 16.4172 4.05882C17.3772 5.01882 17.9165 6.32086 17.9165 7.6785V15.357H7.6785V7.6785ZM23.0355 33.2735H2.5595V17.9165H23.0355V33.2735Z"
        fill="white"
      />
    </SvgIcon>
  );
}
