const CopyIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"
        stroke="url(#paint0_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z"
        stroke="url(#paint1_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5996 2H15.5996"
        stroke="url(#paint2_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5C7 3.34 8.34 2 10 2H12.62"
        stroke="url(#paint3_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9995 8V14.19C21.9995 15.74 20.7395 17 19.1895 17"
        stroke="url(#paint4_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
        stroke="url(#paint5_linear_4206_29420)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4206_29420"
          x1="2"
          y1="14.5"
          x2="17"
          y2="14.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4206_29420"
          x1="10.5996"
          y1="10.2"
          x2="16.9996"
          y2="10.2"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4206_29420"
          x1="11.5996"
          y1="2.5"
          x2="15.5996"
          y2="2.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4206_29420"
          x1="7"
          y1="3.5"
          x2="12.62"
          y2="3.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4206_29420"
          x1="19.1895"
          y1="12.5"
          x2="21.9995"
          y2="12.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4206_29420"
          x1="16"
          y1="5"
          x2="22"
          y2="5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#664CCD" />
          <stop offset="1" stopColor="#8F5DE5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default CopyIcon;
