import React from 'react';

const DeploymentIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      {...props}>
      <path
        d="M9.85953 25.827H5.50851C5.0428 25.002 4.16461 24.4964 3.20659 24.4964C1.74294 24.4964 0.54541 25.6939 0.54541 27.1575C0.54541 28.6212 1.74294 29.8187 3.20659 29.8187C4.16461 29.8187 5.0428 29.3131 5.50851 28.4881H9.85953V25.827Z"
        fill="white"
      />
      <path
        d="M37.8024 11.1902C36.8443 11.1902 35.9661 11.6959 35.5004 12.5208H31.1494V15.182H35.5004C36.2323 16.4594 37.8689 16.8852 39.1329 16.1533C40.4103 15.4215 40.8361 13.7849 40.1043 12.5208C39.6253 11.6959 38.7471 11.1902 37.8024 11.1902Z"
        fill="white"
      />
      <path
        d="M33.0253 5.8679L27.1574 0L21.2896 5.8679L25.8269 10.4052V23.1656H28.488V10.4052L33.0253 5.8679ZM27.1574 3.75226L29.2731 5.8679L27.1574 7.98353L25.0418 5.8679L27.1574 3.75226Z"
        fill="white"
      />
      <path
        d="M15.1819 30.6034V17.8431H12.5207V30.6034L7.9834 35.1407L13.8513 41.0086L19.7192 35.1407L15.1819 30.6034ZM11.7357 35.1407L13.8513 33.0251L15.9669 35.1407L13.8513 37.2564L11.7357 35.1407Z"
        fill="white"
      />
      <path
        d="M23.1656 12.5208H10.4052L5.8679 7.98349L0 13.8514L5.8679 19.7193L10.4052 15.182H23.1656V12.5208ZM5.8679 15.967L3.75226 13.8514L5.8679 11.7358L7.98353 13.8514L5.8679 15.967Z"
        fill="white"
      />
      <path
        d="M16.5128 3.20662C16.5128 1.74297 15.3153 0.545441 13.8516 0.545441C12.388 0.545441 11.1904 1.74297 11.1904 3.20662C11.1904 4.16464 11.6961 5.04283 12.521 5.50854V9.85956H15.1822V5.50854C16.0072 5.04283 16.5128 4.16464 16.5128 3.20662Z"
        fill="white"
      />
      <path
        d="M28.4879 35.5002V31.1492H25.8267V35.5002C24.5493 36.2321 24.1235 37.8687 24.8554 39.1327C25.5872 40.4101 27.2238 40.8359 28.4879 40.1041C29.7519 39.3722 30.191 37.7356 29.4592 36.4716C29.2197 36.0724 28.887 35.7264 28.4879 35.5002Z"
        fill="white"
      />
      <path
        d="M41.0088 27.1575L35.1409 21.2896L30.6036 25.8269H17.8433V28.4881H30.6036L35.1409 33.0254L41.0088 27.1575ZM35.1409 25.0419L37.2566 27.1575L35.1409 29.2731L33.0253 27.1575L35.1409 25.0419Z"
        fill="white"
      />
    </svg>
  );
};

export default DeploymentIcon;
