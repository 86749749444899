import { notifyError } from './../toster';
import { LoginRequest, LoginUserDetails } from '../../models';

import axiosInstance from '../../hooks/useAxiosPrivate';
import { authDetailsReducer, getAuthState } from '../../store/reducers/authReducer';
import { APIRoutes, Providers } from '../../utils/constants';
import { AuthClient } from '../axios';
import store from '../../store/store';
import HttpService from '../Https-services';
const env = process.env;
const baseURL = env.REACT_APP_AUTH_API_BASE_URL || '';
import axios from 'axios';

export class AuthService {
  auth = store.getState().authData;

  async GetHealthCheck() {
    var status = false;
    try {
      var result = await axios.get(baseURL + APIRoutes.HealthCheck);
      if (result.status >= 200 && result.status < 300) {
        status = true;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
    }
    return status;
  }

  async Login(requestBody: LoginRequest) {
    let result = {} as authDetailsReducer;

    try {
      const response = await HttpService.post(baseURL, APIRoutes.Login, requestBody);
      if (response.status >= 200 && response.status < 300 && response.data) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
      return result;
    }

    return result;
  }

  async GetUserDetails() {
    let result = {} as LoginUserDetails;

    try {
      const response = await HttpService.get(baseURL, APIRoutes.GetUserDetails);
      if (response.data?.Github) {
        result = response.data.Github;
        result.Provider = Providers.Github;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
      return result;
    }

    return result;
  }

  async CheckAppInstallationStatus() {
    let result: boolean | null = null;
    try {
      const response = await HttpService.get(baseURL, APIRoutes.CheckAppInstallationStatus);
      if (response.status >= 200 && response.status < 300) {
        result = response.data;
      }
    } catch (error: any) {
      notifyError(error.response?.data?.error);
      return result;
    }

    return result;
  }
}
