import React, { useEffect, useState } from 'react';
import { BuildSettingForm, Framework } from '../..';
import { BuildService } from '../../../../services/API/BuildService';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';

const BuildndeployConfigs = (props: BuildSettingForm) => {
  const buildAPIService = new BuildService();
  const [frameworkList, setFrameworkList] = useState<Array<Framework>>();

  const getFrameworks = async () => {
    const res = await buildAPIService.GetFrameworkList();
    if (props.Framework && props.Framework.Name) {
      let languageIdx = res.findIndex((item) => item.Name === props.Framework?.Name);
      if (languageIdx > -1) {
        res[languageIdx].Settings = props.Framework.Settings;
      }
    }
    setFrameworkList(res);
  };

  useEffect(() => {
    getFrameworks();
  }, []);

  const handleCommandChange = (commandName: string, commandText: string) => {
    let newFramework: any = props.Framework;
    newFramework.Settings[commandName].OverrideValue = commandText;
    props.onChange('Framework', newFramework);
  };

  const handleOverrideChange = (commandName: string, isOverride: boolean) => {
    let newFramework: any = props.Framework;
    newFramework.Settings[commandName].IsOverride = isOverride;
    props.onChange('Framework', newFramework);
  };

  return (
    <>
      <Grid item xs={12} md={6} sx={{ width: 1 }}>
        <FormControl fullWidth>
          <InputLabel shrink id="demo-simple-select-label">
            Framework
          </InputLabel>
          <Select
            notched
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.Framework?.Name || ''}
            label="Framework"
            onChange={(event) => {
              const framework = frameworkList?.find(
                (item) => item.Name === event.target.value
              ) as Framework;
              props.onChange('Framework', framework);
            }}>
            {frameworkList?.map((framework, _) => (
              <MenuItem value={framework.Name} key={framework.Name}>
                {framework.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {props.Framework && props.Framework.Name && (
        <>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: 1 }}
            display={props.Framework?.Settings.BuildCommand.IsRequired ? 'block' : 'none'}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Build Command"
              placeholder={props.Framework?.Settings.BuildCommand.Value}
              disabled={!props.Framework?.Settings.BuildCommand.IsOverride as boolean}
              id="BuildCommand"
              onChange={(event) => {
                handleCommandChange('BuildCommand', event.target.value);
              }}
              value={props.Framework?.Settings.BuildCommand.OverrideValue}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'BuildCommand'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Switch
                      checked={props.Framework?.Settings.BuildCommand.IsOverride as boolean}
                      onChange={(event) =>
                        handleOverrideChange('BuildCommand', event.target.checked)
                      }
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: 1 }}
            display={props.Framework?.Settings.RootDirectory.IsRequired ? 'block' : 'none'}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Root Directory"
              placeholder={props.Framework?.Settings.RootDirectory.Value}
              disabled={!props.Framework?.Settings.RootDirectory.IsOverride as boolean}
              id="RootDirectory"
              onChange={(event) => {
                handleCommandChange('RootDirectory', event.target.value);
              }}
              value={props.Framework?.Settings.RootDirectory.OverrideValue}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'RootDirectory'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Switch
                      checked={props.Framework?.Settings.RootDirectory.IsOverride as boolean}
                      onChange={(event) =>
                        handleOverrideChange('RootDirectory', event.target.checked)
                      }
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: 1 }}
            display={props.Framework?.Settings.OutputDirectory.IsRequired ? 'block' : 'none'}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Output Directory"
              placeholder={props.Framework?.Settings.OutputDirectory.Value}
              disabled={!props.Framework?.Settings.OutputDirectory.IsOverride as boolean}
              id="OutputDirectory"
              onChange={(event) => {
                handleCommandChange('OutputDirectory', event.target.value);
              }}
              value={props.Framework?.Settings.OutputDirectory.OverrideValue}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'OutputDirectory'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Switch
                      checked={props.Framework?.Settings.OutputDirectory.IsOverride as boolean}
                      onChange={(event) =>
                        handleOverrideChange('OutputDirectory', event.target.checked)
                      }
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: 1 }}
            display={props.Framework?.Settings.InstallCommand.IsRequired ? 'block' : 'none'}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Install Command"
              placeholder={props.Framework?.Settings.InstallCommand.Value}
              disabled={!props.Framework?.Settings.InstallCommand.IsOverride as boolean}
              id="InstallCommand"
              onChange={(event) => {
                handleCommandChange('InstallCommand', event.target.value);
              }}
              value={props.Framework?.Settings.InstallCommand.OverrideValue}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'InstallCommand'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Switch
                      checked={props.Framework?.Settings.InstallCommand.IsOverride as boolean}
                      onChange={(event) =>
                        handleOverrideChange('InstallCommand', event.target.checked)
                      }
                    />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ width: 1 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Expose Port"
              placeholder={props.Framework?.Settings.ExposePorts.join(',')}
              id="ExposePorts"
              onChange={(event) => {
                let newFramework: any = props.Framework;
                newFramework.Settings.ExposePorts = event.target.value.split(',');
                props.onChange('Framework', newFramework);
              }}
              value={props.Framework?.Settings.ExposePorts.join(',')}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'ExposePorts'
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ width: 1 }}
            display={props.Framework?.Settings.RunCommand.IsRequired ? 'block' : 'none'}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              label="Run Command"
              placeholder={props.Framework?.Settings.RunCommand.Value}
              disabled={!props.Framework?.Settings.RunCommand.IsOverride as boolean}
              id="RunCommand"
              onChange={(event) => {
                handleCommandChange('RunCommand', event.target.value);
              }}
              value={props.Framework?.Settings.RunCommand.OverrideValue}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'RunCommand'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Switch
                      checked={props.Framework?.Settings.RunCommand.IsOverride as boolean}
                      onChange={(event) => handleOverrideChange('RunCommand', event.target.checked)}
                    />
                  </InputAdornment>
                )
              }}
            />
            <Typography fontSize={12} mt={2}>
              * if your code generates a binary &quot;binary.extension&quot; in the root folder then
              your run command should be something like &quot;/app/binary.extension&quot;, /app will
              always be the root
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default BuildndeployConfigs;
