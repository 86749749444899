const Style = {
  BreadcrumbWrapper: {
    // mx: 'auto',
    // width: 1,
    // paddingBottom: 3,
    maxWidth: 1209,
    ml: 2
  },
  Breadcrumbs: {
    cursor: 'pointer',

    fontSize: 16,
    fontWeight: 400
  }
};

export default Style;
