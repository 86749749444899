import { Grid, Skeleton } from '@mui/material';
export default function SkeletonConfig() {
  return (
    <Grid item xs={12} md={6} sx={{ width: 1 }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        sx={{ borderRadius: '4px' }}
        height={60}
      />
    </Grid>
  );
}
