const Style = {
  DashboardWrapper: {
    mx: 'auto',
    width: 1,
    maxWidth: 800
  },
  Heading: {
    color: 'common.blackText',
    textAlign: 'center',
    mb: { xxs: 3, md: 4, lg: 5 }
  },
  AddProject: {
    padding: '15px',
    width: { sm: 1 }
  },
  ProjectList: {
    mt: 3,
    p: 1.5,
    border: '1px solid',
    borderColor: 'action.focus',
    borderRadius: 2,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  UserBox: {
    display: 'flex',
    alignItems: 'center'
  },
  UserThumb: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    overflow: 'hidden',
    mr: 1,
    img: {
      width: 1,
      height: 1,
      objectFit: 'cover'
    }
  },
  CommitBoxWrapper: {
    mt: { xxs: 2, xs: 'auto' },
    width: { xxs: 1, xs: 'auto' }
  },
  TabButtonWrapper: {
    '.MuiButtonBase-root': {
      textTransform: 'capitalize'
    }
  }
};

export default Style;
