import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginUserDetails } from '../../models';
import { RootState } from '../store';

export type authDetailsReducer = {
  access_token: string;
  refresh_token: string;
  state: string;
  user_details: LoginUserDetails;
  provider: string;
  appInstallationStatus?: boolean | null;
  isVscodeLogin?: boolean | null;
};

const initialState = {} as authDetailsReducer;

export const authDetailSlice = createSlice({
  name: 'authDetails',
  initialState: initialState,
  reducers: {
    setAuthDetails: function (
      state: authDetailsReducer,
      action: PayloadAction<authDetailsReducer>
    ) {
      state = { ...state, ...action.payload };
      return state;
    },
    logout: (state) => {
      return state;
    }
  }
});

export const getAuthState = (state: RootState) => state.authData;

export const { setAuthDetails, logout } = authDetailSlice.actions;

export default authDetailSlice.reducer;
