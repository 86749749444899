import { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import CloseIcon from '../../assets/icons/Close';
import LockIcon from '../../assets/icons/Lock';
import { default as Github, default as GithubLogo } from '../../assets/images/github.svg';
import { RepositoryListItem } from '../../models';
import { PageRoutes } from '../../utils/constants';

import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddCircleBlueIcon from '../../assets/icons/AddCircleBlue';
import NoRepositoryFound from '../../components/shared/NoRepositoryFound';
import { ProjectService } from '../../services/API/ProjectService';
import { getAuthState } from '../../store/reducers/authReducer';
import {
  resetRepositoryDetails,
  setRepositoryDetails
} from '../../store/reducers/configureProjectReducer';
import { RootState } from '../../store/store';
import Style from './Repository.style';
import { SkeletonRepository } from './SkeletonRepository';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { notifyError } from '../../services/toster';
var debouce = require('lodash.debounce');

// TODO:
// THIS CALL IS VERY EXPENSIVE AND RATE LIMITED BY GITHUB, GITLAB ETC
// SO THE BACKEND NEEDS TO PROPERLY CACHE.
// THIS SHOULD ALSO BE A MODAL RATHER THAN A SEPARATE PAGE.
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Repository: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('select_repository_page');
  const [repoList, setRepoList] = useState<RepositoryListItem[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(false);

  const [open, setOpen] = useState(false);
  const [openForGetRepo, setOpenForGetRepo] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const [currentNamespace, setCurrentNamespace] = useState(auth.user_details.Alias);
  const [namespaces, setNamespaces] = useState<
    {
      name: string;
      avatar: string;
    }[]
  >([
    {
      name: auth.user_details.Alias,
      avatar: auth.user_details.AvatarURL
    }
  ]);
  const ref = useRef(auth.user_details.Alias);
  const refNameSpace = useRef<
    {
      name: string;
      avatar: string;
    }[]
  >([]);
  const refType = useRef(false);

  const projectService = new ProjectService();
  const handleRepoSearch = (event: any) => {
    refType.current = true;
    if (event.target.value?.length >= 2) {
      GetListRepos(event.target.value);
    } else {
      if (event.target.value?.length == 0) {
        GetListRepos();
        refType.current = false;
      }
    }
  };

  const handleItemClick = (item: RepositoryListItem) => {
    if (item.default_branch.includes('/')) {
      notifyError("We are not supporting branch name having slash '/'");
    } else {
      gaEventTracker('repo_selected');
      dispatch(setRepositoryDetails(item));
      navigate(PageRoutes.ProjectConfigure);
    }
  };

  async function GetListRepos(searchParams?: string) {
    setIsSkeleton(true);
    const repos = await projectService.ListGithubRepos(searchParams, ref.current);
    setRepoList(repos);

    if (refNameSpace.current.length != 2 && !refType.current && repos?.length == 0) {
      setOpenForGetRepo(true);
    }
    setIsSkeleton(false);
  }

  const debouncedResults = useMemo(() => {
    return debouce(handleRepoSearch, 500);
  }, []);

  const handleNamespaceSelect = (e: any) => {
    setCurrentNamespace(e.target.value);
    ref.current = e.target.value;
    GetListRepos();
  };

  async function GetListGithubOrganisations() {
    const orgs = await projectService.ListGithubOrganisations();
    if (orgs?.length > 0) {
      const item = [...namespaces];
      const cbitems = item.concat(orgs);
      refNameSpace.current = cbitems;
      setNamespaces(cbitems);
    }
    GetListRepos();
  }
  useEffect(() => {
    let isOrgParams = searchParams.get('isOrg');
    if (isOrgParams) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 6000);
      searchParams.delete('isOrg');
    }
  }, []);

  useEffect(() => {
    dispatch(resetRepositoryDetails());
    GetListGithubOrganisations();
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          position: { xxs: 'inherit', sm: 'sticky' },
          textAlign: 'right',
          paddingRight: '10px',
          top: '90px'
        }}>
        <CloseIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(PageRoutes.SelectProjectType);
          }}
        />
      </Box>
      <Typography variant="h2" sx={Style.Heading}>
        Select a repository to configure the project
      </Typography>
      <Box sx={{ display: 'flex', width: 1, mx: 'auto', maxWidth: 600, gap: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} sx={{ ...Style.DropDownBox, mb: 0.5 }}>
            <FormControl sx={{ width: 1 }} variant="outlined">
              <Select
                sx={Style.BranchSelect}
                value={currentNamespace || ''}
                onChange={handleNamespaceSelect}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}>
                {namespaces.map((element, index) => (
                  <MenuItem key={index + 1} value={element.name}>
                    <img
                      width={32}
                      src={element.avatar || GithubLogo}
                      alt="githubLogo"
                      style={{
                        borderRadius: '50%'
                      }}
                    />
                    <Typography ml={'8px'}>{element.name}</Typography>
                  </MenuItem>
                ))}
                <Divider />
                <Box sx={{ ml: 1 }}>
                  <Button
                    color="secondary"
                    onClick={() =>
                      (location.href = `https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new?state=repository`)
                    }>
                    <Box sx={{ width: '32px' }}>
                      {' '}
                      <AddCircleBlueIcon />
                    </Box>{' '}
                    <Typography sx={{ color: '#1E1E1E', ml: 1 }} variant="body1">
                      Add GitHub Account
                    </Typography>
                  </Button>
                </Box>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} sx={Style.SearchBox}>
            <FormControl sx={{ width: 1 }} variant="outlined">
              <TextField
                label="Search repository"
                variant="outlined"
                id="search"
                autoComplete="off"
                onChange={debouncedResults}
                aria-describedby="search"
                inputProps={{
                  'aria-label': 'search'
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ maxWidth: 600, width: 1, m: 'auto' }}>
        <Collapse in={open}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                {' '}
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}>
            <Typography>
              If you are not organization owner please contact owner to accept the request
            </Typography>
          </Alert>
        </Collapse>
      </Box>
      <Box sx={{ maxWidth: 600, width: 1, m: 'auto' }}>
        <Collapse in={openForGetRepo}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenForGetRepo(false);
                }}>
                {' '}
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}>
            <Typography>
              If you are not able to see your repository here{' '}
              <span
                style={{ fontWeight: 500, cursor: 'pointer', textDecoration: 'underLine' }}
                onClick={() =>
                  (location.href = `https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new?state=repository`)
                }>
                install github apps
              </span>
            </Typography>
          </Alert>
        </Collapse>
      </Box>

      {isSkeleton ? (
        <Box
          sx={{
            display: 'flex',
            width: 1,
            maxWidth: 600,
            mt: 2,
            flexDirection: 'column',
            justifyContent: 'center',
            mx: 'auto'
          }}>
          <SkeletonRepository />
          <SkeletonRepository />
          <SkeletonRepository />
          <SkeletonRepository />
          <SkeletonRepository />
        </Box>
      ) : (
        <List disablePadding sx={Style.ListItemWrapper}>
          {repoList.map((Element, index) => (
            <ListItem
              disabled={Element.configured ? true : false}
              key={index}
              disablePadding
              onClick={() => {
                if (Element.configured) return;
                handleItemClick(Element);
              }}
              sx={{
                ...Style.ListItem,
                // opacity: `${!Element.private && '0.5'}`,
                // pointerEvents: `${!Element.private && 'none'}`,
                cursor: 'pointer'
              }}>
              <Box sx={Style.ListItemLeftPart}>
                <Box sx={Style.ThumbImage}>
                  <img src={Github} alt="thumb" />
                </Box>
                <Box sx={{ maxWidth: { xxs: 'calc(100% - 38px)', md: 'calc(100% - 64px)' } }}>
                  <Typography variant="body1" sx={Style.ListItemName}>
                    {Element.name}
                    {Element.private && <LockIcon />}
                  </Typography>
                  <Typography variant="caption">
                    {Element?.description?.substring(0, 40)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption" component="div" sx={Style.TechnologiesText}>
                <Box>{Element.technologies}</Box>
                <Box>
                  {Element.technologies && (
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        ml: 0.5,
                        height: 8,
                        width: 8,
                        borderRadius: '50%',
                        bgcolor: `${Element.technologiesColor}`
                      }}></Box>
                  )}
                </Box>
              </Typography>
            </ListItem>
          ))}
          {repoList.length === 0 && !isSkeleton && (
            <NoRepositoryFound message="Repository not found" />
          )}
        </List>
      )}
    </Container>
  );
};
