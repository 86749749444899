import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ExportIcon from '../../assets/icons/Export';
import SettingsMenuIcon from '../../assets/icons/SettingsMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ProjectService } from '../../services/API/ProjectService';
import { DefaultPagination, PageRoutes } from '../../utils/constants';
import Style from './RepositoryDetails.style';

import moment from 'moment';
import PropTypes from 'prop-types';
import InitialLogo from '../../assets/icons/InitialLogo';
import GithubLogo from '../../assets/images/github.svg';
import {
  BuildResponse,
  BuildServiceBranchConfigResponse,
  DeployInfo,
  DeployServiceBranchConfigResponse,
  PaginationHelper,
  Project
} from '../../models';
import { BuildService } from '../../services/API/BuildService';
import { DeployService } from '../../services/API/DeployService';
import { notifyError, notifySuccess } from '../../services/toster';
import { language } from '../../utils/language';
import { ApplicationLogs } from './RepositoryDetailsTab/ApplicationLogs';
import { History } from './RepositoryDetailsTab/History';
import { SkeletonHistory } from './SkeletonHistory';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import AddCircleBlueIcon from '../../assets/icons/AddCircleBlue';
import { confirmDialog } from '../../components/shared/ConfirmDialog';
import { EllipsisContent } from '../../components/shared/EllipsisContent';
import { AddLink, Launch, LinkOff } from '@mui/icons-material';
import GitHubIcon from '../../assets/icons/Github';
import TrashIcon from '../../assets/icons/Trash';
import BranchIcon from '../../assets/icons/BranchIcon';

export const RepositoryDetails: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('project_details');
  const navigate = useNavigate();
  const [branch, setBranch] = useState('');
  const [languageLogo, setLanguageLogo] = useState({ languageName: '', logo: <InitialLogo /> });

  const [projectDetails, setProjectDetails] = useState<Project>({} as Project);

  const [listBuildBranchConfig, setListBuildBranchConfigs] = useState<
    BuildServiceBranchConfigResponse[]
  >([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [letestBuildBranchConfig, setLetestBuildBranchConfig] =
    useState<BuildServiceBranchConfigResponse>({} as BuildServiceBranchConfigResponse);
  const [letestDeployBranchConfig, setLetestDeployBranchConfig] =
    useState<DeployServiceBranchConfigResponse>({} as DeployServiceBranchConfigResponse);

  const [listBuilds, setListBuilds] = useState<BuildResponse[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isSkeletonHistory, setIsSkeletonHistory] = useState(true);
  const [latestDeployment, setLatestDeployment] = useState<DeployInfo>();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const projectService = new ProjectService();
  const buildAPIService = new BuildService();
  const deployAPIService = new DeployService();
  let checkTimer: any;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [pagination, setpagination] = useState<PaginationHelper>({
    pageSize: 5,
    currentPage: DefaultPagination.Page
  } as PaginationHelper);

  const handleNavigateConfiguration = () => {
    if (searchParams.get('alias') != null) {
      navigate({
        pathname: PageRoutes.DetectingLogs.replace(':id', projectDetails.Name),
        search: createSearchParams({
          branch: branch,
          alias: searchParams.get('alias') || ''
        }).toString()
      });
    } else {
      navigate({
        pathname: PageRoutes.DetectingLogs.replace(':id', projectDetails.Name),
        search: createSearchParams({
          branch: branch
        }).toString()
      });
    }
  };

  function relativeCreatedTime(): any {
    // Taking create at time as branch config is created

    // if (latestDeployment.Service?.StartedAt) {
    //   return moment(latestDeployment.Service.StartedAt).fromNow();
    // }

    // return moment(latestDeployment.SubmittedAt).fromNow();

    if (!latestDeployment?.Status) return '-';

    if (letestBuildBranchConfig?.CreatedAt) {
      return moment(letestBuildBranchConfig.CreatedAt).fromNow();
    }

    return '-';
  }

  async function getProjectById(id: string) {
    let response = await projectService.GetProjectById(id, searchParams.get('alias'));
    if (!response?.ID) {
      navigate(PageRoutes.Dashboard);
    }
    setProjectDetails(response);
    await handleListBranchConfig(response.Name);
  }

  async function handleListBranchConfig(projectName: string) {
    const buildResult = await buildAPIService.ListBranchConfig(
      projectName,
      searchParams.get('alias')
    );
    setListBuildBranchConfigs(buildResult);

    if (buildResult.length > 0) {
      let branch = searchParams.get('branch');
      handleChangeNewBranch(projectName, branch ? branch : buildResult[0].Branch);
    }
  }

  const handleChange = (event: any) => {
    if (event.target.value != undefined) {
      setBranch(event.target.value);
      searchParams.set('branch', event.target.value);
      setSearchParams(searchParams);
      handleChangeNewBranch(projectDetails.Name, event.target.value);
    } else {
      if (searchParams.get('alias')) {
        navigate(
          PageRoutes.ManageBranchConfiguration.replace(':id', projectDetails.Name) +
            `?alias=${searchParams.get('alias')}`
        );
      } else {
        navigate(PageRoutes.ManageBranchConfiguration.replace(':id', projectDetails.Name));
      }
    }
  };

  async function handleGetBuildBranchConfigDetails(projectName: string, branchName: string) {
    const result = await buildAPIService.GetProjectBranchConfig(
      projectName,
      branchName,
      searchParams.get('alias')
    );

    if (result.Branch) {
      setBranch(result.Branch);
      setLetestBuildBranchConfig(result);
      handleSetLanguage(result.Language);
    }
  }

  function handleChangeNewBranch(projectName: string, branchName: string) {
    GetListBuilds(pagination, projectName, branchName);
    handleGetBuildBranchConfigDetails(projectName, branchName);
    handleGetDeployBranchConfigDetails(projectName, branchName);
    GetServiceDeployment(projectName, branchName);
  }

  async function handleGetDeployBranchConfigDetails(projectName: string, branchName: string) {
    const result = await deployAPIService.GetProjectBranchConfig(
      projectName,
      branchName,
      searchParams.get('alias')
    );
    setIsSkeleton(false);
    if (result.Branch) {
      setLetestDeployBranchConfig(result);
    }
  }

  const handleSetLanguage = (languageName: string) => {
    const result = language.filter(
      (Element, id) => Element?.languageName?.toLowerCase() === languageName?.toLowerCase()
    );

    if (result.length != 0) {
      setLanguageLogo({
        languageName: result[0]?.languageName,
        logo: result[0]?.logo
      });
    } else {
      setLanguageLogo({
        languageName: '',
        logo: <InitialLogo />
      });
    }
  };

  async function GetListBuilds(
    paginationParams: PaginationHelper,
    projectName: string,
    branchName: string
  ) {
    setIsSkeletonHistory(true);
    const result = await buildAPIService.ListBuild(
      projectName,
      branchName,
      searchParams.get('alias'),
      {
        ...paginationParams
      }
    );
    if (result?.results.length > 0) {
      if (result?.results[0].Status != 'running') {
        setButtonDisabled(false);
      } else {
        if (!buttonDisabled) {
          startBuildRunningWatch(projectName, branchName, result?.results[0]?.ID);
        }
      }
      let item = pagination;
      item.totalPages = Math.ceil(result.totalResults / pagination.pageSize);
      item.totalCount = result.totalResults;
      setpagination({ ...item });

      setListBuilds(result.results);
    } else {
      setListBuilds([]);
    }
    setIsSkeletonHistory(false);
  }

  async function StartBuild(
    projectName: string = projectDetails.Name,
    branchName: string = branch
  ) {
    setButtonDisabled(true);
    setIsSkeletonHistory(true);
    const result = await buildAPIService.StartBuild(
      projectName,
      branchName,
      searchParams.get('alias')
    );

    if (result?.ID) {
      notifySuccess('Build started successfully');

      setTimeout(() => {
        setIsSkeletonHistory(false);
        startBuildRunningWatch(projectName, branchName, listBuilds[0]?.ID);
        GetServiceDeployment(projectName, branchName);
        GetListBuilds(pagination, projectName, branchName);
      }, 4000);
    } else {
      setIsSkeletonHistory(false);
      notifyError('Error while starting the build');
      setButtonDisabled(false);
    }
  }

  async function GetServiceDeployment(projectName: string, branchName: string) {
    var result = await deployAPIService.GetServiceDeployment(
      projectName,
      branchName,
      searchParams.get('alias')
    );
    setLatestDeployment(result);
  }

  useEffect(() => {
    if (typeof id != 'undefined') {
      getProjectById(id);
    } else {
      navigate(PageRoutes.Dashboard);
    }
  }, []);

  useEffect(() => {
    let isNew = searchParams.get('isNew');
    let branchName = searchParams.get('branch');
    if (isNew && branchName && id) {
      gaEventTracker('success_first_build_triggered');
      StartBuild(id, branchName);
    }
    searchParams.delete('isNew');
    setSearchParams(searchParams);
    return () => {
      stopBuildRunningWatch();
    };
  }, []);

  async function checkBuildComplete(projectName: string, branchName: string, buildId: string) {
    var buildResult = await buildAPIService.GetBuild(
      projectName,
      branchName,
      searchParams.get('alias'),
      buildId
    );
    if (buildResult.Status != 'running') {
      var result = await deployAPIService.GetServiceDeployment(
        projectName,
        branchName,
        searchParams.get('alias')
      );
      GetListBuilds(pagination, projectName, branchName);
      stopBuildRunningWatch();
      setLatestDeployment(result);
    }
  }

  function startBuildRunningWatch(projectName: string, branchName: string, buildId: string) {
    clearInterval(checkTimer);
    checkTimer = setInterval(() => {
      checkBuildComplete(projectName, branchName, buildId);
    }, 7000); // 7s
  }

  function stopBuildRunningWatch() {
    clearInterval(checkTimer);
  }

  //#region Tab Helper Function

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`repository-tabpanel-${index}`}
        aria-labelledby={`repository-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function TabSequence(index: any) {
    return {
      id: `repository-tab-${index}`,
      'aria-controls': `repository-tabpanel-${index}`
    };
  }

  //#endregion
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleDeleteProject() {
    confirmDialog(
      `Delete Project`,
      `Are you sure want to delete ${projectDetails.Name} project ?`,
      async () => {
        setLoader(true);
        var result = await projectService.DeleteProject(
          projectDetails.Name,
          searchParams.get('alias')
        );
        if (result) {
          notifySuccess('Project deleted successfully');
          setTimeout(() => {
            navigate(PageRoutes.Dashboard);
            setLoader(false);
          }, 1000);
        }
      },
      handleClose
    );
  }

  function onPageChange(p: PaginationHelper) {
    let item = pagination;
    item.currentPage = p.currentPage;
    setpagination({ ...item });
    GetListBuilds(pagination, projectDetails.Name, branch);
  }

  async function SyncRepositoryCollaborator() {
    var syncResult = await projectService.SyncRepositoryCollaborator(
      projectDetails.Name,
      searchParams.get('alias')
    );
    if (syncResult) {
      notifySuccess('Repository collaborators synced');
    }
  }

  return (
    <Container maxWidth="xl">
      <Box sx={Style.DetailsHeader}>
        <Box>
          {' '}
          <EllipsisContent
            variant="caption"
            sx={{
              fontSize: 18,
              fontWeight: '600',
              color: 'common.blackText',
              width: 'max-content',
              maxWidth: {
                xxs: 280,
                sm: 180,
                md: 300,
                lg: 400
              }
            }}
            text={id || ''}
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: {
                xxs: 1.5,
                sm: 2
              },

              alignItem: 'center'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: { xxs: 1.5, md: 2 } }}>
              <Box>
                <FormControl sx={{ minWidth: 120, maxWidth: 250 }} variant="standard">
                  {isSkeleton ? (
                    <Box sx={{ ...Style.BranchSelect }}>
                      <Skeleton sx={{ width: 1 }} variant="rounded" height={32} />
                    </Box>
                  ) : (
                    <>
                      <Select
                        sx={Style.BranchSelect}
                        value={branch}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}>
                        {listBuildBranchConfig.map((element, index) => (
                          <MenuItem value={element.Branch} key={index} sx={{ maxWidth: 250 }}>
                            <BranchIcon />
                            <EllipsisContent ml={'8px'} text={element.Branch} />
                          </MenuItem>
                        ))}
                        <Divider />
                        <Button
                          color="secondary"
                          sx={{ px: 2 }}
                          onClick={() =>
                            searchParams.get('alias')
                              ? navigate(
                                  PageRoutes.ManageBranchConfiguration.replace(
                                    ':id',
                                    projectDetails.Name
                                  )
                                ) + `?alias=${searchParams.get('alias')}`
                              : navigate(
                                  PageRoutes.ManageBranchConfiguration.replace(
                                    ':id',
                                    projectDetails.Name
                                  )
                                )
                          }>
                          <Box sx={{ width: '24px', height: '24px' }}>
                            <AddCircleBlueIcon />
                          </Box>
                          <Typography sx={{ color: '#1E1E1E', ml: 1 }} variant="body1">
                            Add new branch
                          </Typography>
                        </Button>
                      </Select>
                    </>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
              <Box>
                <Button
                  onClick={() => StartBuild()}
                  disabled={
                    listBuilds[0]?.Status === 'running' || isSkeletonHistory || buttonDisabled
                  }
                  sx={{
                    '&.Mui-disabled': {
                      background: '#C7D8EA',
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important'
                    }
                  }}
                  variant="contained"
                  color="secondary"
                  size="large">
                  Deploy
                </Button>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Button
                  onClick={() => SyncRepositoryCollaborator()}
                  disabled={isSkeletonHistory}
                  sx={{
                    '&.Mui-disabled': {
                      background: '#C7D8EA',
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important'
                    }
                  }}
                  variant="contained"
                  color="secondary"
                  size="large">
                  Sync Collaborator
                </Button>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Tooltip arrow title={'More Settings'} placement={'top'}>
                  <Box>
                    <Button
                      id="menu-button"
                      aria-controls={open ? 'header-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      disabled={isSkeleton || loader}
                      sx={Style.SettingButton}>
                      <MoreVertIcon sx={{ m: 1 }} />
                    </Button>
                    <Menu
                      id="header-menu"
                      aria-labelledby="menu-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: Style.MenuDropDown
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                      <MenuItem sx={{ gap: 2, my: 1 }}>
                        <Link
                          sx={{
                            textDecoration: 'none',
                            color: 'common.grey',
                            display: 'flex',
                            gap: 2
                          }}
                          href={`https://${projectDetails.Source?.replace(
                            '.git',
                            ''
                          )}/tree/${branch}`}
                          rel="noreferrer"
                          target="_blank">
                          <GitHubIcon sx={{ fill: 'none' }} />
                          Git Repository
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleNavigateConfiguration} sx={{ gap: 2, my: 1 }}>
                        <SettingsMenuIcon sx={{ fill: 'none' }} />
                        Settings
                      </MenuItem>
                      {searchParams.get('alias') == null && (
                        <MenuItem
                          onClick={handleDeleteProject}
                          sx={{
                            color: 'error.main',
                            gap: 2,
                            my: 1,
                            pt: 2,
                            borderTop: '1px solid #DADADA'
                          }}>
                          <TrashIcon sx={{ fill: 'none' }} />
                          Delete Project
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={Style.OverviewCard}>
        <Box sx={Style.OverviewBox}>
          <Grid item xs={6} md={5}>
            <Box sx={Style.OverviewImageBox}>
              <Box sx={{ width: 150, height: 150 }}>{languageLogo.logo}</Box>
            </Box>
          </Grid>
          <Box sx={Style.OverViewStatus}>
            <Grid item xs={6} md={7} sx={{ ml: { xxs: 0, sm: 3 }, width: { xxs: 1, sm: 'auto' } }}>
              <Box sx={Style.OverviewContentBox}>
                <Box sx={Style.ContentLabel}>Deployment</Box>
                {isSkeleton ? (
                  <Skeleton
                    sx={{ mr: { xxs: 0, sm: 1 }, width: 0.53, minWidth: 250 }}
                    variant="rounded"
                    height={22}
                  />
                ) : (
                  <>
                    {letestDeployBranchConfig?.FQDNs?.length > 0 ? (
                      <Box sx={{ display: 'flex' }}>
                        <EllipsisContent
                          variant="caption"
                          sx={{
                            color: 'common.blackText',
                            width: 'max-content',
                            maxWidth: {
                              xxs: 240,
                              xs: 300
                            }
                          }}
                          text={letestDeployBranchConfig?.FQDNs[0]}
                        />
                        <a
                          href={`https://${letestDeployBranchConfig?.FQDNs[0]}`}
                          rel="noopener noreferrer"
                          target="_blank">
                          <ExportIcon
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            sx={{ fill: 'none', mx: 0.5, verticalAlign: 'middle' }}
                          />
                        </a>
                      </Box>
                    ) : (
                      <Typography variant="caption" sx={{ color: 'common.blackText' }}>
                        No Deployment Set Yet
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  width: 0.8,
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  flexDirection: {
                    xxs: 'column',
                    sm: 'row'
                  }
                }}>
                <Box>
                  <Box sx={Style.ContentLabel}>Domains</Box>

                  {isSkeleton ? (
                    <Skeleton
                      sx={{ mt: 1.5, width: 320, minWidth: 250 }}
                      variant="rounded"
                      height={22}
                    />
                  ) : (
                    <>
                      {letestDeployBranchConfig?.CustomDomains?.length > 0 ? (
                        <>
                          {letestDeployBranchConfig.CustomDomains.map((el, index) => (
                            <Box sx={{ display: 'flex' }} key={index + 1}>
                              <EllipsisContent
                                variant="caption"
                                sx={{
                                  color: 'common.blackText',
                                  width: 'max-content',
                                  maxWidth: {
                                    xxs: 240,
                                    xs: 300
                                  }
                                }}
                                text={el?.Domain}
                              />
                              <a
                                href={`https://${el.Domain}`}
                                rel="noopener noreferrer"
                                target="_blank">
                                <ExportIcon
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  sx={{ fill: 'none', mx: 0.5, verticalAlign: 'middle' }}
                                />
                              </a>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <Typography variant="caption" sx={{ color: 'common.blackText' }}>
                          No Domain Set Yet
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
            <Box sx={{ width: { xxs: 1, sm: 'auto' }, ml: { xxs: 0, sm: 3, md: 0 } }}>
              <Box
                sx={{
                  display: 'flex',
                  width: { xxs: 1, sm: 'auto' },
                  gap: {
                    xxs: 0,
                    sm: 7
                  },
                  alignItems: {
                    xxs: 'center',
                    sm: 'flex-start'
                  },
                  flexDirection: {
                    xxs: 'column',
                    sm: 'row'
                  },
                  my: 2
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: { xxs: 1, sm: 'auto' },
                    flexDirection: { xxs: 'row', sm: 'column' },
                    justifyContent: 'space-between',
                    my: { xxs: 2, md: 0 },
                    alignItems: 'center'
                  }}>
                  <Box sx={Style.ContentLabel}>Status</Box>
                  <Button variant="contained" disableElevation sx={Style.StatusButton}>
                    {latestDeployment?.Status
                      ? listBuilds[0]?.Status == 'running'
                        ? 'Pending'
                        : latestDeployment?.Status
                      : '-'}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: { xxs: 1, sm: 'auto' },
                    flexDirection: { xxs: 'row', sm: 'column' },
                    justifyContent: 'space-between',
                    my: { xxs: 2, md: 0 },
                    alignItems: 'center'
                  }}>
                  <Box sx={Style.ContentLabel}>Created</Box>
                  <Typography variant="caption" sx={{ color: 'common.blackText', mt: 1 }}>
                    {relativeCreatedTime()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: { xxs: 1, sm: 'auto' },
                    flexDirection: { xxs: 'row', sm: 'column' },
                    justifyContent: 'space-between',
                    my: { xxs: 2, md: 0 },
                    alignItems: 'center'
                  }}>
                  <Box sx={Style.ContentLabel}>Updated</Box>
                  <Typography variant="caption" sx={{ color: 'common.blackText', mt: 1 }}>
                    {latestDeployment?.SubmittedAt
                      ? moment(latestDeployment?.SubmittedAt).fromNow()
                      : '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          mt: 4,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={Style.TabButtonWrapper}
          textColor="secondary"
          indicatorColor="secondary">
          <Tab label="History" {...TabSequence(0)} />
          <Tab label="Application Logs" {...TabSequence(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {isSkeletonHistory ? (
          <Box>
            <SkeletonHistory />
            <SkeletonHistory />
            <SkeletonHistory />
            <SkeletonHistory />
            <SkeletonHistory />
          </Box>
        ) : (
          <History
            items={listBuilds}
            onPageChange={(a) => onPageChange(a)}
            pagination={pagination}
          />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ApplicationLogs
          projectName={projectDetails.Name}
          branch={branch}
          deployData={latestDeployment}
        />
      </TabPanel>
    </Container>
  );
};
