import { Box, Skeleton } from '@mui/material';
import { FC } from 'react';

export const SkeletonDashboard: FC = () => {
  return (
    <Box
      sx={{
        border: '1px solid #DADADA',
        borderRadius: 2,
        p: 2,
        width: 1,
        display: 'flex',
        alignItems: 'center',
        mt: 2
      }}>
      <Box>
        <Skeleton width={'48px'} height={'48px'} variant="circular" />
      </Box>
      <Box sx={{ ml: 2 }}>
        <Skeleton sx={{ mb: 1 }} variant="rounded" width={200} height={22} />
        <Skeleton sx={{ mt: 1 }} variant="rounded" width={350} height={22} />
      </Box>
    </Box>
  );
};
