import styled from '@emotion/styled';

const SectionWrapper = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  justify-content: flex-start;
  @media only screen and (max-height: 1150px) {
    padding-top: 5vh;
  }
  @media only screen and (max-height: 800px) {
    padding-top: 0;
  }
  @media only screen and (max-width: 768px) {
    justify-content: space-evenly;
    padding-top: 0;
  }
  .robot {
    width: 200px;
    position: absolute;
    bottom: 50%;
    transform: rotate(30deg);
    left: 85%;
    z-index: -1;
    opacity: 0.3;
    transition: ease all 1.5s;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .patterPlanet {
    position: absolute;
    left: -1%;
    top: 9%;
    transform: scale(2);
    transform-origin: left;
    transition: ease all 1.5s;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .sectionContent {
    margin-bottom: 4em;
    /* margin: auto auto 10vh; */
    width: 100%;
    text-align: center;
    transform: translateY(100px) scale(0.8);
    transition: ease all 1s;
    margin-top: 5em;
    @media only screen and (max-height: 1023px) {
      margin-top: 0;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 3em;
    }
    .notifyForm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title {
      font-weight: 600;
      font-size: 34px;
      line-height: 50px;
      margin-bottom: 20px;
      @media only screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 30px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .sectionImage {
    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 15vh;
    }
    .image {
      height: 193px;
      margin: 0 auto;
      transform: scale(0.6);
      transform-origin: bottom;
      transition: ease all 1s;
      @media only screen and (max-width: 768px) {
        width: 200px;
      }
      img {
        max-height: 40vh;
      }
    }
  }

  .logoImage {
    img {
      width: 124px;
      height: 124px;
    }
    margin-bottom: 1em;
  }

  .footer {
    position: absolute;
    top: 90.5vh;
    .footerBlock {
      display: flex;
      width: max-content;
      justify-content: center;
      align-items: center;
      gap: 32px;
      @media only screen and (max-width: 512px) {
        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
      }
      .footerMenu {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fcfdff;
        text-align: center;
        cursor: pointer;
        @media only screen and (max-width: 512px) {
          font-size: 14px;
          line-height: 12px;
        }
      }
    }
    @media only screen and (max-width: 512px) {
      top: calc(100vh - 140px);
      width: 100%;
    }
  }
`;

export { SectionWrapper };
