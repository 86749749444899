import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

import DeploymentPage from '../../../assets/images/DeploymentPage.png';

import * as Scroll from 'react-scroll';
import { Link } from 'react-scroll';
import BuildLogHistory from '../../../assets/images/BuildLogHistory.png';
import ConfigureNewBranch from '../../../assets/images/ConfigureNewBranch.png';
import RedeployProject from '../../../assets/images/RedeployProject.png';
import { PageRoutes } from '../../../utils/constants';
import Style from '../Documentation.style';
const GetStartedStepFour: FC = () => {
  const navigate = useNavigate();
  var scroll = Scroll.animateScroll;
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between', position: 'relative' }}>
      <Box sx={Style.ContentBox}>
        <Typography sx={{ fontSize: { xxs: '34px', md: '48px' }, color: 'black', fontWeight: 700 }}>
          {' '}
          Deployment
        </Typography>

        <Box>
          <Typography>
            Once you have configured your project, you can start monitoring its behavior via our
            realtime log updates, to make sure that your visitors are provided with the best
            experience possible.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography
            className={'deploy'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Deploy
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            You are done! We will deploy the first build and you can access it via the published URL
            shown in the project details screen.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={DeploymentPage} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Project Details Page
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            className={'builLogs'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Build History
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            You can check the build’s history and application logs on successful deployment of the
            project.
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1 }}>
            <img src={BuildLogHistory} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Build Log Screen
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            className={'re-deploy'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Re-deploy
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            You can re-deploy the project anytime you want by clicking the deploy button right to
            the corner of the project details screen
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1, p: 2 }}>
            <img src={RedeployProject} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Project Control Settings Screen
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography
            className={'SelectLanguage'}
            sx={{ fontSize: '36px', fontWeight: 600, color: 'black' }}>
            Branch based deployment
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            {`You can also configure the multiple branches by clicking on the branch selection dropdown and then add a new branch.`}
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ border: '1px solid #DADADA', borderRadius: 1, width: 1, p: 2 }}>
            <img src={ConfigureNewBranch} style={{ width: '100%' }} />
          </Box>
          <Typography sx={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic', mt: 1 }}>
            Project’s New Branch Configuration
          </Typography>
        </Box>

        <Box
          className={'upnext'}
          onClick={() => navigate(PageRoutes.Login)}
          sx={{
            border: '1px solid #DADADA',
            borderRadius: 2,
            padding: 3,
            mt: 4,
            cursor: 'pointer',
            backgroundColor: '#DADADA'
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'black' }}>
            Start using BnD{' '}
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>We will deploy the first build</Typography>
        </Box>
      </Box>
      <Box>
        <Box sx={Style.OnthisPage}>
          <Typography sx={{ mb: 1.5 }}>On this page</Typography>
          <Link activeClass="active" to="deploy" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Deploy
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="builLogs" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Build history logs
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="re-deploy" spy={true} smooth={true} offset={-200}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 3,
                mb: 1,
                cursor: 'pointer'
              }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Re-deploy
              </Typography>
            </Box>
          </Link>
          <Link activeClass="active" to="upnext" spy={true} smooth={true} offset={-200}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 3, cursor: 'pointer' }}>
              <Box
                sx={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: '#757575',
                  borderRadius: '50%'
                }}></Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  borderBottom: '2px solid transparent',
                  '&:hover': {
                    color: 'black',
                    borderBottom: '2px solid black',
                    transition: ' all 0.5s'
                  }
                }}>
                Start using BnD
              </Typography>
            </Box>
          </Link>
          <Box sx={{ mt: 2, cursor: 'pointer' }}>
            <Typography onClick={scrollToTop}>Back to top</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GetStartedStepFour;
