import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const LandingSection = styled.div`
  color: #fff;
  input {
    border: none;
    outline: none;
  }
  button {
    cursor: pointer;
    border: none;
  }
  p {
    margin: 0;
  }
  img {
    width: 100%;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1230px;
    }
  }
`;

const Loader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlanetDiv = styled(motion.div)`
  width: 90%;
  height: 90%;
  max-width: 1230px;
  img {
    border-radius: 50%;
  }
`;

const RobotDiv = styled(motion.div)`
  z-index: -1;
  opacity: 0.4;
  transition: all 1s;
  img {
    height: 300px;
  }
  @media only screen and (max-height: 899px) {
    img {
      width: 80%;
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

const BackgroundBlock = styled.div`
  /* background: url("../../assets/images/dashboardBg.jpg") no-repeat center center
    fixed; */
  background-size: cover;
  background-color: #101119;
  background-blend-mode: difference;
  transition: all 1s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;

export { LandingSection, Loader, PlanetDiv, RobotDiv, BackgroundBlock };
