const Style = {
  StepWraper: {
    fontSize: '14px',
    cursor: 'pointer'
  },
  ContentBox: {
    width: { xxs: 1, md: 0.7 }
  },
  OnthisPage: {
    width: '250px',
    display: { xxs: 'none', md: 'block' },
    border: '1px solid #DADADA',
    position: 'sticky',
    height: 'auto',
    top: '160px',

    ml: 2.5,
    p: 2
  },
  WebLink: {
    textDecoration: { xxs: 'underline', md: 'underline' },
    color: 'common.grey',
    cursor: 'pointer',
    mx: 0.5,
    '&:hover': {
      color: 'primary.main'
    }
  }
};

export default Style;
