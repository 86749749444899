import { useState, useEffect, useCallback, useRef } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import * as Style from './Landing.style';
import Subscribe from '../../components/sections/Subscribe';
import Dashboard from '../../components/sections/Dashboard';
import Dashboard2 from '../../components/sections/Dashboard/Dashboard2';
import Shop from '../../components/sections/Shop';
import Process from '../../components/sections/Process';
import Notified from '../../components/sections/Notified';
import PlanetLg from '../../assets/images/planet-lg.png';
import Background from '../../assets/images/StarBG.png';
import BlankSpaceBG from '../../assets/images/BG.png';
import patternPlanet from '../../assets/images/red-pattern-planet.svg';
import Robot from '../../assets/images/astronaut.svg';

import { motion } from 'framer-motion';
import { PlanetDiv, RobotDiv } from './Landing.style';
import { Backdrop, CircularProgress, useMediaQuery } from '@mui/material';
import useImagePreloader from '../../hooks/PreloadImage';
import LandingHeader from '../../components/LandingHeader';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getAuthState } from '../../store/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from '../../utils/constants';
import GetStarted from '../../components/sections/GetStarted';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function Landing() {
  const gaEventTracker = useAnalyticsEventTracker('landing_page');
  const auth = useTypedSelector(getAuthState, shallowEqual);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [screenHeight, setScreenHeight] = useState(1024);
  const [robotViewport, setRobotViewport] = useState('52vh');

  const alignCenter = { display: 'flex', alignItems: 'center' };
  const isSmallScreen = useMediaQuery('(max-width: 820px)');
  const isBigScreen = useMediaQuery('(min-height: 1200px)');

  const [y, setY] = useState(0);
  const [isScrollUp, setIsScrollUp] = useState(true);
  const [isHeaderSolid, setIsHeaderSolid] = useState(false);
  const parallax = useRef<any>(null);

  const planetVariants = {
    start: {
      translateY: 150,
      scale: 0.3,
      translateX: 100,
      transition: { duration: 3 }
    },
    stop: {
      translateY: 0,
      scale: currentPage === 2 ? 0.3 : 1,
      transition: { duration: 1 }
    }
  };

  useEffect(() => {
    gaEventTracker('landing_page_visitors');
    if (auth.access_token) {
      navigate(PageRoutes.Dashboard);
    }
  }, []);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    if (window.innerHeight > 1269) {
      setRobotViewport('49vh');
    } else if (window.innerHeight > 1089) {
      setRobotViewport('51vh');
    }

    const handleScroll = (event: any) => {};

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCurrentPage = (value: number) => {
    setCurrentPage(value);
  };

  const handleNavigation = useCallback(
    (e: any) => {
      if (parallax?.current.current < parallax?.current.space * 0.2) {
        setCurrentPage(0);
      }
      if (
        parallax?.current.current > parallax?.current.space * 0.2 &&
        parallax?.current.current < parallax?.current.space + parallax?.current.space * 0.75
      ) {
        setCurrentPage(1);
      }
      if (
        parallax?.current.current > parallax?.current.space * 1.3 &&
        parallax?.current.current < parallax?.current.space + parallax?.current.space * 1.5
      ) {
        setCurrentPage(2);
      }
      if (y > parallax?.current.current) {
        setIsScrollUp(true);
        setIsHeaderSolid(true);
      } else if (y < parallax?.current.current) {
        setIsScrollUp(false);
        setIsHeaderSolid(false);
      }
      if (parallax?.current.current < 80) {
        setIsHeaderSolid(false);
      }
      if (parallax?.current.current < 40) {
        setIsScrollUp(true);
      }
      setY(parallax?.current.current);
    },
    [y]
  );

  useEffect(() => {
    const container = document.querySelector('.my-class-name');
    container?.addEventListener('scroll', handleNavigation);
    return () => {
      container?.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  const handleRedPlantScale = () => {
    if (currentPage === 3) {
      return {
        scale: 3,
        translateX: -80,
        // translateY: -300,
        transition: { duration: 2, delay: 0 }
      };
    } else if (currentPage === 4) {
      return {
        scale: 4,
        translateX: 20,
        // translateY: -300,
        transition: { duration: 2, delay: 0 }
      };
    } else if (currentPage === 5) {
      return {
        scale: 1,
        translateX: 20,
        // translateY: -200,
        transition: { duration: 2, delay: 0 }
      };
    } else {
      return { scale: 1, opacity: 0, transition: { duration: 1 } };
    }
  };

  const variants = {
    start: handleRedPlantScale(),
    stop: { scale: 1, opacity: 0.3, transition: { duration: 1 } }
  };

  const preloadSrcList: string[] = [Background, PlanetLg, BlankSpaceBG];

  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  return (
    <Style.LandingSection>
      <Backdrop
        sx={{
          backgroundColor: 'rgb(0 0 0 / 90%)',
          color: '#664CCD',
          zIndex: 3
        }}
        open={!imagesPreloaded}>
        <Style.Loader>
          <CircularProgress color="inherit" size={80} />
        </Style.Loader>
      </Backdrop>

      <Style.BackgroundBlock
        style={{
          opacity: 1,
          backgroundImage: `url(${Background})`
        }}
      />
      <Style.BackgroundBlock
        className={`${currentPage > 2 ? 'active' : ''}`}
        style={{
          backgroundImage: `url(${BlankSpaceBG})`
        }}
      />
      <Style.BackgroundBlock
        className={`${currentPage <= 2 ? 'active' : ''}`}
        style={{
          backgroundImage: `url(${Background})`
        }}
      />
      <LandingHeader isShow={isScrollUp ? true : false} isHeaderSolid={isHeaderSolid} />

      <Parallax pages={isSmallScreen ? 7 : 6} ref={parallax} className="my-class-name">
        <ParallaxLayer offset={0} speed={0.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <Subscribe currentPage={currentPage} />
        </ParallaxLayer>
        <ParallaxLayer
          style={{
            ...alignCenter,
            justifyContent: 'center',
            zIndex: -2,
            height: '50vh'
          }}
          offset={isSmallScreen ? 0.8 : 0.7}
          speed={isSmallScreen ? 0.4 : 1}>
          <PlanetDiv
            className="subscribeSectionPlanetBg"
            variants={planetVariants}
            animate={currentPage === 1 ? 'start' : 'stop'}>
            <img src={PlanetLg} alt="Planet" />
          </PlanetDiv>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isSmallScreen ? 1 : 0.9}
          sticky={
            isSmallScreen
              ? { start: 1, end: 1 }
              : {
                  start: 1,
                  end: isBigScreen ? 2.15 : 2.1
                }
          }
          style={{
            ...alignCenter,
            justifyContent: 'center',
            zIndex: currentPage >= 2 ? '-1' : '0'
          }}>
          <Dashboard isActive={currentPage >= 2} />
        </ParallaxLayer>

        <ParallaxLayer style={{ zIndex: '2' }} offset={2} speed={isSmallScreen ? 0.3 : 0.4}>
          <Dashboard2 />
        </ParallaxLayer>

        <ParallaxLayer
          style={{
            ...alignCenter,
            justifyContent: 'center'
          }}
          offset={3}
          speed={isSmallScreen ? 0.2 : 0.9}>
          <Shop setCurrentPage={setCurrentPage} />
        </ParallaxLayer>

        <ParallaxLayer
          style={{ ...alignCenter, justifyContent: 'center' }}
          offset={isSmallScreen ? 4 : 3.2}
          factor={isSmallScreen ? 1 : 2.5}
          speed={isSmallScreen ? 0.2 : 1}>
          <Process />
        </ParallaxLayer>

        <ParallaxLayer
          speed={-0.5}
          offset={isSmallScreen ? 4.2 : 3.2}
          sticky={{ start: 3, end: isSmallScreen ? 4.8 : 3.8 }}
          style={{
            ...alignCenter,
            justifyContent: 'flex-start',
            height: robotViewport
          }}
          className="container">
          <motion.div
            className="patterRedPlanet"
            variants={variants}
            animate={currentPage > 2 ? 'start' : 'stop'}>
            <img src={patternPlanet} alt="planet" />
          </motion.div>
        </ParallaxLayer>
        <ParallaxLayer
          speed={0.1}
          offset={4}
          factor={1.5}
          sticky={{ start: 2.1, end: 3.1 }}
          // sticky={{ start: 3.1, end: 3.8 }}
          style={{
            ...alignCenter,
            alignItems: 'end',
            justifyContent: 'end',
            height: robotViewport,
            zIndex: '2'
          }}
          className="container">
          <RobotDiv
            className="robot"
            // variants={robotVariants}
            // animate={currentPage > 2 ? "start" : "stop"}
            initial={{
              opacity: 0.1,
              // translateX: screenWidth - screenWidth * 0.15,
              rotateZ: 15,
              translateY: screenHeight - screenHeight * 0.6
            }}
            transition={{
              type: 'spring',
              bounce: 10,
              damping: 10,
              stiffness: 1000
            }}>
            <img src={Robot} alt="robot" />
          </RobotDiv>
        </ParallaxLayer>

        <ParallaxLayer
          style={{ ...alignCenter, justifyContent: 'center', zIndex: '3' }}
          offset={isSmallScreen ? 5 : 4}
          speed={isSmallScreen ? 0.1 : 0.8}>
          <GetStarted />
        </ParallaxLayer>

        <ParallaxLayer
          style={{ ...alignCenter, justifyContent: 'center', zIndex: '3' }}
          offset={isSmallScreen ? 6 : 5}
          speed={isSmallScreen ? 0.1 : 0.8}>
          <Notified />
        </ParallaxLayer>
      </Parallax>
    </Style.LandingSection>
  );
}
