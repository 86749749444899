import { combineReducers } from '@reduxjs/toolkit';
import authDetailReducer from './authReducer';
import configureProjectReducer from './configureProjectReducer';

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  authData: authDetailReducer,
  configureProjectData: configureProjectReducer
});

const RootReducer = (state: any, action: any) => {
  if (action.type === 'authDetails/logout') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default RootReducer;
