import { Box } from '@mui/material';
import React, { FC } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import BackgroundImage from '../../../assets/images/PricingMVP.jpg';
import { useLocation } from 'react-router-dom';
import { PageRoutes } from '../../../utils/constants';
export const MainContainer: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        minHeight: location.pathname === PageRoutes.LandingPage ? '100%' : '100vh',
        bgcolor:
          location.pathname === PageRoutes.LandingPage ? 'background.dark' : 'background.light',
        backgroundImage:
          location.pathname === PageRoutes.PricingPage ? `url(${BackgroundImage})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
      <ToastContainer autoClose={1000} position="top-right" />
      {location.pathname !== PageRoutes.LandingPage && <Header />}
      <Box component="main" className="main-content" sx={{ width: 1 }}>
        {props.children}
      </Box>
      {location.pathname !== PageRoutes.LandingPage && <Footer />}
    </Box>
  );
};

export default MainContainer;
