import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react';
import { BuildSettingForm } from '../..';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CodeEditor from '../../../../components/CodeEditor/CodeEditor';

const DockerfileConfigs = (props: BuildSettingForm) => {
  return (
    <>
      <Grid item xs={12} md={6} sx={{ width: 1 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ padding: '6px 16px', border: '1px solid #0000003b', borderRadius: '5px' }}
          value={props.dockerChecked}
          onChange={props.handleDockerToggle}>
          <FormControlLabel value={false} control={<Radio />} label="Dockerfile" />
          <FormControlLabel value={true} control={<Radio />} label="Inline" />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} md={6} sx={{ width: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Context"
          placeholder="Context"
          id="Context"
          onChange={(event) => {
            props.onChange('Context', event?.target.value);
          }}
          value={props.Context || ''}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'Context'
          }}
        />
      </Grid>
      {props.dockerChecked ? (
        <Grid item xs={12} sx={{ width: 1 }}>
          <Typography sx={{ mb: 1 }}>
            Custom Docker File Instruction <InfoOutlinedIcon sx={{ verticalAlign: 'bottom' }} />
          </Typography>
          <CodeEditor
            value={props.DockerfileContent}
            onValueChange={(text: any) => props.onChange('DockerfileContent', text)}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={6} sx={{ width: 1 }}>
          <TextField
            variant="outlined"
            label="Dockerfile"
            fullWidth
            id="DockerFile"
            placeholder="Docker File"
            onChange={(event) => {
              props.onChange('Dockerfile', event?.target.value);
            }}
            value={props.Dockerfile || ''}
            aria-describedby="DockerFile"
            inputProps={{
              'aria-label': 'DockerFile'
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default DockerfileConfigs;
