import ShopImage from '../../../assets/images/shop.png';
import whitePlanet from '../../../assets/images/white-planet.svg';
import * as Style from './Shop.style';
import React, { useEffect } from 'react';
import { motion, useInView } from 'framer-motion';

const Shop = ({ setCurrentPage }: { setCurrentPage: any }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false, amount: 'some' });

  const variants = {
    start: { scale: 1, transition: { duration: 1, delay: 0 } },
    stop: { scale: 0.9, transition: { duration: 1 } }
  };
  useEffect(() => {
    if (isInView && setCurrentPage) {
      setCurrentPage(3);
    }
  }, []);

  return (
    <Style.SectionWrapper>
      <Style.ShopMain>
        <div className="container" ref={ref}>
          <div className="sectionContent">
            <motion.div
              className="content"
              variants={variants}
              animate={isInView ? 'start' : 'stop'}>
              <div className="title">
                Your One-Stop Shop for <span> Operationalizing Code </span>with Ease
              </div>
            </motion.div>
            <motion.div className="image" variants={variants} animate={isInView ? 'start' : 'stop'}>
              <img src={ShopImage} alt="dashboard" />
            </motion.div>
            <div className="whitePlanet">
              <img src={whitePlanet} alt="planet" />
            </div>
          </div>
        </div>
      </Style.ShopMain>
    </Style.SectionWrapper>
  );
};

export default Shop;
